import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI, { useDocumentTitle } from "@mesacloud/react-framework"

import { useRedirectRoles, useCloseNotesOnFirstLoad } from '../../hooks'
import { UserRoleEnum, FlagStatusEnum } from '../../lib/enums'
import { useSelector, flagsSagaActions, indicatorsSagaActions, loadTriggersActions, fileExportSagaActions } from '../../store'

import RoleFilter from '../../components/RoleFilter'

import IndicatorsOverallPie from './IndicatorsOverallPie'
import IndicatorsYE9Bar from './IndicatorsYE9Bar'
import IndicatorsFlagTable from './IndicatorsFlagTable'
import IndicatorsStudentFlags from './IndicatorsStudentFlags'
import IndicatorsFlagsPie from './IndicatorsFlagsPie'
import { MesaColorEnum } from '@mesacloud/corejs'
import { FlagType } from '../../store/flags/flagsDomain'

const flagTypeColorSequence = [
  MesaColorEnum.SEAFOAM,
  MesaColorEnum.ORANGE,
  MesaColorEnum.GOLD,
  MesaColorEnum.RED,
  MesaColorEnum.NAVY,
  MesaColorEnum.BLUE,
  MesaColorEnum.GREEN
]

const formatFlagCountsForTable = (
  flagCounts: Array<{ [flagName: string]: string | number }>, 
  indexTitle: string,
  indexKeyName: string, 
  flagTypes: Array<FlagType>, 
  findFlagTypeColor: (flagName: string) => MesaColorEnum
): {
  headers: Array<{ name: string; color: MesaColorEnum }>;
  rows: Array<Array<string | number>>;
} => {

  if(!flagCounts?.length){
    return { headers: [], rows: [] }
  }

  const headers = [{ name: indexTitle, color: MesaColorEnum.NAVY }]

  const flagTypesCopy = flagTypes.filter(({ flagTypeId }) => flagTypeId !== "0")

  flagTypesCopy.sort((a, b) => {
    if(typeof a?.flagName === "string" && a.flagName?.match(/offtrack/i)){
      return -1
    } else {
      return a > b ? -1 : 1
    }
  })

  headers.push(...flagTypesCopy.map(({ flagName }) => ({ name: flagName, color: findFlagTypeColor(flagName) })))

  const rows = flagCounts.map(flagCountObj => {
    if(flagCountObj === null || typeof flagCountObj !== "object") return []

    const row = [flagCountObj[indexKeyName] || 'N/A']

    flagTypesCopy.forEach(({ flagName }) => {
      if((typeof flagCountObj[flagName]).match(/string|number/)){
        row.push(flagCountObj[flagName])
      } else {
        row.push(0)
      }
    })

    return row
  }).filter(val => val !== null)

  return { headers, rows }
}

const Indicators = () => {

  useDocumentTitle("Mesa OnTime: Indicators")
  useRedirectRoles([UserRoleEnum.STUDENT])
  useCloseNotesOnFirstLoad()

  const dispatch = useDispatch()

  const { userRole } = useSelector(({ user }) => user)

  const {
    indicatorsPageShouldLoadEventual,
    indicatorsPageShouldLoadImmediate
  } = useSelector(({ loadTriggers }) => loadTriggers) 

  const { 
    isLoadingFlagCountsByCampus,
    isLoadingFlagCountsByDem,
    isLoadingFlagCountsByYE9,
    isLoadingOverallFlagCounts,
    isLoadingOverallRiskAssessment,
    isLoadingRiskAssessmentByYE9,
    isLoadingStudentsWithFlagsCounts,
    overallRiskAssessment,
    overallFlagCounts,
    riskAssessmentByYE9,
    flagCountsByCampus,
    flagCountsByDem,
    flagCountsByYE9,
    totalStudentCount,
    studentsWithFlagsCounts
  } = useSelector(({ indicators }) => indicators)

  const {
    isExportingOverallRisk,
    isExportingYE9Risk,
    isExportingFlags
  } = useSelector(({ fileExport }) => fileExport)

  const {
    isLoadingFlagTypes,
    flagTypes
  } = useSelector(({ flags }) => flags)

  const loadPage = () => {
    if(!isLoadingFlagTypes && !flagTypes?.length){
      dispatch(flagsSagaActions.fetchFlagTypes())
    }
    dispatch(indicatorsSagaActions.fetchOverallRiskAssessment())
    dispatch(indicatorsSagaActions.fetchRiskAssessmentByYE9())
    dispatch(indicatorsSagaActions.fetchFlagCountsByDem())
    dispatch(indicatorsSagaActions.fetchFlagCountsByYE9())
    userRole === UserRoleEnum.DISTRICT && dispatch(indicatorsSagaActions.fetchFlagCountsByCampus())
    dispatch(indicatorsSagaActions.fetchStudentsWithFlagsCounts())
    dispatch(indicatorsSagaActions.fetchOverallFlagCounts())
    dispatch(loadTriggersActions.indicatorsPageLoaded())
  }

  useEffect(() => {
    if(indicatorsPageShouldLoadEventual){
      loadPage()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(indicatorsPageShouldLoadImmediate){
      loadPage()
    }
    // eslint-disable-next-line
  }, [indicatorsPageShouldLoadImmediate])

  const flagTypeColors: Array<{ flagName: string; color: MesaColorEnum }> = useMemo(() => (
    Array.isArray(flagTypes) ? flagTypes.filter(({ flagTypeId }) => flagTypeId !== "0").map(({ flagName }, i) => ({
      flagName,
      color: flagTypeColorSequence[i % flagTypeColorSequence.length]
    })) : []
  ), [flagTypes])

  const findFlagTypeColor = (flagName: string): MesaColorEnum => flagTypeColors.find(({ flagName: _flagName }) => _flagName === flagName)?.color || MesaColorEnum.NAVY

  return (
    <MesaUI.Container className="indicators" paddingType="sm">
      <MesaUI.FlexRow>
        <MesaUI.FlexCol>
          <MesaUI.Container paddingType="md">
            <IndicatorsOverallPie
              isLoading={isLoadingOverallRiskAssessment || isExportingOverallRisk}
              overallRiskAssessment={overallRiskAssessment}
              totalStudentCount={parseInt(totalStudentCount + "") || 0}
              callExport={() => dispatch(fileExportSagaActions.fetchOverallRiskExport())}
            />
          </MesaUI.Container>
        </MesaUI.FlexCol>
        <MesaUI.FlexCol>
          <MesaUI.Container paddingType={{ left: "md", top: "md", bottom: "md", right: "md" }}>
            <IndicatorsYE9Bar
              isLoading={isLoadingRiskAssessmentByYE9 || isExportingYE9Risk}
              riskAssessmentByYE9={riskAssessmentByYE9}
              riskAssessment={overallRiskAssessment}
              callExport={() => dispatch(fileExportSagaActions.fetchYE9RiskExport())}
            />
          </MesaUI.Container>
        </MesaUI.FlexCol>
      </MesaUI.FlexRow>
      <MesaUI.Container paddingType="md">
        <IndicatorsFlagsPie
          isLoading={isExportingFlags || isLoadingOverallFlagCounts || isLoadingFlagTypes}
          flagCounts={overallFlagCounts}
          callExport={() => dispatch(fileExportSagaActions.fetchFlagsExport({ flagStatus: FlagStatusEnum.UNRESOLVED.keyname, flagTypeId: "0" }))}
          findFlagTypeColor={findFlagTypeColor}
        />
      </MesaUI.Container>
      <MesaUI.Container paddingType="md">
        <IndicatorsStudentFlags isLoading={isLoadingStudentsWithFlagsCounts} studentsWithFlagsCounts={studentsWithFlagsCounts}/>
      </MesaUI.Container>
      <RoleFilter roles={[UserRoleEnum.DISTRICT]}>
        <MesaUI.Container paddingType="md">
          <IndicatorsFlagTable isLoading={isLoadingFlagCountsByCampus} title="Flags By Campus" csvFilename="Mesa_Flags_by_Campus" {...formatFlagCountsForTable(flagCountsByCampus, 'Campus', 'campusName', flagTypes, findFlagTypeColor)}/>
        </MesaUI.Container>
      </RoleFilter>
      <MesaUI.Container paddingType="md">
        <IndicatorsFlagTable isLoading={isLoadingFlagCountsByYE9} title="Flags By YE9" csvFilename="Mesa_Flags_by_YE9" {...formatFlagCountsForTable(flagCountsByYE9, 'YE9', 'YE9', flagTypes, findFlagTypeColor)}/>
      </MesaUI.Container>
      <MesaUI.Container paddingType="md">
        <IndicatorsFlagTable isLoading={isLoadingFlagCountsByDem} title="Flags By Demographic" csvFilename="Mesa_Flags_by_Demographic" {...formatFlagCountsForTable(flagCountsByDem, 'Demographic', 'Demographic', flagTypes, findFlagTypeColor)}/>
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default Indicators