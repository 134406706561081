
import React from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '@mesacloud/react-framework'

import { PanelViewEnum } from '../../lib/enums'
import { useSelector, viewActions } from '../../store'

import PanelFilter from './filter'
import PanelSupport from './support'
import PanelNotes from './notes'
import PanelGroupList from './groupList'

const PanelSwitch = () => {

  const { panelIsOpen, panelView } = useSelector(({ view }) => view)

  const dispatch = useDispatch()

  const closePanel = () => dispatch(viewActions.closePanel())

  const PanelComponent = () => {
    switch(panelView){
      case PanelViewEnum.FILTER:
        return <PanelFilter/>
      case PanelViewEnum.SUPPORT_REQUEST:
        return <PanelSupport/>
      case PanelViewEnum.NOTES:
        return <PanelNotes/>
      case PanelViewEnum.GROUP_LIST:
        return <PanelGroupList/>
      default:
        return <></>
    }
  }

  return (
    <MesaUI.Panel isOpen={panelIsOpen} closeFunc={closePanel} withMask={panelView.useMask}>
      <PanelComponent/>
    </MesaUI.Panel>
  )
}

export default PanelSwitch