
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

import { StudentsWithFlagsCounts } from '../../store/indicators/indicatorsDomain'

interface IndicatorsStudentFlagsPropTypes {
  isLoading: boolean | null | undefined;
  studentsWithFlagsCounts: StudentsWithFlagsCounts;
}

const IndicatorsStudentFlags = ({
  isLoading,
  studentsWithFlagsCounts
}: IndicatorsStudentFlagsPropTypes) => (
  <MesaUI.Card className={{ "loading-whirl": isLoading, "indicators__card--student-flags": true }}>
    <MesaUI.CardHeader>
      <MesaUI.CardHeaderLeft>
        <h3>Students With Flags</h3>
      </MesaUI.CardHeaderLeft>
    </MesaUI.CardHeader>
    <MesaUI.CardContent>
      <MesaUI.FlexRow style={{ justifyContent: "center" }}>
        <MesaUI.FlexCol>
          <MesaUI.Container paddingType="md" className="indicators__students-with-flags__item">
            <div>{studentsWithFlagsCounts?.studentsCount || 0}</div>
            <div>Students with an unresolved flag</div>
          </MesaUI.Container>
        </MesaUI.FlexCol>
        <MesaUI.FlexCol>
          <MesaUI.Container paddingType="md" className="indicators__students-with-flags__item">
            <div>{studentsWithFlagsCounts?.studentsCountWithMultipleFlags || 0}</div>
            <div>Students with more than one flag</div>
          </MesaUI.Container>
        </MesaUI.FlexCol>
      </MesaUI.FlexRow>
    </MesaUI.CardContent>
  </MesaUI.Card>
)

export default IndicatorsStudentFlags
