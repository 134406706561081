
import { createSlice } from '@reduxjs/toolkit'

export interface ExportState {
  isExportingCourses: boolean;
  isExportingOnTrack: boolean;
  isExportingOffTrack: boolean;
  isExportingOverallRisk: boolean;
  isExportingYE9Risk: boolean;
  isExportingFlags: boolean;
  isExportingStudentTranscript: boolean;
}

const initialState: ExportState = {
  isExportingCourses: false,
  isExportingOnTrack: false,
  isExportingOffTrack: false,
  isExportingOverallRisk: false,
  isExportingYE9Risk: false,
  isExportingFlags: false,
  isExportingStudentTranscript: false,
}

export const fileExportSlice = createSlice({
  name: "fileExport",
  initialState,
  reducers: {
    exportCoursesRequested: (state) => {
      state.isExportingCourses = true
    },
    exportCoursesComplete: (state) => {
      state.isExportingCourses = false
    },
    exportOnTrackRequested: (state) => {
      state.isExportingOnTrack = true
    },
    exportOnTrackComplete: (state) => {
      state.isExportingOnTrack = false
    },
    exportOffTrackRequested: (state) => {
      state.isExportingOffTrack = true
    },
    exportOffTrackComplete: (state) => {
      state.isExportingOffTrack = false
    },
    exportOverallRiskRequested: (state) => {
      state.isExportingOverallRisk = true
    },
    exportOverallRiskComplete: (state) => {
      state.isExportingOverallRisk = false
    },
    exportYE9RiskRequested: (state) => {
      state.isExportingYE9Risk = true
    },
    exportYE9RiskComplete: (state) => {
      state.isExportingYE9Risk = false
    },
    exportFlagsRequested: (state) => {
      state.isExportingFlags = true
    },
    exportFlagsComplete: (state) => {
      state.isExportingFlags = false
    },
    exportStudentTranscriptRequested: (state) => {
      state.isExportingStudentTranscript = true
    },
    exportStudentTranscriptComplete: (state) => {
      state.isExportingStudentTranscript = false
    },
  }
})