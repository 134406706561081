
import React from 'react'

const StudentMainAch = () => {

    return (
      <div>StudentMainAch</div>
    )
}

export default StudentMainAch
