
import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import MesaUI from '@mesacloud/react-framework'

import { RiskAssessment } from '../../store/indicators/indicatorsDomain'
import { MesaColorEnum } from '@mesacloud/corejs'

import { StudentGradPlanStatusEnum } from '../../lib/enums'

import IndicatorsCardFooterStudentCount from './IndicatorsCardFooterStudentCount'

interface IndicatorsOverallPiePropTypes {
  isLoading: boolean | null | undefined;
  overallRiskAssessment: RiskAssessment;
  totalStudentCount: number;
  callExport: () => any;
}

const formatOverallForNivo = (overall: RiskAssessment): Array<{
  id: string;
  label: string;
  value: number;
  color: string;
}> => (overall === null || typeof overall !== "object") ? [] : (
  [
    {
      id: StudentGradPlanStatusEnum.ALERT.keyname,
      label: StudentGradPlanStatusEnum.ALERT.displayName,
      value: parseInt(overall["Alert"], 10) || 0,
      color: MesaColorEnum.GOLD.hex
    },
    {
      id: StudentGradPlanStatusEnum.NO_YE9_TRANSCRIPT.keyname,
      label: StudentGradPlanStatusEnum.NO_YE9_TRANSCRIPT.displayName,
      value: parseInt(overall["No YE9 Transcript"], 10)|| 0,
      color: MesaColorEnum.GRAY.hex
    },
    {
      id: StudentGradPlanStatusEnum.ON_TRACK.keyname,
      label: StudentGradPlanStatusEnum.ON_TRACK.displayName,
      value: parseInt(overall["On Track"], 10) || 0,
      color: MesaColorEnum.SEAFOAM.hex
    },
    {
      id: StudentGradPlanStatusEnum.OFF_TRACK.keyname,
      label: StudentGradPlanStatusEnum.OFF_TRACK.displayName,
      value: parseInt(overall["Off Track"], 10) || 0,
      color: MesaColorEnum.RED.hex
    }
  ]
)

const IndicatorsOverallPie = ({
  isLoading,
  overallRiskAssessment,
  totalStudentCount,
  callExport
}: IndicatorsOverallPiePropTypes) => (
  <MesaUI.Card className={{ "indicators__card--overall": true, "loading-whirl": isLoading }}>
    <MesaUI.CardHeader>
      <MesaUI.CardHeaderLeft>
        <h3>Students On Track To Graduate</h3>
      </MesaUI.CardHeaderLeft>
      <MesaUI.CardHeaderRight>
        <MesaUI.Button buttonType="primary" onClick={callExport}>
          Export
        </MesaUI.Button>
      </MesaUI.CardHeaderRight>
    </MesaUI.CardHeader>
    <MesaUI.CardContent>
      <MesaUI.Container className="indicators__pie-wrapper">
        {!isLoading && (
          <>
            <div className="indicators__pie-wrapper__total">
              <div>{totalStudentCount}</div>
              <div>Students</div>
            </div>
            <ResponsivePie
              data={formatOverallForNivo(overallRiskAssessment)}
              innerRadius={0.45}
              animate={true}
              padAngle={2}
              margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
              enableSlicesLabels={false}
              radialLabelsLinkColor={{ from: "color" }}
              enableRadialLabels={false}
              colors={(c: any) => StudentGradPlanStatusEnum.getByKeyname(c?.id).color.hex}
            />
          </>
        )}
      </MesaUI.Container>
      <IndicatorsCardFooterStudentCount riskAssessment={overallRiskAssessment}/>
    </MesaUI.CardContent>
  </MesaUI.Card>
)

export default IndicatorsOverallPie
