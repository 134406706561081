
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

import { StudentGroupDetails, CampusOption } from '../../store/groups/groupsDomain'
import { MesaColorEnum } from '@mesacloud/corejs'

interface GroupsDetailsModalPropTypes {
  details: StudentGroupDetails;
  campuses: Array<CampusOption>;
  close: () => void;
  isLoading?: boolean | null;
}

const GroupsDetailsModal = ({
  details,
  campuses,
  isLoading,
  close
}: GroupsDetailsModalPropTypes) => (
  <MesaUI.Modal closeFunc={close} className="group-details-modal">
    <MesaUI.Card className={{ "loading-whirl": isLoading }}>
      <MesaUI.CardHeader backgroundColor={MesaColorEnum.NAVY} fontColor={MesaColorEnum.WHITE}>
        <MesaUI.CardHeaderLeft>
          Group Details: {details?.name || "N/A"}
        </MesaUI.CardHeaderLeft>
        <MesaUI.CardHeaderRight>
          <MesaUI.Button buttonType="close" onClick={close}/>
        </MesaUI.CardHeaderRight>
      </MesaUI.CardHeader>
      <MesaUI.CardContent>
        <MesaUI.Container paddingType="sm">There are {details?.studentIds?.length || 0} individually added students in this group.</MesaUI.Container>
      </MesaUI.CardContent>
      {Array.isArray(details?.specialSelectors) ? (
        details.specialSelectors.map(({
          campusIds,
          ye9Filters,
          lastNameFilter
        }, i) => (
          <MesaUI.Container key={`group-details-modal__selection__${i}`} className="group-details-modal__selection">
            <MesaUI.Container backgroundColor={MesaColorEnum.NAVY} fontColor={MesaColorEnum.WHITE} paddingType="sm">
              Selection {i + 1}
            </MesaUI.Container>
            <MesaUI.Container backgroundColor={MesaColorEnum.SILVER} paddingType="sm">
              Last Name Letters
            </MesaUI.Container>
            <MesaUI.Container paddingType="sm">
              {(Array.isArray(lastNameFilter) && lastNameFilter.length === 2) ? `${lastNameFilter[0]} - ${lastNameFilter[1]}` : 'N/A'}
            </MesaUI.Container>
            <MesaUI.Container backgroundColor={MesaColorEnum.SILVER} paddingType="sm">
              Campus
            </MesaUI.Container>
            <MesaUI.Container paddingType="sm">
              {Array.isArray(campusIds) ? (
                campusIds.map(campusId => campuses.find(({ externalId }) => externalId + "" === campusId + "")?.name || campusId).join(', ') || 'All'
              ) : 'N/A'}
            </MesaUI.Container>
            <MesaUI.Container backgroundColor={MesaColorEnum.SILVER} paddingType="sm">
              YE9
            </MesaUI.Container>
            <MesaUI.Container paddingType="sm">
              {Array.isArray(ye9Filters) ? (
                ye9Filters.join(', ') || 'All'
              ) : 'N/A'}
            </MesaUI.Container>
          </MesaUI.Container>
        ))
      ) : null}
    </MesaUI.Card>
  </MesaUI.Modal>
)

export default GroupsDetailsModal