
import { createSlice } from "@reduxjs/toolkit"

import { API_STATUS_OK } from './apiHealthDomain'

export interface ApiHealthState {
  statusText: string;
  isLoadingApiHealth: boolean;
  apiHealthCheckSuccess: boolean;
  apiHealthCheckFailed: boolean;
  apiHealthCheckFailureReason: string;
}

const initialState: ApiHealthState = {
  statusText: "",
  isLoadingApiHealth: false,
  apiHealthCheckSuccess: false,
  apiHealthCheckFailed: false,
  apiHealthCheckFailureReason: ""
}

export const apiHealthSlice = createSlice({
  name: "apiHealth",
  initialState,
  reducers: {
    getApiHealthRequested: (state) => {
      state.isLoadingApiHealth = true
    },
    getApiHealthFailure: (state, { payload: { errorMessage }}: { payload: { errorMessage: string }}) => {
      state.isLoadingApiHealth = false
      state.apiHealthCheckFailed = true
      state.apiHealthCheckFailureReason = `Oops! Mesa is currently under maintenance! Please check back later! (${errorMessage})`
    },
    getApiHealth: (state, { payload: { statusText }}: { payload: { statusText: string } }) => {
      state.statusText = statusText
      state.isLoadingApiHealth = false
      if(statusText !== API_STATUS_OK){
        state.apiHealthCheckFailed = true
        state.apiHealthCheckFailureReason = `Oops! Mesa is currently under maintenance! Please check back later! (status: ${statusText})`
      } else {
        state.apiHealthCheckSuccess = true
      }
    }
  }
})