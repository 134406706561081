import queryStringParser from "querystring"
import { call, takeLatest, takeLeading, put, all } from "redux-saga/effects";

import { OnTimeAPI, IS_REDIRECTING_TO_LOGIN_PLACEHOLDER, setShouldUseJWTState } from '../../lib/api'

import * as userSagaActions from './userSagaActions'

import { userSlice } from './userSlice'
import { errorSlice } from '../error/errorSlice'
import { WhoAmI, mockWhoamiResult } from "./userDomain";

const AUTH_TOKEN_PARAM = "x-mesaiam-jwt-auth-token"

const getAuthToken = (): string | null | void | undefined => {
  let authToken;

  const search = window?.location?.search
  if (search) {
    const urlQueryParms = queryStringParser.parse(search.slice(1))
    authToken = urlQueryParms[AUTH_TOKEN_PARAM] as string
    if (authToken) {
      localStorage.clear()
      window.history.pushState(
        window.history.state,
        document.title,
        window.location.origin + window.location.pathname
      )
    }
  }
  return authToken
}

function* fetchWhoAmISaga(){
  try {
    yield put(userSlice.actions.getWhoAmIRequested())
    const authToken = getAuthToken()
    const config = authToken ? { headers: { [AUTH_TOKEN_PARAM]: authToken } } : {}
    const result = yield call(() => {
      return OnTimeAPI.get<WhoAmI>("/api/whoami", config, mockWhoamiResult)
    })
    yield put(userSlice.actions.getWhoAmI(result.data))
  } catch(e) {
    if(e !== IS_REDIRECTING_TO_LOGIN_PLACEHOLDER){
      yield put(userSlice.actions.getWhoAmIFailed({ errorMessage: `Error in authorization: "${e}"` }))
    }
  }
}

function* fetchLogoutSaga(){
  try {
    yield put(userSlice.actions.logoutRequested())
    setShouldUseJWTState(false)
    yield call(() => OnTimeAPI.get('/api/logout', {}, {}))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error logging out: "${e}"` }))
  } finally {
    yield call(() => OnTimeAPI.get('/api/whoami', {}, {}))
  }
}

export function* rootUserSaga(){
  yield all([
    takeLatest(userSagaActions.fetchWhoAmI.type, fetchWhoAmISaga),
    takeLeading(userSagaActions.fetchLogout.type, fetchLogoutSaga)
  ]) 
}