import { createAction } from '@reduxjs/toolkit'

import { FlagStatusAndTypePayload } from '../flags/flagsDomain'
import { StudentIDAndNamePayload } from '../student/studentDomain'

export const fetchCoursesExport = createAction("FETCH_COURSES_EXPORT_SAGA")
export const fetchOnTrackExport = createAction("FETCH_ONTRACK_EXPORT_SAGA")
export const fetchOffTrackExport = createAction("FETCH_OFFTRACK_EXPORT_SAGA")
export const fetchOverallRiskExport = createAction("FETCH_OVERALL_RISK_EXPORT_SAGA")
export const fetchYE9RiskExport = createAction("FETCH_YE9_RISK_EXPORT_SAGA")
export const fetchFlagsExport = createAction<FlagStatusAndTypePayload>("FETCH_FLAGS_EXPORT_SAGA")
export const fetchStudentTranscriptExport = createAction<StudentIDAndNamePayload>("FETCH_STUDENT_TRANSCRIPT_EXPORT_SAGA")
