
export const DEFAULT_GROUP_LIST_PAGE_SIZE = 25

export interface StudentGroupDetails {
  groupId: string;
  name: string;
  studentIds: Array<string>;
  specialSelectors: Array<{
    ye9Filters: Array<string>;
    lastNameFilter: [string, string];
    campusIds: Array<string>;
  }>;
}

export const emptyStudentGroupDetails = {
  groupId: "",
  name: "",
  studentIds: [],
  specialSelectors: []
}

export const mockStudentGroupDetails = {
  groupId: "-1",
  name: "Mock Group",
  studentIds: ["12345", "6789"],
  specialSelectors: [{
    lastNameFilter: ['A','B'],
    ye9Filters: ['2019'],
    campusIds: ['-1']
  }]
}

export interface CampusOption {
  externalId: string;
  name: string;
}

export const mockUserCampuses: Array<CampusOption> = [
  { externalId: "-1", name: "Mock Campus 1" },
  { externalId: "-2", name: "Mock Campus 2" }
]

export interface StudentGroup {
  groupName: string;
  systemUserGroupId: string;
  hasDetails: boolean;
  groupDetails?: StudentGroupDetails | null;
  studentCntMessage: string;
  singleStudentsAdded: boolean;
  autogen: boolean;
}

export const emptyStudentGroup: StudentGroup = {
  groupName: "",
  systemUserGroupId: "",
  hasDetails: false,
  groupDetails: null,
  studentCntMessage: "",
  singleStudentsAdded: false,
  autogen: false,
}

export interface RawStudentGroup {
  groupName: string;
  systemUserGroupId: string;
  hasDetails: string;
  groupDetails: string;
  studentCntMessage: string;
  singleStudentsAdded: string;
  autogen: boolean;
}

export const mockStudentGroups: Array<RawStudentGroup> = [
  { groupName: "Mock Group", systemUserGroupId: "-1", hasDetails: "true", groupDetails: JSON.stringify(mockStudentGroupDetails), studentCntMessage: "Student count Message", singleStudentsAdded: "false", autogen: false},
  { groupName: "Mock Group", systemUserGroupId: "-2", hasDetails: "false", groupDetails: "null", studentCntMessage: "Student count Message", singleStudentsAdded: "true", autogen: true},
  { groupName: "Mock Group", systemUserGroupId: "-3", hasDetails: "true", groupDetails: JSON.stringify({ ...mockStudentGroupDetails, specialSelectors: [], groupId: "-3" }), studentCntMessage: "Student count Message", singleStudentsAdded: "true", autogen: false},
]


export interface CreateGroupSpecialSelector {
  campusIds: Array<string>;
  ye9Filters: Array<string>;
  lastNameFilter: [string, string];
}

export interface GroupStudent {
  assignedExternalId: string;
  studentName: string;
  systemUserId: string;
  singularAdd: boolean;
}

export interface GroupList {
  groupName: string;
  students: Array<GroupStudent>;
  numberOfPages: number;
}

export const mockGroupList = {
  groupName: "Mock group",
  students: [
    { assignedExternalId: "-1", studentName: "Mock student", systemUserId: "-1", singularAdd: false },
    { assignedExternalId: "-2", studentName: "Mock student", systemUserId: "-2", singularAdd: true },
    { assignedExternalId: "-3", studentName: "Mock student", systemUserId: "-3", singularAdd: false },
    { assignedExternalId: "-1", studentName: "Mock student", systemUserId: "-1", singularAdd: false },
    { assignedExternalId: "-2", studentName: "Mock student", systemUserId: "-2", singularAdd: true },
    { assignedExternalId: "-3", studentName: "Mock student", systemUserId: "-3", singularAdd: false },
    { assignedExternalId: "-1", studentName: "Mock student", systemUserId: "-1", singularAdd: false },
    { assignedExternalId: "-2", studentName: "Mock student", systemUserId: "-2", singularAdd: true },
    { assignedExternalId: "-3", studentName: "Mock student", systemUserId: "-3", singularAdd: false },
  ],
  numberOfPages: 10
}

export const emptyGroupList = {
  groupName: "",
  students: [],
  numberOfPages: 1
}

export interface GroupIDPayload {
  groupId: string | number;
}

export interface GetStudentsByGroupIdPayload {
  groupId: string;
  groupName: string;
  page: string | number;
  isSingleAddOnly: boolean;
}

export interface AddOrRemoveGroupStudentPayload {
  groupId: string;
  studentId: string;
}

export interface CreateGroupPayload {
  name: string;
  groupId: string | number;
  resetSingleAddedStudents: boolean;
  studentIds: Array<string>;
  specialSelectors: Array<CreateGroupSpecialSelector>;
}
