
import { createSlice } from '@reduxjs/toolkit'

/* This slice works with redux-saga to provide side effects
   around loading Indicators and Flags.  The Immediate load is triggered
   by a filter status update.
*/

export interface LoadTriggersState {
  flagsPageShouldLoadEventual: boolean;
  flagsPageShouldLoadImmediate: boolean;
  indicatorsPageShouldLoadEventual: boolean;
  indicatorsPageShouldLoadImmediate: boolean;
}

const initialState: LoadTriggersState = {
  flagsPageShouldLoadEventual: true,
  flagsPageShouldLoadImmediate: false,
  indicatorsPageShouldLoadEventual: true,
  indicatorsPageShouldLoadImmediate: false,
}

export const loadTriggersSlice = createSlice({
  name: 'loadTriggers',
  initialState,
  reducers: {
    triggerEventualFlagPageLoad: (state) => {
      state.flagsPageShouldLoadEventual = true
      state.flagsPageShouldLoadImmediate = false
    },
    triggerEventualIndicatorsPageLoad: (state) => {
      state.indicatorsPageShouldLoadEventual = true
      state.indicatorsPageShouldLoadImmediate = false
    },
    triggerImmediateFlagPageLoad: (state) => {
      state.flagsPageShouldLoadEventual = false
      state.flagsPageShouldLoadImmediate = true
    },
    triggerImmediateIndicatorsPageLoad: (state) => {
      state.indicatorsPageShouldLoadEventual = false
      state.indicatorsPageShouldLoadImmediate = true
    },
    flagsPageLoaded: (state) => {
      state.flagsPageShouldLoadEventual = false
      state.flagsPageShouldLoadImmediate = false
    },
    indicatorsPageLoaded: (state) => {
      state.indicatorsPageShouldLoadEventual = false
      state.indicatorsPageShouldLoadImmediate = false
    }
  }
})