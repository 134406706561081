
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

interface PanelSupportCompletionPropTypes {
  reset: Function;
}

const PanelSupportCompletion = ({
  reset
}: PanelSupportCompletionPropTypes) => {

  return (
    <MesaUI.Container style={{ textAlign: "center" }}>
      <MesaUI.Container paddingType={{ top: "lg", bottom: "sm" }}>
        <h3>Success!</h3>
      </MesaUI.Container>
      <MesaUI.Container paddingType={["sm", "md", "sm", "md"]}>
        <p style={{ textIndent: "0" }}>
          Thank you for reaching out! We will get back to you at your account&apos;s email address as soon as we can.
        </p>
      </MesaUI.Container>
      <MesaUI.FlexRow style={{ justifyContent: "center" }}>
        <MesaUI.Container paddingType="sm">
          <MesaUI.Button buttonType="primary" onClick={reset}>Go Back</MesaUI.Button>
        </MesaUI.Container>
      </MesaUI.FlexRow>
    </MesaUI.Container>
  )
}

export default PanelSupportCompletion