/*
  The effective student ID for the student page has been put into Context, since it doesn't necessarily warrant
  the redux store, but as a prop it would create endlesss prop drilling within all the page components without some 
  sort of global provider.
*/

import { createContext, useContext } from 'react'

interface StudentPageContextType {
  studentId: string;
  studentLeftPanelCollapsed: boolean;
}

export const StudentPageContext = createContext<StudentPageContextType>({ studentId: "", studentLeftPanelCollapsed: false })

export const useStudentPageContext = () => useContext(StudentPageContext)