
import { combineReducers } from '@reduxjs/toolkit'

import { apiHealthSlice } from './apiHealth'
import { userSlice } from './user'
import { errorSlice } from './error'
import { notesSlice } from './notes'
import { fileExportSlice } from './fileExport'
import { supportSlice } from './support'
import { searchSlice } from './search'
import { filterSlice } from './filter'
import { groupsSlice } from './groups'
import { flagsSlice } from './flags'
import { studentSlice } from './student'
import { viewSlice } from './view'
import { loadTriggersSlice } from './loadTriggers'
import { indicatorsSlice } from './indicators'

export const rootReducer = combineReducers({
  apiHealth: apiHealthSlice.reducer,
  user: userSlice.reducer,
  error: errorSlice.reducer,
  notes: notesSlice.reducer,
  fileExport: fileExportSlice.reducer,
  search: searchSlice.reducer,
  filter: filterSlice.reducer,
  groups: groupsSlice.reducer,
  flags: flagsSlice.reducer,
  student: studentSlice.reducer,
  support: supportSlice.reducer,
  view: viewSlice.reducer,
  loadTriggers: loadTriggersSlice.reducer,
  indicators: indicatorsSlice.reducer
})

export type StoreState = ReturnType<typeof rootReducer>