
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

import { OverallFlagCounts } from '../../store/indicators/indicatorsDomain'
import { MesaColorEnum } from '@mesacloud/corejs'
import { ResponsivePie } from '@nivo/pie'

interface IndicatorsOverallPiePropTypes {
  isLoading: boolean | null | undefined;
  flagCounts: OverallFlagCounts;
  findFlagTypeColor: (flagName: string) => MesaColorEnum;
  callExport: () => any;
}

const IndicatorsOverallPie = ({
  isLoading,
  flagCounts,
  findFlagTypeColor,
  callExport
}: IndicatorsOverallPiePropTypes) => {

  const formattedFlagCounts = (flagCounts === null || typeof flagCounts !== "object") ? [] : (
    Object.entries(flagCounts).map(([key, val], i) => ({
      id: key,
      label: key,
      value: parseInt(val + "", 10) || 0,
      color: findFlagTypeColor(key).hex
    }))
  )

  const totalFlags = formattedFlagCounts.reduce((total, { value }) => value + total, 0)

  return (
    <MesaUI.Card className={{ "indicators__card--flags": true, "loading-whirl": isLoading }}>
      <MesaUI.CardHeader>
        <MesaUI.CardHeaderLeft>
          <h3>Unresolved Flags</h3>
        </MesaUI.CardHeaderLeft>
        <MesaUI.CardHeaderRight>
          <MesaUI.Button buttonType="primary" onClick={callExport}>
            Export
          </MesaUI.Button>
        </MesaUI.CardHeaderRight>
      </MesaUI.CardHeader>
      <MesaUI.CardContent>
        <MesaUI.FlexRow>
          <MesaUI.FlexCol>
            <MesaUI.Container className="indicators__pie-wrapper">
            <div className="indicators__pie-wrapper__total">
              <div>{totalFlags}</div>
              <div>Flags</div>
            </div>
              <ResponsivePie
                data={formattedFlagCounts}
                innerRadius={0.45}
                animate={true}
                padAngle={2}
                margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
                enableSlicesLabels={false}
                radialLabelsLinkColor={{ from: "color" }}
                enableRadialLabels={false}
                colors={formattedFlagCounts.map(({ color }) => color)}
              />
            </MesaUI.Container>
          </MesaUI.FlexCol>
          <MesaUI.FlexCol>
            <MesaUI.Container paddingType="lg" className="indicators__flag-bars">
              {formattedFlagCounts.map((flagData, i) => (
                <MesaUI.Container key={`indicators-flag-count-bar-${i}-${flagData.label}`} paddingType="sm" style={{ backgroundColor: flagData.color }}>
                  <div>{flagData.value}</div>
                  <div>{flagData.label}</div>
                </MesaUI.Container>
              ))}
            </MesaUI.Container>
          </MesaUI.FlexCol>
        </MesaUI.FlexRow>
      </MesaUI.CardContent>
    </MesaUI.Card>
  )
}

export default IndicatorsOverallPie
