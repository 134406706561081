import uuid from 'uuid/v4'

export interface FlagType {
  flagName: string;
  flagTypeId: string | number;
}

export const mockFlagTypes: Array<FlagType> = [
  { flagName: "Mock Flag 1", flagTypeId: "-1" },
  { flagName: "Mock Flag 2", flagTypeId: "-2"},
  { flagName: "Mock Flag 3", flagTypeId: "-3"}
]

export interface FlagDTO {
  campusName: string;
  districtAssignedStudentId: string;
  firstName: string;
  lastName: string;
  flagName: string;
  flagType: string;
  flagTypeId: string;
  lastModifiedDate: string;
  noteCnt: string | number;
  notes: [];
  status: string;
  studentFlagId: string;
  studentId: string;
  studentName: string;
  studentFlagUUID: string;
  studentSystemUserUUID: string;
}

export const mockFlags: Array<FlagDTO> = []
const n = Math.round(Math.random() * 50 + 1)
for(let i = 0; i < n; i++){
  mockFlags.push({
    campusName: "Mock Campus",
    districtAssignedStudentId: uuid(),
    firstName: "Mock",
    lastName: "Student",
    flagName: "Mock Flag Name",
    flagType: "Mock Flag Type",
    flagTypeId: "-1",
    lastModifiedDate: new Date().toISOString(),
    noteCnt: Math.round(Math.random() * 10),
    notes: [],
    status: "Unresolved",
    studentFlagId: "01",
    studentId: "-1",
    studentName: "Mock Student",
    studentFlagUUID: uuid(),
    studentSystemUserUUID: uuid()
  })
}

export interface FlagStatusAndTypePayload {
  flagTypeId: string | number;
  flagStatus: string | number;
}

export interface UpdateFlagsStatusPayload {
  status: string;
  studentFlagIds: Array<string | number>;
}

export type AvailableFlagSortKey = "studentName" | "studentId" | "flagType" | "lastModifiedDate"

export interface GetFlagsPayload {
  flagStatus: string;
  flagTypeId: string | number;
  sortKey: AvailableFlagSortKey;
  sortAsc: boolean;
  size: string | number;
  page: string | number;
}