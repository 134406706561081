
import { userSlice } from './user'
import { errorSlice } from './error'
import { notesSlice } from './notes'
import { apiHealthSlice } from './apiHealth'
import { fileExportSlice } from './fileExport'
import { searchSlice } from './search'
import { groupsSlice } from './groups'
import { flagsSlice } from './flags'
import { studentSlice } from './student'
import { supportSlice } from './support'
import { viewSlice } from './view'
import { loadTriggersSlice } from './loadTriggers'
import { indicatorsSlice } from './indicators'

export const apiHealthActions = apiHealthSlice.actions
export const userActions = userSlice.actions
export const errorActions = errorSlice.actions
export const notesActions = notesSlice.actions
export const fileExportActions = fileExportSlice.actions
export const searchActions = searchSlice.actions
export const groupsActions = groupsSlice.actions
export const flagsActions = flagsSlice.actions
export const studentActions = studentSlice.actions
export const viewActions = viewSlice.actions
export const supportActions = supportSlice.actions
export const loadTriggersActions = loadTriggersSlice.actions
export const indicatorsActions = indicatorsSlice.actions
