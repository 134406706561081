// @flow

// Akita settings

export const akitaSettings = Object.freeze({
  NAME: 'AKITA',
  SITE_ID: '8054fa30-99c5-11e9-8a0b-5168ed9c08cc',
  SCRIPT_SRC: 'https://tracking.akitaapp.com',
  SCRIPT_ID: 'AKITA_SCRIPT_01',
  INIT_CONFIRM_ATTEMPT_MAX: 5,
  TRACK_ATTEMPT_MAX: 10
});

const PENDO_API_KEY = 'feae0770-d3a4-4bb5-4152-9b9dd6c5d41f';

export const pendoSettings = Object.freeze({
  NAME: 'PENDO',
  API_KEY: PENDO_API_KEY,
  SCRIPT_ID: 'PENDO_SCRIPT_01',
  SCRIPT_SRC: `https://cdn.pendo.io/agent/static/${PENDO_API_KEY}/pendo.js`,
  INIT_CONFIRM_ATTEMPT_MAX: 5,
  TRACK_ATTEMPT_MAX: 10
});
