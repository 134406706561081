
import React from 'react'
import MesaUI from '@mesacloud/react-framework'
import { useDispatch } from 'react-redux'

import { 
  useSelector, 
  // groupsSagaActions, 
  groupsActions, 
  viewActions 
} from '../../../store'

// import { getLocalStorageState } from '../../../lib/utils'

import PanelGroupListNoSelect from './PanelGroupListNoSelect'
import PanelGroupListSelected from './PanelGroupListSelected'

const PanelGroupList = () => {

  const dispatch = useDispatch()

  const {
    isLoadingGroupList,
    isAddingStudentToGroup,
    isRemovingStudentFromGroup,
    groupIsSelected,
    selectedGroupList,
  } = useSelector(({ groups }) => groups)

  const isLoading = isLoadingGroupList || isAddingStudentToGroup || isRemovingStudentFromGroup

  // useEffect(() => {
    // const { selectedGroupId: localSelectedGroupId } = getLocalStorageState()
    // if(!groupIsSelected && localSelectedGroupId){
      // dispatch(groupsSagaActions.fetchGroupList({ page: 0, groupId: localSelectedGroupId, isSingleAddOnly: false }))
    // }
  // eslint-disable-next-line
  // }, [])

  return (
    <>
      <MesaUI.PanelHeader className="panel-groups__header">
        {groupIsSelected && <MesaUI.Button buttonType="secondary" onClick={() => dispatch(groupsActions.deselectGroup())}>Back</MesaUI.Button>}
        <h2>{groupIsSelected ? selectedGroupList.groupName : 'Select A Group'}</h2>
        <MesaUI.Button buttonType='close' onClick={() => dispatch(viewActions.closePanel())}/>
      </MesaUI.PanelHeader>
      <MesaUI.PanelContent className={{ "panel-groups": true, "loading-whirl": isLoading }}>
        {groupIsSelected ? (
          <PanelGroupListSelected/>
        ) : (
          <PanelGroupListNoSelect/>
        )}
      </MesaUI.PanelContent>
    </>
  )
}

export default PanelGroupList