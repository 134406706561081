
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  SearchStudent,
  GroupStudent
} from './searchDomain'

export interface SearchState {
  isLoadingGeneralStudentSearch: boolean;
  isLoadingGroupStudentSearch: boolean;
  isLoadingAddGroupStudentSearch: boolean;
  isLoadingCreateGroupStudentSearch: boolean;
  generalStudentSearchResults: Array<SearchStudent>;
  groupStudentSearchResults: Array<GroupStudent>;
  addGroupStudentSearchResults: Array<GroupStudent>;
  createGroupStudentSearchResults: Array<SearchStudent>;
}

const initialState: SearchState = {
  isLoadingGeneralStudentSearch: false,
  isLoadingGroupStudentSearch: false,
  isLoadingAddGroupStudentSearch: false,
  isLoadingCreateGroupStudentSearch: false,
  generalStudentSearchResults: [],
  groupStudentSearchResults: [],
  addGroupStudentSearchResults: [],
  createGroupStudentSearchResults: []
}

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    generalStudentSearchRequested: (state) => {
      state.isLoadingGeneralStudentSearch = true
    },
    getGeneralStudentSearch: (state, { payload: { students } }: PayloadAction<{ students: Array<SearchStudent> }>) => {
      state.isLoadingGeneralStudentSearch = false
      state.generalStudentSearchResults = students
    },
    groupStudentSearchRequested: (state) => {
      state.isLoadingGroupStudentSearch = true
    },
    getGroupStudentSearch: (state, { payload: { students } }: PayloadAction<{ students: Array<GroupStudent> }>) => {
      state.isLoadingGroupStudentSearch = false
      state.groupStudentSearchResults = students
    },
    addGroupStudentSearchRequested: (state) => {
      state.isLoadingAddGroupStudentSearch = true
    },
    getAddGroupStudentSearch: (state, { payload: { students } }: PayloadAction<{ students: Array<GroupStudent> }>) => {
      state.isLoadingAddGroupStudentSearch = false
      state.addGroupStudentSearchResults = students
    },
    createGroupStudentSearchRequested: (state) => {
      state.isLoadingCreateGroupStudentSearch = true
    },
    getCreateGroupStudentSearch: (state, { payload: { students } }: PayloadAction<{ students: Array<SearchStudent> }>) => {
      state.isLoadingCreateGroupStudentSearch = false
      state.createGroupStudentSearchResults = students
    }
  }
})
