import { createAction } from "@reduxjs/toolkit"

import { CreateFilterPayload, FilterIDPayload } from './filterDomain'

export const fetchFilterFields = createAction("FETCH_FILTER_FIELDS_SAGA")
export const fetchUserFilters = createAction("FETCH_USER_FILTERS_SAGA")
export const saveFilter = createAction<CreateFilterPayload>("SAVE_FILTER_SAGA")
export const deactivateFilter = createAction("DEACTIVATE_FILTER_SAGA")
export const activateFilter = createAction<FilterIDPayload>("ACTIVATE_FILTER_SAGA")
export const deleteFilter = createAction<FilterIDPayload>("DELETE_FILTER_SAGA")
export const fetchFilterDetails = createAction<FilterIDPayload>("FETCH_FILTER_DETAILS_SAGA")
export const fetchActiveFilterStatus = createAction("FETCH_ACTIVE_FILTER_STATUS_SAGA")
