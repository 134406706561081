
import React from 'react'
import createClassName from 'classnames'

interface HeaderExportsOptionPropTypes {
  displayText: string;
  isLoading: boolean;
  exportFunc: Function;
}

const HeaderExportsOption = ({
  displayText,
  isLoading,
  exportFunc
}: HeaderExportsOptionPropTypes) => {

  const onClick = () => {
    !isLoading && exportFunc()
  }

  const className = createClassName({
    'header__exports-list__item': true,
    'loading-whirl': isLoading
  })

  return (
    <li className={className} onClick={onClick}>
      <span>{displayText}</span><span>CSV</span>
    </li>
  )
}

export default HeaderExportsOption
