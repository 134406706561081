
import React from 'react'
import { ResponsiveBar } from "@nivo/bar"
import MesaUI from '@mesacloud/react-framework'
import { MesaColorEnum } from '@mesacloud/corejs'
import { useSelector } from '../../store'

import { RiskAssessment, RiskAssessmentByYE9 } from '../../store/indicators/indicatorsDomain'

import { StudentGradPlanStatusEnum } from '../../lib/enums'

import IndicatorsCardFooterStudentCount from './IndicatorsCardFooterStudentCount'

interface IndicatorsYE9BarPropTypes {
  isLoading: boolean | null | undefined;
  riskAssessmentByYE9: RiskAssessmentByYE9;
  riskAssessment: RiskAssessment;
  callExport: () => any;
}

// Returns an array of objects, sorted by YE9.
const formatRiskAssessmentsForNivo = (riskAssessmentByYE9: RiskAssessmentByYE9): Array<{
  ye9: string;
  [key: string]: number | string;
}> => (riskAssessmentByYE9 === null || typeof riskAssessmentByYE9 !== "object") ? [] : (
  Object.keys(riskAssessmentByYE9).sort().map(ye9Key => ({
    // ye9Key are usually later year - early year, we want to swap this
    // ex: 2023-2022 -> 2022->2023
    ye9: ye9Key === 'NA' ? ye9Key : `${ye9Key.split('-')[1]}-${ye9Key.split('-')[0]}`,
    [StudentGradPlanStatusEnum.NO_YE9_TRANSCRIPT.keyname]: parseInt(riskAssessmentByYE9[ye9Key]["No YE9 Transcript"], 10) || 0,
    [StudentGradPlanStatusEnum.ALERT.keyname]: parseInt(riskAssessmentByYE9[ye9Key]["Alert"], 10) || 0,
    [StudentGradPlanStatusEnum.ON_TRACK.keyname]: parseInt(riskAssessmentByYE9[ye9Key]["On Track"], 10) || 0,
    [StudentGradPlanStatusEnum.OFF_TRACK.keyname]: parseInt(riskAssessmentByYE9[ye9Key]["Off Track"], 10) || 0,
  }))
)

const IndicatorsYE9Bar = ({
  isLoading,
  riskAssessmentByYE9,
  riskAssessment,
  callExport
}: IndicatorsYE9BarPropTypes) => {
  const { filterIsActive } = useSelector(({ filter }) => filter)
  let formattedRiskAssessments = formatRiskAssessmentsForNivo(riskAssessmentByYE9);
  // If there's no filter active, we only want the graph to show the most recent 5 years
  if (!filterIsActive) {
    formattedRiskAssessments = formattedRiskAssessments.slice(-5);
  }

  return (
    <MesaUI.Card className={{ "indicators__card--ye9": true, "loading-whirl": isLoading }}>
      <MesaUI.CardHeader>
        <MesaUI.CardHeaderLeft>
          <h3>YE9 Comparison</h3>
        </MesaUI.CardHeaderLeft>
        <MesaUI.CardHeaderRight>
          <MesaUI.Button buttonType="primary" onClick={callExport}>
            Export
          </MesaUI.Button>
        </MesaUI.CardHeaderRight>
      </MesaUI.CardHeader>
      <MesaUI.CardContent>
        <MesaUI.Container className="indicators__bar-wrapper" style={{ height: "300px" }}>
          <ResponsiveBar
            data={formattedRiskAssessments}
            indexBy="ye9"
            keys={[StudentGradPlanStatusEnum.ON_TRACK.keyname, StudentGradPlanStatusEnum.ALERT.keyname, StudentGradPlanStatusEnum.OFF_TRACK.keyname, StudentGradPlanStatusEnum.NO_YE9_TRANSCRIPT.keyname]}
            colors={(c: any) => StudentGradPlanStatusEnum.getByKeyname(c?.id).color.hex}
            groupMode="grouped"
            padding={0.5}
            margin={{ top: 20, right: 30, bottom: 50, left: 70 }}
            enableLabel={false}
            axisBottom={{
              tickSize: 3,
              tickPadding: 3,
              tickRotation: 0,
              legend: isLoading ? "" : "Year Entered 9th Grade",
              legendPosition: "middle",
              legendOffset: 30
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 3,
              tickRotation: 0,
              legend: isLoading ? "" : "Students",
              legendPosition: "middle",
              legendOffset: -47
            }}
            tooltip={({ data }) => (
              <div>
                <div style={{ fontWeight: 'bold' }}>{data.ye9}</div>
                <MesaUI.Container marginType={{ top: "sm", bottom: "xs" }} fontColor={MesaColorEnum.SEAFOAM} style={{ fontWeight: "bold" }}>
                  {StudentGradPlanStatusEnum.ON_TRACK.displayName}:{" "}
                  <MesaUI.Container style={{ display: 'inline-block', fontWeight: 'normal' }} fontColor={MesaColorEnum.MIDNIGHT}>{data[StudentGradPlanStatusEnum.ON_TRACK.keyname]}</MesaUI.Container>
                </MesaUI.Container>
                <MesaUI.Container marginType={{ top: "sm", bottom: "xs" }} fontColor={MesaColorEnum.GOLD} style={{ fontWeight: "bold" }}>
                  {StudentGradPlanStatusEnum.ALERT.displayName}:{" "}
                  <MesaUI.Container style={{ display: 'inline-block', fontWeight: 'normal' }} fontColor={MesaColorEnum.MIDNIGHT}>{data[StudentGradPlanStatusEnum.ALERT.keyname]}</MesaUI.Container>
                </MesaUI.Container>
                <MesaUI.Container marginType={{ top: "sm", bottom: "xs" }} fontColor={MesaColorEnum.RED} style={{ fontWeight: "bold" }}>
                  {StudentGradPlanStatusEnum.OFF_TRACK.displayName}:{" "}
                  <MesaUI.Container style={{ display: 'inline-block', fontWeight: 'normal' }} fontColor={MesaColorEnum.MIDNIGHT}>{data[StudentGradPlanStatusEnum.OFF_TRACK.keyname]}</MesaUI.Container>
                </MesaUI.Container>
                <MesaUI.Container marginType={{ top: "sm", bottom: "xs" }} fontColor={MesaColorEnum.GRAY} style={{ fontWeight: "bold" }}>
                  {StudentGradPlanStatusEnum.NO_YE9_TRANSCRIPT.displayName}:{" "}
                  <MesaUI.Container style={{ display: 'inline-block', fontWeight: 'normal' }} fontColor={MesaColorEnum.MIDNIGHT}>{data[StudentGradPlanStatusEnum.NO_YE9_TRANSCRIPT.keyname]}</MesaUI.Container>
                </MesaUI.Container>
              </div>
            )}
          />
        </MesaUI.Container>
        <IndicatorsCardFooterStudentCount riskAssessment={riskAssessment}/>
      </MesaUI.CardContent>
    </MesaUI.Card>
  )
}

export default IndicatorsYE9Bar
