
import React, { useEffect, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '@mesacloud/react-framework'
import { MesaColorEnum } from '@mesacloud/corejs'

import { useSelector, userSagaActions, apiHealthSagaActions } from '../store'

import LoadingPage from '../pages/LoadingPage'

const RouterBlocker = ({
  children
}: { children: ReactElement }) => {

  const {
    failedToAuthorize,
    failedToAuthorizeReason,
    authorized,
    isLoadingWhoAmI,
    isLoggingOut,
  } = useSelector(({ user }) => user)

  const { 
    apiHealthCheckFailureReason, 
    isLoadingApiHealth, 
    apiHealthCheckSuccess,
    apiHealthCheckFailed
  } = useSelector(({ apiHealth }) => apiHealth)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(apiHealthSagaActions.fetchApiHealth())
  }, [dispatch])

  useEffect(() => {
    apiHealthCheckSuccess && dispatch(userSagaActions.fetchWhoAmI())
  }, [apiHealthCheckSuccess, dispatch])

  if(isLoadingApiHealth){
    return (
      <LoadingPage displayLoader>
        Connecting...
      </LoadingPage>
    )
  } else if(apiHealthCheckFailed){
    return (
      <LoadingPage>
        <MesaUI.Container style={{ textAlign: "center" }}>
          {apiHealthCheckFailureReason}
        </MesaUI.Container>
      </LoadingPage>
    )
  } else if(isLoggingOut) {
    return (
      <LoadingPage displayLoader>
        You are being securely logged out...
      </LoadingPage>
    )
  }
  else if(isLoadingWhoAmI || (!authorized && !failedToAuthorize)){
    return (
      <LoadingPage displayLoader>
        Authorizing...
      </LoadingPage>
    )
  } else if (failedToAuthorize){
    return (
      <LoadingPage>
        <MesaUI.Container fontColor={MesaColorEnum.RED} style={{ textAlign: "center" }}>
          {failedToAuthorizeReason}
        </MesaUI.Container>
      </LoadingPage>
    )
  } else {
    return children
  }
}

export default RouterBlocker