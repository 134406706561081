
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  StudentGroup,
  StudentGroupDetails,
  emptyStudentGroupDetails,
  CampusOption,
  GroupList,
  emptyGroupList
} from './groupsDomain'

export interface GroupsState {
  isLoadingStudentGroups: boolean;
  isLoadingGroupDetails: boolean;
  isLoadingCampusOptions: boolean;
  isLoadingGroupList: boolean;
  isAddingStudentToGroup: boolean;
  isDeletingGroup: boolean;
  isCreatingGroup: boolean;
  isRemovingStudentFromGroup: boolean;

  studentGroups: Array<StudentGroup>;
  groupDetails: StudentGroupDetails;
  campusOptions: Array<CampusOption>;
  groupIsSelected: boolean;
  selectedGroupId: string | number;
  selectedGroupName: string;
  selectedGroupList: GroupList;
}

const initialState: GroupsState = {
  isLoadingStudentGroups: false,
  isLoadingGroupDetails: false,
  isLoadingCampusOptions: false,
  isLoadingGroupList: false,
  isAddingStudentToGroup: false,
  isDeletingGroup: false,
  isCreatingGroup: false,
  isRemovingStudentFromGroup: false,

  studentGroups: [],
  groupDetails: emptyStudentGroupDetails,
  campusOptions: [],
  groupIsSelected: false,
  selectedGroupId: "",
  selectedGroupName: "",
  selectedGroupList: emptyGroupList,
}

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    studentGroupsRequested: (state) => {
      state.isLoadingStudentGroups = true
    },
    getStudentGroups: (state, { payload: { groups } }: PayloadAction<{ groups: Array<StudentGroup>}>) => {
      state.isLoadingStudentGroups = false
      state.studentGroups = groups
      const existingSelected = groups.find(g => g.groupName === state.selectedGroupName)
      if(existingSelected){
        state.selectedGroupId = existingSelected.systemUserGroupId
      }
    },
    groupDetailsRequested: (state) => {
      state.isLoadingGroupDetails = true
    },
    getGroupDetails: (state, { payload: { newGroup }}: PayloadAction<{ newGroup: StudentGroup }>) => {
      state.isLoadingGroupDetails = false
      state.groupDetails = newGroup.groupDetails || emptyStudentGroupDetails

      const existingGroupIndex = state.studentGroups.findIndex(({ systemUserGroupId }) => systemUserGroupId === newGroup.systemUserGroupId)
      if(existingGroupIndex !== -1){
        state.studentGroups.splice(existingGroupIndex, 1, newGroup)
      }
    },
    campusOptionsRequested: (state) => {
      state.isLoadingCampusOptions = true
    },
    getCampusOptions: (state, { payload: { campuses }}: PayloadAction<{ campuses: Array<CampusOption>}>) => {
      state.isLoadingCampusOptions = false
      state.campusOptions = campuses
    },
    groupListRequested: (state, { payload: { groupId, groupName }}: PayloadAction<{ groupId: string, groupName: string; }>) => {
      state.isLoadingGroupList = true
      state.groupIsSelected = true
      state.selectedGroupId = groupId
      state.selectedGroupName = groupName
    },
    getGroupList: (state, { payload: { groupList }}: PayloadAction<{ groupList: GroupList }>) => {
      state.isLoadingGroupList = false
      state.selectedGroupList = groupList
    },
    addStudentToGroupRequested: (state) => {
      state.isAddingStudentToGroup = true
    },
    addStudentToGroupComplete: (state) => {
      state.isAddingStudentToGroup = false
    },
    removeStudentFromGroupRequested: (state) => {
      state.isRemovingStudentFromGroup = true
    },
    removeStudentFromGroupComplete: (state) => {
      state.isRemovingStudentFromGroup = false
    },
    deleteGroupRequested: (state) => {
      state.isDeletingGroup = true
    },
    deleteGroupComplete: (state) => {
      state.isDeletingGroup = false
    },
    createGroupRequested: (state) => {
      state.isCreatingGroup = true
    },
    createGroupComplete: (state) => {
      state.isCreatingGroup = false
    },
    deselectGroup: (state) => {
      state.groupIsSelected = false
      state.selectedGroupList = initialState.selectedGroupList
    }
  }
})
