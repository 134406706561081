
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

import { AvailableFlagSortKey } from '../../store/flags/flagsDomain'

import FlagsTableHeaderSorter from './FlagsTableHeaderSorter'

interface FlagsTableHeaderPropTypes {
  selectSortKey: (sortKey: AvailableFlagSortKey) => any;
  selectAllFlags: () => any;
  allFlagsSelected: boolean | null | undefined;
  currentSortKey: AvailableFlagSortKey;
  currentAsc: boolean | null | undefined;
}

const FlagsTableHeader = ({
  selectAllFlags,
  selectSortKey,
  allFlagsSelected,
  currentSortKey,
  currentAsc
}: FlagsTableHeaderPropTypes) => (
  <MesaUI.TableRow header className="flags-card__table__header">
    <MesaUI.TableCell>
      <input type="checkbox" checked={!!allFlagsSelected} onChange={() => selectAllFlags()}/>
    </MesaUI.TableCell>
    <FlagsTableHeaderSorter displayTitle="Student Name" onSelect={() => selectSortKey("studentName")} isSelected={currentSortKey === "studentName"} isAsc={currentAsc}/>
    <FlagsTableHeaderSorter displayTitle="Student ID" onSelect={() => selectSortKey("studentId")} isSelected={currentSortKey === "studentId"} isAsc={currentAsc}/>
    <MesaUI.TableCell style={{ flex: 2 }}>Flag</MesaUI.TableCell>
    <FlagsTableHeaderSorter displayTitle="Type" onSelect={() => selectSortKey("flagType")} isSelected={currentSortKey === "flagType"} isAsc={currentAsc}/>
    <FlagsTableHeaderSorter displayTitle="Date Last Modified" onSelect={() => selectSortKey("lastModifiedDate")} isSelected={currentSortKey === "lastModifiedDate"} isAsc={currentAsc}/>
    <MesaUI.TableCell>Status</MesaUI.TableCell>
    <MesaUI.TableCell>Notes</MesaUI.TableCell>
  </MesaUI.TableRow>
)

export default FlagsTableHeader