
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '@mesacloud/react-framework'

import { FlagDTO } from '../../../store/flags/flagsDomain'
import { notesSagaActions, flagsSagaActions, viewActions, useSelector, studentSagaActions } from '../../../store'
import { FlagStatusEnum, PanelViewEnum } from '../../../lib/enums'
import { useStudentPageContext } from '../studentPageContext'

interface FlagsModalPropTypes {
  flags: Array<FlagDTO>;
  close: () => void;
}

const FlagsModal = ({
  flags,
  close
}: FlagsModalPropTypes) => {

  const dispatch = useDispatch()

  const { isUpdatingFlagStatus } = useSelector(({ flags }) => flags)
  const { isLoadingStudentInfo } = useSelector(({ student }) => student)

  const { studentId } = useStudentPageContext()

  useEffect(() => {
    return () => { isUpdatingFlagStatus && dispatch(studentSagaActions.fetchStudentInfo({ studentId })) }
    // eslint-disable-next-line
  }, [isUpdatingFlagStatus])

  return (
    <MesaUI.Modal closeFunc={close}>
      <MesaUI.Card className={{ "flags-modal-card": true, "loading-whirl": isLoadingStudentInfo || isUpdatingFlagStatus }}>
        <MesaUI.CardHeader>
          <MesaUI.CardHeaderLeft>
            <h3>Flags</h3>
          </MesaUI.CardHeaderLeft>
          <MesaUI.CardHeaderRight>
            <MesaUI.Button buttonType="close" onClick={close}/>
          </MesaUI.CardHeaderRight>
        </MesaUI.CardHeader>
        <MesaUI.CardContent>
          <MesaUI.Table>
            <MesaUI.TableRow header>
              <MesaUI.TableCell>Flag</MesaUI.TableCell>
              <MesaUI.TableCell>Type</MesaUI.TableCell>
              <MesaUI.TableCell>Date Last Modified</MesaUI.TableCell>
              <MesaUI.TableCell>Status</MesaUI.TableCell>
              <MesaUI.TableCell>Notes</MesaUI.TableCell>
            </MesaUI.TableRow>
            {Array.isArray(flags) && flags.map((flag, i) => (
              <MesaUI.TableRow key={`student-flags-modal-flag-${i}-${flag?.studentFlagUUID}`}>
                <MesaUI.TableCell>{flag?.flagName || "N/A"}</MesaUI.TableCell>
                <MesaUI.TableCell>{flag?.flagType || "N/A"}</MesaUI.TableCell>
                <MesaUI.TableCell>{flag?.lastModifiedDate || "N/A"}</MesaUI.TableCell>
                <MesaUI.TableCell>
                  <select 
                    value={FlagStatusEnum.getByDisplayName(flag.status).keyname} 
                    onChange={({ target: { value }}) => {
                      const current = FlagStatusEnum.getByDisplayName(flag.status).keyname
                      if(current !== value){
                        dispatch(flagsSagaActions.updateFlagsStatus({ studentFlagIds: [flag?.studentFlagId], status: value }))
                      }
                    }}
                  >
                    {FlagStatusEnum.asArray().map(({ displayName, keyname }, ii) => (
                      <option key={`student-flags-modal-flag-${i}-status-option-${ii}-${keyname}`} value={keyname}>
                        {displayName}
                      </option>
                    ))}
                  </select>
                </MesaUI.TableCell>
                <MesaUI.TableCell>
                  <i className="fas fa-sticky-note" onClick={() => {
                    dispatch(notesSagaActions.fetchFlagNotes({ flagUuid: flag?.studentFlagUUID, userUuid: flag?.studentSystemUserUUID, studentName: flag?.studentName, flagName: flag?.flagName }))
                    dispatch(viewActions.setPanelView({ view: PanelViewEnum.NOTES }))
                    dispatch(viewActions.openPanel())
                    close()
                  }}/>
                </MesaUI.TableCell>
              </MesaUI.TableRow>
            ))}
          </MesaUI.Table>
        </MesaUI.CardContent>
      </MesaUI.Card>
    </MesaUI.Modal>
  )
}

export default FlagsModal