
import { createSlice } from '@reduxjs/toolkit'

export interface ErrorState {
  errorIsDisplayed: boolean;
  errorMessage: string;  
  errorHistory: Array<{ ts: Date; errorMessage: string }>;
}

const initialState: ErrorState = {
  errorIsDisplayed: false,
  errorMessage: "",
  errorHistory: [],
}

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    displayError: (state, { payload: { errorMessage } }: { payload: { errorMessage: string }}) => {
      state.errorIsDisplayed = true
      state.errorMessage = errorMessage
      state.errorHistory.push({ ts: new Date(), errorMessage })
      console.error(errorMessage)
    },
    hideError: (state) => {
      state.errorIsDisplayed = false
    }
  }
})