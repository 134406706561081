
import { all } from 'redux-saga/effects'

import { rootApiHealthSaga } from './apiHealth'
import { rootUserSaga } from './user'
import { rootErrorSaga } from './error'
import { rootNotesSaga } from './notes'
import { rootFileExportSaga } from './fileExport'
import { rootFilterSaga } from './filter'
import { rootSupportSaga } from './support'
import { rootSearchSaga } from './search'
import { rootGroupsSaga } from './groups'
import { rootFlagsSaga } from './flags'
import { rootStudentSaga } from './student'
import { rootLoadTriggersSaga } from './loadTriggers'
import { rootIndicatorsSaga } from './indicators'

export function* rootSaga(){
  yield all([
    rootApiHealthSaga(),
    rootUserSaga(),
    rootErrorSaga(),
    rootNotesSaga(),
    rootFilterSaga(),
    rootFileExportSaga(),
    rootSearchSaga(),
    rootGroupsSaga(),
    rootFlagsSaga(),
    rootStudentSaga(),
    rootSupportSaga(),
    rootLoadTriggersSaga(),
    rootIndicatorsSaga(),
  ])
}