
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import MesaUI from '@mesacloud/react-framework'

import { AppPathEnum } from '../lib/enums'
import { UserRoleEnum } from '../lib/enums'

import RoleFilter from '../components/RoleFilter'

import Shell from '../components/layoutShell'
import PanelSwitch from '../components/panel'
import RouterBlocker from './RouterBlocker'
import IndicatorsPage from '../pages/indicators/Indicators'
import StudentPage from '../pages/student/Student'
import FourOFourPage from '../pages/FourOFour'
import FlagsPage from '../pages/flags'
import GroupsPage from '../pages/groups/Groups'
import ResourcesPage from '../pages/resources'

const Router = () => (
  <RouterBlocker>
    <BrowserRouter>
      <Shell/>
      <PanelSwitch/>
      <MesaUI.Main withFullShell>
        <Switch>
          <Route 
            path={AppPathEnum.HOME.path} 
            exact={AppPathEnum.HOME.exact}
          >
            <RoleFilter roles={[UserRoleEnum.STUDENT]}>
              <StudentPage/>
            </RoleFilter>
            <RoleFilter roles={[UserRoleEnum.STUDENT]} exclude>
              <IndicatorsPage/>
            </RoleFilter>
          </Route>
          <Route 
            path={AppPathEnum.GROUPS.path} 
            exact={AppPathEnum.GROUPS.exact}
          >
            <GroupsPage/>
          </Route>
          <Route 
            path={AppPathEnum.FLAGS.path} 
            exact={AppPathEnum.FLAGS.exact}
          >
            <FlagsPage/>
          </Route>
          <Route 
            path={AppPathEnum.RESOURCES.path} 
            exact={AppPathEnum.RESOURCES.exact}
          >
            <ResourcesPage/>
          </Route>
          <Route 
            path={AppPathEnum.STUDENT.path} 
            exact={AppPathEnum.STUDENT.exact}
          >
            <StudentPage/>
          </Route>
          <Route 
            path={AppPathEnum.FOUR_O_FOUR.path} 
            exact={AppPathEnum.FOUR_O_FOUR.exact}
          >
            <FourOFourPage/>
          </Route>
        </Switch>
      </MesaUI.Main>
    </BrowserRouter>
  </RouterBlocker>
)

export default Router