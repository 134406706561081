
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {  
  OverallFlagCounts,
  RiskAssessment,
  RiskAssessmentByYE9,
  StudentsWithFlagsCounts,
  FlagCountsByCampus,
  FlagCountsByDem,
  FlagCountsByYE9,
  emptyRiskAssessment,
  emptyStudentsWithFlagsCount
} from './indicatorsDomain'

export interface IndicatorsState {
  isLoadingOverallRiskAssessment: boolean;
  isLoadingRiskAssessmentByYE9: boolean;
  isLoadingOverallFlagCounts: boolean;
  isLoadingStudentsWithFlagsCounts: boolean;
  isLoadingFlagCountsByCampus: boolean;
  isLoadingFlagCountsByYE9: boolean;
  isLoadingFlagCountsByDem: boolean;
  
  totalStudentCount: number;
  overallFlagCounts: OverallFlagCounts;
  overallRiskAssessment: RiskAssessment;
  riskAssessmentByYE9: RiskAssessmentByYE9;
  studentsWithFlagsCounts: StudentsWithFlagsCounts;
  flagCountsByCampus: Array<FlagCountsByCampus>;
  flagCountsByDem: Array<FlagCountsByDem>;
  flagCountsByYE9: Array<FlagCountsByYE9>;
}

const initialState: IndicatorsState = {
  isLoadingOverallRiskAssessment: false,
  isLoadingRiskAssessmentByYE9: false,
  isLoadingOverallFlagCounts: false,
  isLoadingStudentsWithFlagsCounts: false,
  isLoadingFlagCountsByCampus: false,
  isLoadingFlagCountsByYE9: false,
  isLoadingFlagCountsByDem: false,

  totalStudentCount: 0,
  overallFlagCounts: {},
  overallRiskAssessment: emptyRiskAssessment,
  riskAssessmentByYE9: {},
  studentsWithFlagsCounts: emptyStudentsWithFlagsCount,
  flagCountsByCampus: [],
  flagCountsByDem: [],
  flagCountsByYE9: []
}

export const indicatorsSlice = createSlice({
  name: "indicators",
  initialState,
  reducers: {
    overallRiskAssessmentRequested: (state) => {
      state.isLoadingOverallRiskAssessment = true
    },
    getOverallRiskAssessment: (state, { 
      payload: {
        overallRiskAssessment,
        totalStudentCount
      }
    }: PayloadAction<{ 
      overallRiskAssessment: RiskAssessment;
      totalStudentCount: number;
    }>) => {
      state.isLoadingOverallRiskAssessment = false
      state.overallRiskAssessment = overallRiskAssessment
      state.totalStudentCount = totalStudentCount
    },
    riskAssessmentByYE9Requested: (state) => {
      state.isLoadingRiskAssessmentByYE9 = true
    },
    getRiskAssessmentByYE9: (state, { payload: { riskAssessmentByYE9 }}: PayloadAction<{ riskAssessmentByYE9: RiskAssessmentByYE9 }>) => {
      state.isLoadingRiskAssessmentByYE9 = false
      state.riskAssessmentByYE9 = riskAssessmentByYE9
    },
    overallFlagCountsRequested: (state) => {
      state.isLoadingOverallFlagCounts = true
    },
    getOverallFlagCounts: (state, { payload: { overallFlagCounts }}: PayloadAction<{ overallFlagCounts: OverallFlagCounts }>) => {
      state.isLoadingOverallFlagCounts = false
      state.overallFlagCounts = overallFlagCounts
    },
    studentsWithFlagsCountsRequested: (state) => {
      state.isLoadingStudentsWithFlagsCounts = true
    },
    getStudentsWithFlagsCounts: (state, { payload: { studentsWithFlagsCounts }}: PayloadAction<{ studentsWithFlagsCounts: StudentsWithFlagsCounts }>) => {
      state.isLoadingStudentsWithFlagsCounts = false
      state.studentsWithFlagsCounts = studentsWithFlagsCounts
    },
    flagCountsByCampusRequested: (state) => {
      state.isLoadingFlagCountsByCampus = true
    },
    getFlagCountsByCampus: (state, { payload: { flagCounts }}: PayloadAction<{ flagCounts: Array<FlagCountsByCampus> }>) => {
      state.isLoadingFlagCountsByCampus = false
      state.flagCountsByCampus = flagCounts
    },
    flagCountsByYE9Requested: (state) => {
      state.isLoadingFlagCountsByYE9 = true
    },
    getFlagCountsByYE9: (state, { payload: { flagCounts }}: PayloadAction<{ flagCounts: Array<FlagCountsByYE9> }>) => {
      state.isLoadingFlagCountsByYE9 = false
      state.flagCountsByYE9 = flagCounts
    },
    flagCountsByDemRequested: (state) => {
      state.isLoadingFlagCountsByDem = true
    },
    getFlagCountsByDem: (state, { payload: { flagCounts }}: PayloadAction<{ flagCounts: Array<FlagCountsByDem> }>) => {
      state.isLoadingFlagCountsByDem = false
      state.flagCountsByDem = flagCounts
    }
  }
})
