
import { takeLatest, call, all, put } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import { 
  RiskAssessment,
  mockRiskAssessment,
  RiskAssessmentByYE9,
  StudentsWithFlagsCounts,
  FlagCountsByCampus,
  FlagCountsByDem,
  FlagCountsByYE9,
  OverallFlagCounts,
  emptyRiskAssessment,
  emptyStudentsWithFlagsCount,
  mockFlagCountsByCampus,
  mockFlagCountsByDem,
  mockFlagCountsByYE9,
  mockRiskAssessmentByYE9,
  mockOverallFlagCounts
} from './indicatorsDomain'
import * as indicatorsSagaActions from './indicatorsSagaActions'
import { errorSlice } from '../error/errorSlice'
import { indicatorsSlice } from './indicatorsSlice'

function* fetchOverallRiskAssessmentSaga(){
  try {
    yield put(indicatorsSlice.actions.overallRiskAssessmentRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ overallRiskAssessmentBean: RiskAssessment }>('/api/overallindicator', {}, { overallRiskAssessmentBean: mockRiskAssessment })
    })

    const overallRiskAssessment = result?.data?.overallRiskAssessmentBean || emptyRiskAssessment
    // If a value does not exist, it will be parsed into NaN, which is falsy,
    // so we'll get a zero.
    let no_ye9 = parseInt(overallRiskAssessment["No YE9"], 10) || 0
    let no_transcript = parseInt(overallRiskAssessment["No Transcripts"], 10) || 0
    let undetermined = parseInt(overallRiskAssessment["Undetermined"], 10) || 0
    let no_ye9_trans = no_ye9 + no_transcript + undetermined
    overallRiskAssessment["No YE9 Transcript"] = String(no_ye9_trans)
    const totalStudentCount = Object.entries(overallRiskAssessment).reduce((total, [key, val]: [string, any]) => {
      if(["On Track", "Off Track", "Alert", "No YE9", "No Transcripts"].includes(key)){
        return (parseInt(val) || 0) + total
      } else {
        return total
      }
    }, 0)
    yield put(indicatorsSlice.actions.getOverallRiskAssessment({ overallRiskAssessment, totalStudentCount }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching overall risk assessment: ${e}` }))
    yield put(indicatorsSlice.actions.getOverallRiskAssessment({ overallRiskAssessment: emptyRiskAssessment, totalStudentCount: 0 }))
  }
}

function* fetchRiskAssessmentByYE9Saga(){
  try {
    yield put(indicatorsSlice.actions.riskAssessmentByYE9Requested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ cohortRiskAssessmentBean: RiskAssessmentByYE9 }>('/api/cohortcomparison', {}, { cohortRiskAssessmentBean: mockRiskAssessmentByYE9 })
    })

    var riskAssessmentByYE9 = result?.data?.cohortRiskAssessmentBean || {}
    for (let key in riskAssessmentByYE9) {
      // If a value does not exist, it will be parsed into NaN, which is falsy,
      // so we'll get a zero.
      let value = riskAssessmentByYE9[key]
      let no_ye9 = parseInt(value["No YE9"], 10) || 0
      let no_transcript = parseInt(value["No Transcripts"], 10) || 0
      let undetermined = parseInt(value["Undetermined"], 10) || 0
      let no_ye9_trans: number = no_ye9 + no_transcript + undetermined
      riskAssessmentByYE9[key]["No YE9 Transcript"] = String(no_ye9_trans)
    }
    yield put(indicatorsSlice.actions.getRiskAssessmentByYE9({ riskAssessmentByYE9 }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching YE9 risk assessment: ${e}` }))
    yield put(indicatorsSlice.actions.getRiskAssessmentByYE9({ riskAssessmentByYE9: {} }))
  }
}

function* fetchOverallFlagCountsSaga(){
  try {
    yield put(indicatorsSlice.actions.overallFlagCountsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ flagCounts: OverallFlagCounts }>('/api/flags/flagcounts', {}, { flagCounts: mockOverallFlagCounts })
    })
    const overallFlagCounts = result?.data?.flagCounts || {}
    yield put(indicatorsSlice.actions.getOverallFlagCounts({ overallFlagCounts }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching overall flag counts: ${e}` }))
    yield put(indicatorsSlice.actions.getOverallFlagCounts({ overallFlagCounts: {} }))
  }
}

function* fetchStudentsWithFlagsCountsSaga(){
  try {
    yield put(indicatorsSlice.actions.studentsWithFlagsCountsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ studentFlagCounts: StudentsWithFlagsCounts }>('/api/flags/studentcounts', {}, { studentFlagCounts: { studentsCount: 100, studentsCountWithMultipleFlags: 2123 } })
    })
    const studentsWithFlagsCounts = result?.data?.studentFlagCounts || emptyStudentsWithFlagsCount
    yield put(indicatorsSlice.actions.getStudentsWithFlagsCounts({ studentsWithFlagsCounts }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching overall flag counts: ${e}` }))
    yield put(indicatorsSlice.actions.getStudentsWithFlagsCounts({ studentsWithFlagsCounts: emptyStudentsWithFlagsCount }))
  }
}

function* fetchFlagCountsByCampusSaga(){
  try {
    yield put(indicatorsSlice.actions.flagCountsByCampusRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ campusFlagCounts: Array<FlagCountsByCampus> }>('/api/flags/campusflagcounts', {}, { campusFlagCounts: mockFlagCountsByCampus })
    })
    const flagCounts = result?.data?.campusFlagCounts || []
    yield put(indicatorsSlice.actions.getFlagCountsByCampus({ flagCounts }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching flag counts by campus: ${e}` }))
    yield put(indicatorsSlice.actions.getFlagCountsByCampus({ flagCounts: [] }))
  }
}

function* fetchFlagCountsByYE9Saga(){
  try {
    yield put(indicatorsSlice.actions.flagCountsByYE9Requested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ ye9FlagCounts: Array<FlagCountsByYE9> }>('/api/flags/ye9flagcounts', {}, { ye9FlagCounts: mockFlagCountsByYE9 })
    })
    const flagCounts = result?.data?.ye9FlagCounts || []
    yield put(indicatorsSlice.actions.getFlagCountsByYE9({ flagCounts }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching flag counts by YE9: ${e}` }))
    yield put(indicatorsSlice.actions.getFlagCountsByYE9({ flagCounts: [] }))
  }
}

function* fetchFlagCountsByDemSaga(){
  try {
    yield put(indicatorsSlice.actions.flagCountsByDemRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ demFlagCounts: Array<FlagCountsByDem> }>('/api/flags/demflagcounts', {}, { demFlagCounts: mockFlagCountsByDem })
    })
    const flagCounts = result?.data?.demFlagCounts || []
    yield put(indicatorsSlice.actions.getFlagCountsByDem({ flagCounts }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching flag counts by demographic: ${e}` }))
    yield put(indicatorsSlice.actions.getFlagCountsByDem({ flagCounts: [] }))
  }
}

export function* rootIndicatorsSaga(){
  yield all([
    takeLatest(indicatorsSagaActions.fetchOverallRiskAssessment.type, fetchOverallRiskAssessmentSaga),
    takeLatest(indicatorsSagaActions.fetchRiskAssessmentByYE9.type, fetchRiskAssessmentByYE9Saga),
    takeLatest(indicatorsSagaActions.fetchOverallFlagCounts.type, fetchOverallFlagCountsSaga),
    takeLatest(indicatorsSagaActions.fetchStudentsWithFlagsCounts.type, fetchStudentsWithFlagsCountsSaga),
    takeLatest(indicatorsSagaActions.fetchFlagCountsByCampus.type, fetchFlagCountsByCampusSaga),
    takeLatest(indicatorsSagaActions.fetchFlagCountsByYE9.type, fetchFlagCountsByYE9Saga),
    takeLatest(indicatorsSagaActions.fetchFlagCountsByDem.type, fetchFlagCountsByDemSaga)
  ])
}
