
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FlagDTO, FlagType } from './flagsDomain'

export interface FlagsState {
  isLoadingFlagTypes: boolean;
  isLoadingFlags: boolean;
  isUpdatingFlagStatus: boolean;

  totalFlagPages: number;
  flags: Array<FlagDTO>;
  flagTypes: Array<FlagType>;
}

const initialState: FlagsState = {
  isLoadingFlagTypes: false,
  isLoadingFlags: false,
  isUpdatingFlagStatus: false,

  totalFlagPages: 1,
  flags: [],
  flagTypes: []
}

export const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    flagTypesRequested: (state) => {
      state.isLoadingFlagTypes = true
    },
    getFlagTypes: (state, { payload: { flagTypes }}: PayloadAction<{ flagTypes: Array<FlagType> }>) => {
      state.isLoadingFlagTypes = false
      state.flagTypes = flagTypes
    },
    flagsRequested: (state) => {
      state.isLoadingFlags = true
    },
    getFlags: (state, { payload: { flags, totalPages } }: PayloadAction<{ flags: Array<FlagDTO>; totalPages: number }>) => {
      state.isLoadingFlags = false
      state.flags = flags
      state.totalFlagPages = totalPages
    },
    updateFlagStatusRequested: (state) => {
      state.isUpdatingFlagStatus = true
    },
    updateFlagStatusComplete: (state) => {
      state.isUpdatingFlagStatus = false
    }
  }
})
