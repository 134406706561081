
import React from 'react'
import createClassName from 'classnames'
import MesaUI from '@mesacloud/react-framework'

interface FlagsTableHeaderSorterPropTypes {
  onSelect: () => any;
  displayTitle: string;
  isSelected: boolean | null | undefined;
  isAsc: boolean | null | undefined;
}

const FlagsTableHeaderSorter = ({
  displayTitle,
  onSelect,
  isSelected,
  isAsc
}: FlagsTableHeaderSorterPropTypes) => (
  <MesaUI.TableCell className="flags-card__table__header__sorter" style={{ cursor: 'pointer' }} onClick={() => onSelect()}>
    <span>{displayTitle}</span>
    <i className={createClassName({ 'fa': true, 'fa-sort': !isSelected, 'fa-sort-up': isSelected && !isAsc, 'fa-sort-down': isSelected && isAsc })}/>
  </MesaUI.TableCell>
)

export default FlagsTableHeaderSorter