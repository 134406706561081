
import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react'
import MesaUI from '@mesacloud/react-framework'
import { MesaColorEnum } from '@mesacloud/corejs'

interface PanelSupportInputPropTypes {
  isLoading?: boolean;
  sendSupportMessage: (text: string) => any;
  supportMessageFailed: boolean;
  supportMessageFailureReason: string;
}

const PanelSupportInput = ({
  isLoading,
  supportMessageFailed,
  supportMessageFailureReason,
  sendSupportMessage
}: PanelSupportInputPropTypes) => {

  const [messageText, setMessageText]  = useState("")
  const [messageValid, setMessageValid] = useState(false)

  const hasText = messageText?.length > 0

  const clearText = () => setMessageText("")

  const handleTextareaChange = ({ target: { value }}: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageText(value)
  }

  const handleSubmitMessage = (e: FormEvent) => {
    e.preventDefault()
    messageValid && sendSupportMessage(messageText)
  }

  useEffect(() => {
    setMessageValid(hasText)
  }, [hasText, setMessageValid])

  return (
    <MesaUI.Container className={{
      "panel__support__input-view": true,
      "loading-whirl": isLoading
    }} style={{ minHeight: "100%" }}>
      <MesaUI.Container paddingType={{ top: "lg" }}>
        <h4 style={{ textAlign: "center" }}>Have a question?</h4>
        <MesaUI.Container paddingType="md" style={{ fontSize: "0.85em" }}>
          <p>
            {`If you have a question about how to use a feature in Mesa, 
            see something that you want to discuss with us, or are having trouble accessing anything in OnTime, 
            use this form to submit a request to our Support Team. We'll get back to you ASAP!`}
          </p>
        </MesaUI.Container>
      </MesaUI.Container>
      <MesaUI.Container paddingType="md">
        <form onSubmit={handleSubmitMessage}>
          <MesaUI.Container marginType={{ bottom: "md" }}>
            <textarea
              maxLength={1000}
              value={messageText}
              onChange={handleTextareaChange}
              draggable="false"
              style={{ width: "100%", resize: "none", minHeight: "200px" }}
            />
          </MesaUI.Container>
          {supportMessageFailed && (
            <MesaUI.Container fontColor={MesaColorEnum.RED} marginType={{ top: "0", bottom: "md", left: "md", right: "md" }} style={{ fontSize: "0.85em" }}>
              Error sending support message. Please try again later. ({supportMessageFailureReason})
            </MesaUI.Container>
          )}
          <MesaUI.FlexRow style={{ justifyContent: "center" }}>
            <MesaUI.Button buttonType="primary" onClick={clearText} marginType={{ right: "sm" }}>
              Clear
            </MesaUI.Button>
            <MesaUI.Button buttonType="primary" disabled={!messageValid} onClick={handleSubmitMessage}>
              Send
            </MesaUI.Button>
          </MesaUI.FlexRow>
        </form>
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default PanelSupportInput