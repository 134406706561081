
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI, { useClickOutside, useToggle } from '@mesacloud/react-framework'

import { FlagStatusEnum } from '../../lib/enums'
import { FlagType, FlagDTO } from '../../store/flags/flagsDomain'
import { flagsSagaActions, fileExportSagaActions, useSelector } from '../../store'

interface FlagsHeaderPropTypes {
  flagsStatus: FlagStatusEnum;
  selectedFlags: Array<FlagDTO>;
  selectFlagStatus: (status: FlagStatusEnum) => any;
  flagTypes: Array<FlagType>;
  selectedFlagType: FlagType | null;
  selectFlagType: (flagType: FlagType | null) => any;
}

const FlagsHeader = ({
  selectedFlags,
  flagsStatus,
  selectFlagStatus,
  flagTypes,
  selectFlagType,
  selectedFlagType
}: FlagsHeaderPropTypes) => {

  const [showFlagTypes, toggleShowFlagTypes] = useToggle(false)
  const flagTypeClickRef = useRef(null)

  const { isExportingFlags } = useSelector(({ fileExport }) => fileExport)

  useClickOutside(flagTypeClickRef, () => toggleShowFlagTypes(false))

  const dispatch = useDispatch()

  const flagsStatusActionable = !!selectedFlags?.length

  const updateSelectedFlags = (status: FlagStatusEnum) => {
    if(flagsStatusActionable){
      dispatch(flagsSagaActions.updateFlagsStatus({
        status: status.keyname,
        studentFlagIds: selectedFlags.map(({ studentFlagId }) => studentFlagId).filter((sfId) => typeof sfId === "string" || typeof sfId === "number")
      })) // flags page will reload after due to loadTriggersSaga side effect
    }
  }

  const resolveFlags = () => updateSelectedFlags(FlagStatusEnum.RESOLVED)
  const archiveFlags = () => updateSelectedFlags(FlagStatusEnum.ARCHIVED)

  return (
    <MesaUI.CardHeader className="flags-card__header">
      <MesaUI.Container marginType={{ left: "sm" }}>
        <h2>Manage Flags</h2>
      </MesaUI.Container>
      <MesaUI.Container className="flags-card__header__status-buttons">
        <MesaUI.Button buttonType="primary" disabled={!flagsStatusActionable} onClick={resolveFlags}>Resolve</MesaUI.Button>
        <MesaUI.Button buttonType="primary" disabled={!flagsStatusActionable} onClick={archiveFlags}>Archive</MesaUI.Button>
      </MesaUI.Container>
      <MesaUI.Container className="flags-card__header__status-views">
        <div>
          <input type="checkbox" id="view-archived-flags" checked={flagsStatus === FlagStatusEnum.ARCHIVED} onChange={() => selectFlagStatus(FlagStatusEnum.ARCHIVED)}/>
          <label htmlFor="view-archived-flags">Show Archived Flags</label>
        </div>
        <div>
          <input type="checkbox" id="view-resolved-flags" checked={flagsStatus === FlagStatusEnum.RESOLVED} onChange={() => selectFlagStatus(FlagStatusEnum.RESOLVED)}/>
          <label htmlFor="view-resolved-flags">Show Resolved Flags</label>
        </div>
      </MesaUI.Container>
      <div ref={flagTypeClickRef} className="flags-card__header__filter">
        <MesaUI.Button buttonType="primary" disabled={!flagTypes?.length} onClick={toggleShowFlagTypes}>Filter Flags</MesaUI.Button>
        {(!!flagTypes?.length && showFlagTypes) && (
          <ul className="flags-card__header__filter__dropdown">
            {flagTypes.map((flagType, i) => (
              <li key={`flags-card__header__filter__dropdown-${i}-${flagType.flagTypeId}`}>
                <input type="checkbox" id={`flag-filter-type-${flagType.flagTypeId}-${i}`} checked={flagType === selectedFlagType} onChange={() => selectFlagType(flagType)}/>
                <label htmlFor={`flag-filter-type-${flagType.flagTypeId}-${i}`}>{flagType.flagName}</label>
              </li>
            ))}
          </ul>
        )}
      </div>
      <MesaUI.Container className={{ "flags-card__header__export": true, 'loading-whirl': isExportingFlags }}>
        <MesaUI.Button
          buttonType="primary" 
          marginType={{ right: "md" }}
          onClick={() => dispatch(fileExportSagaActions.fetchFlagsExport({ flagStatus: flagsStatus.keyname, flagTypeId: selectedFlagType === null ? "0" : selectedFlagType?.flagTypeId }))}
        >
          Export
        </MesaUI.Button>
      </MesaUI.Container>
    </MesaUI.CardHeader>
  )
}

export default FlagsHeader