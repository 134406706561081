
import { put, takeLatest, all, delay } from 'redux-saga/effects'

import { errorSlice } from './errorSlice'

const ERROR_TIMEOUT = 5 * 1000

function* genericErrorDisplaySaga(){
  yield delay(ERROR_TIMEOUT)
  yield put(errorSlice.actions.hideError())
}

export function* rootErrorSaga(){
  yield all([
    takeLatest(errorSlice.actions.displayError.type, genericErrorDisplaySaga)
  ])
}