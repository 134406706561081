
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { 
  GradPlanDetails,
  StudentInfo,
  CourseGroupDetails,
  emptyStudentInfo,
  StudentProgressSnapshotItem,
  StudentCTE,
  StudentAssessmentStatus,
  StudentCriteriaAgg,
} from './studentDomain'

export interface StudentState {
  isLoadingAllGradPlans: boolean;
  isLoadingStudentInfo: boolean;
  isLoadingStudentAssessmentStatuses: boolean;
  isLoadingStudentEnrollment: boolean;
  isLoadingStudentProgressSnapshot: boolean;
  isLoadingStudentGradPlanStatus: boolean;
  isLoadingStudentCTEs: boolean;
  isLoadingStudentEndorsements: boolean;
  isLoadingStudentCriteriaAggregates: boolean;

  allGradPlans: Array<GradPlanDetails>;
  defaultGradPlanName: string;
  studentInfo: StudentInfo;
  studentEnrollment: Array<CourseGroupDetails>;
  studentProgressSnapshot: Array<StudentProgressSnapshotItem>;
  studentGradPlanStatus: Array<CourseGroupDetails>;
  studentCTEs: Array<StudentCTE>;
  studentAssessmentStatuses: Array<StudentAssessmentStatus>;
  studentEndorsements: Array<string>;
  studentCriteriaAggregates: Array<StudentCriteriaAgg>;
  studentShouldDisplayLetterGrades: boolean;
}

const initialState: StudentState = {
  isLoadingAllGradPlans: false,
  isLoadingStudentInfo: false,
  isLoadingStudentAssessmentStatuses: false,
  isLoadingStudentEnrollment: false,
  isLoadingStudentProgressSnapshot: false,
  isLoadingStudentGradPlanStatus: false,
  isLoadingStudentCTEs: false,
  isLoadingStudentEndorsements: false,
  isLoadingStudentCriteriaAggregates: false,

  allGradPlans: [],
  defaultGradPlanName: "",
  studentInfo: emptyStudentInfo,
  studentProgressSnapshot: [],
  studentEnrollment: [],
  studentGradPlanStatus: [],
  studentCTEs: [],
  studentAssessmentStatuses: [],
  studentEndorsements: [],
  studentCriteriaAggregates: [],
  studentShouldDisplayLetterGrades: false
}

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    allGradPlansRequested: (state) => {
      state.isLoadingAllGradPlans = true
    },
    getAllGradPlans: (state, { payload: { gradPlans }}: PayloadAction<{ gradPlans: Array<GradPlanDetails> }>) => {
      state.isLoadingAllGradPlans = false
      state.allGradPlans = gradPlans
    },
    studentInfoRequested: (state) => {
      state.isLoadingStudentInfo = true
    },
    getStudentInfo: (state, { payload: { studentInfo }}: PayloadAction<{ studentInfo: StudentInfo }>) => {
      state.isLoadingStudentInfo = false
      state.studentInfo = studentInfo
    },
    studentEnrollmentRequested: (state) => {
      state.isLoadingStudentEnrollment = true
    },
    getStudentEnrollment: (state, { payload: { studentEnrollment }}: PayloadAction<{ studentEnrollment: Array<CourseGroupDetails>}>) => {
      state.isLoadingStudentEnrollment = false
      state.studentEnrollment = studentEnrollment
    },
    studentAssessmentStatusesRequested: (state) => {
      state.isLoadingStudentAssessmentStatuses = true
    },
    getStudentAssessmentStatuses: (state, { payload: { assessmentStatuses }}: PayloadAction<{ assessmentStatuses: Array<StudentAssessmentStatus>}>) => {
      state.isLoadingStudentAssessmentStatuses = false
      state.studentAssessmentStatuses = assessmentStatuses
    },
    studentProgressSnapshotRequested: (state) => {
      state.isLoadingStudentProgressSnapshot = true
    },
    getStudentProgressSnapshot: (state, { payload: { studentProgressSnapshot }}: PayloadAction<{ studentProgressSnapshot: Array<StudentProgressSnapshotItem> }>) => {
      state.isLoadingStudentProgressSnapshot = false
      state.studentProgressSnapshot = studentProgressSnapshot
    },
    studentGradPlanStatusRequested: (state) => {
      state.isLoadingStudentGradPlanStatus = true
    },
    getStudentGradPlanStatus: (state, { payload: { studentGradPlanStatus, defaultGradPlanName, shouldDisplayLetterGrades }}: PayloadAction<{ studentGradPlanStatus: Array<CourseGroupDetails>; defaultGradPlanName?: string; shouldDisplayLetterGrades?: boolean }>) => {
      state.isLoadingStudentGradPlanStatus = false
      state.studentGradPlanStatus = studentGradPlanStatus
      state.studentShouldDisplayLetterGrades = !!shouldDisplayLetterGrades
      if(defaultGradPlanName && !state.defaultGradPlanName){
        state.defaultGradPlanName = defaultGradPlanName
      }
    },
    studentCTEsRequested: (state) => {
      state.isLoadingStudentCTEs = true
    },
    getStudentCTEs: (state, { payload: { studentCTEs }}: PayloadAction<{ studentCTEs: Array<StudentCTE>}>) => {
      state.isLoadingStudentCTEs = false
      state.studentCTEs = studentCTEs
    },
    studentEndorsementsRequested: (state) => {
      state.isLoadingStudentEndorsements = true
    },
    getStudentEndorsements: (state, { payload: { endorsements }}: PayloadAction<{ endorsements: Array<string> }>) => {
      state.isLoadingStudentEndorsements = false
      state.studentEndorsements = endorsements
    },
    studentCriteriaAggregatesRequested: (state) => {
      state.isLoadingStudentCriteriaAggregates = true
    },
    getStudentCriteriaAggregates: (state, { payload: { studentCriteriaAggregates } }: PayloadAction<{ studentCriteriaAggregates: Array<StudentCriteriaAgg> }>) => {
      state.studentCriteriaAggregates = studentCriteriaAggregates
    },
    reset: (state) => {
      state.studentGradPlanStatus = []
      state.studentCTEs = []
      state.studentProgressSnapshot = []
      state.studentInfo = emptyStudentInfo
      state.studentEnrollment = []
      state.studentEndorsements = []
      state.studentAssessmentStatuses = []
      state.studentCriteriaAggregates = []
    }
  }
})
