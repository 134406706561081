
import { createSlice } from '@reduxjs/toolkit'

import { UserRoleEnum, UserRole } from '../../lib/enums'

import { WhoAmI } from './userDomain'

import { PendoService } from '../../lib/services'
import { PendoUserDetails } from '../../lib/services/pendo/types'

import { ENV } from '../../lib/env'

export interface UserState {
  isLoadingWhoAmI: boolean;
  isLoggingOut: boolean;
  failedToAuthorize: boolean;
  failedToAuthorizeReason: string;
  authorized: boolean;
  whoami: WhoAmI;
  userRole: UserRole;
}

const initialState: UserState = {
  isLoadingWhoAmI: false,
  isLoggingOut: false,
  failedToAuthorize: false,
  failedToAuthorizeReason: "",
  authorized: false,
  userRole: UserRoleEnum.NA,
  whoami: {
    id: "N/A",
    uuid: "N/A",
    userName: "N/A",
    authRoleEnum: "N/A",
    roleName: "N/A",
    stateCode: "N/A",
    emailAddress: "N/A",
    firstName: "N/A",
    lastName: "N/A",
    districtId: "N/A",
    districtName: "N/A",
    campusName: "N/A"
  }
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getWhoAmIRequested: (state) => {
      state.isLoadingWhoAmI = true
    },
    getWhoAmI: (state, { payload }: { payload: WhoAmI }) => {
      PendoService.initialize(ENV.envName)
      state.userRole = UserRoleEnum.getByRoleName(payload.roleName)
      if(state.userRole === UserRoleEnum.NA){
        state.failedToAuthorize = true
        state.failedToAuthorizeReason = `Failed To Authorize: Reason Invalid USER ROLE (roleName: ${payload.roleName})`
      } else {
        state.authorized = true
        console.info(`Authorized Role: ${state.userRole.name}`)
        const pendoDetails: PendoUserDetails = {
          id: payload.id,
          firstName: payload.firstName,
          lastName: payload.lastName,
          userName: payload.userName,
          email: payload.emailAddress,
          role: payload.roleName,
          districtId: payload.districtId,
          districtName: payload.districtName,
          campusName: payload.campusName
        }
        PendoService.initUser(pendoDetails)
      }
      state.whoami = payload
      state.isLoadingWhoAmI = false
    },
    getWhoAmIFailed: (state, { payload: { errorMessage } }: { payload: { errorMessage: string } }) => {
      state.isLoadingWhoAmI = false
      state.failedToAuthorize = true
      state.failedToAuthorizeReason = errorMessage
    },
    logoutRequested: (state) => {
      state.isLoggingOut = true
    },
    logoutFailed: (state) => {
      state.isLoggingOut = false
    },
  },
})
