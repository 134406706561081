
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PanelView, PanelViewEnum } from '../../lib/enums'

export interface ViewState {
  panelView: PanelView;
  panelIsOpen: boolean;
}

const initialState: ViewState = {
  panelView: PanelViewEnum.NA,
  panelIsOpen: false,
}

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    setPanelView: (state, { payload: { view }}: PayloadAction<{ view: PanelViewEnum }>) => {
      if(view instanceof PanelViewEnum && view !== PanelViewEnum.NA){
        state.panelView = view
      }
    },
    toggleOpenPanel: (state) => {
      state.panelIsOpen = !state.panelIsOpen
    },
    openPanel: (state) => {
      state.panelIsOpen = true
    },
    closePanel: (state) => {
      state.panelIsOpen = false
    }
  }
})
