
import React from 'react'
import MesaUI from "@mesacloud/react-framework"

import { StudentCTE } from '../../../../store/student/studentDomain'

import ScoreTotal from '../../common/ScoreTotal'

interface StudentMainCTEProgressPropTypes {
  selectedCTE: StudentCTE | null;
  setSelectedCluster: (clusterName: string) => void;
}

const StudentMainCTEProgress = ({
  selectedCTE,
  setSelectedCluster
}: StudentMainCTEProgressPropTypes) => (
  <MesaUI.Container className="student__main__progress">
    <MesaUI.Container className="student__main__progress__header--cte">
      <h3>Progress Snapshot</h3>
    </MesaUI.Container>
    <MesaUI.Container className="student__main__progress__list">
      {Array.isArray(selectedCTE?.clusters) && selectedCTE?.clusters.map(({ 
        name, 
        totalPathwayCredits,
        clusterAssignedCredits 
      }, i) => (
        <MesaUI.Container key={`student__main__progress__item-${i}`} className="student__main__progress__item">
          <MesaUI.Container>
            <a href={`#student-cte-cluster-status-${name}`} onClick={() => setSelectedCluster(name)}>
              {name}
            </a>
          </MesaUI.Container>
          <MesaUI.Container>
            <ScoreTotal 
              amount={totalPathwayCredits}
              total={clusterAssignedCredits}
              displayIcon
              iconFaClass={totalPathwayCredits < clusterAssignedCredits ? "fal fa-circle" : "fas fa-check-circle"}
            />
          </MesaUI.Container>
        </MesaUI.Container>
      )
      )}
    </MesaUI.Container>
  </MesaUI.Container>
)

export default StudentMainCTEProgress
