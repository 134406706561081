
export type WhoAmI = {
  id: string;
  uuid: string;
  userName: string;
  authRoleEnum: string;
  roleName: string;
  stateCode: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  districtId: string;
  districtName: string;
  campusName: string;
}

export const mockWhoamiResult: WhoAmI = {
  id: "MOCK_ID",
  uuid: "MOCK_UUID",
  userName: "MOCK_USER",
  authRoleEnum: "DISTRICT",
  roleName: "district",
  stateCode: "TX",
  emailAddress: "mock@mockuser.com",
  firstName: "Mock",
  lastName: "User",
  districtId: "-1",
  districtName: "Mock ISD",
  campusName: "Mock Campus Name"
}
