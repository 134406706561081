
import React from 'react'

const StudentMainAck = () => {

    return (
      <div>StudentMainAck</div>
    )
}

export default StudentMainAck
