
import React, { Fragment } from 'react'
import MesaUI from "@mesacloud/react-framework"

import ScoreTotal from '../../common/ScoreTotal'

import { StudentGradPlanItem } from './adaptStudentGradPlanItems'
import { MesaColorEnum } from '@mesacloud/corejs'
import { CourseGroupStatusEnum } from '../../../../lib/enums'
import { AssessmentEvalTypeEnum } from '../../../../lib/enums/AssessmentEvalTypeEnum'

interface StudentMainGPSubjectsPropTypes {
  shouldDisplayLetterGrades: boolean;
  selectedSubject: string;
  gradPlanItems: Array<StudentGradPlanItem>;
}

const StudentMainGPSubjects = ({ 
  shouldDisplayLetterGrades,
  selectedSubject,
  gradPlanItems
}: StudentMainGPSubjectsPropTypes) => {

  //! PLEASE REFACTOR ME

  const courseGroupItems = gradPlanItems?.filter(({ hasCourseGroupDetails, entityType }) => entityType === "Course Group" && hasCourseGroupDetails) || []
  const assessmentItems = gradPlanItems?.filter(({ hasAssessmentItems, entityType }) => entityType === "Assessment" && hasAssessmentItems) || []

  return (
    <>
      {courseGroupItems.map(({
          htmlLinkId,
          subjectName,
          hasProgress,
          progress,
          iconClassName,
          courseGroupDetails
        }, i) => {

          const selected = selectedSubject === subjectName

          return (
            <MesaUI.Container key={`student-gp-subject-item-${i}`} paddingType="md">
              <MesaUI.CollapsibleListCard
                id={htmlLinkId}
                className="student__main__subject-card"
                header={(
                  <>
                    <MesaUI.CardHeaderLeft className="student__main__collapsible-card__title"> 
                      <MesaUI.Container style={{ fontSize: "1.05em" }} fontColor={selected ? MesaColorEnum.SEAFOAM : undefined}>
                        <h3>{subjectName}</h3>
                      </MesaUI.Container>
                    </MesaUI.CardHeaderLeft>
                    <MesaUI.CardHeaderRight>
                      {hasProgress && (
                        <ScoreTotal
                          amount={progress.earned}
                          total={progress.total}
                          displayIcon
                          iconFaClass={iconClassName}
                        />
                      )}
                    </MesaUI.CardHeaderRight>
                  </>
                )}
              >
                {Array.isArray(courseGroupDetails) && courseGroupDetails.map(({ courseName, details, status }, ii) => {

                  const courseGroupStatus = CourseGroupStatusEnum.getByKeyname(status)

                  const sortedDetails = [...details].sort((a,b) => {
                    if (parseInt(a.academicSession) < parseInt(b.academicSession)) { return -1; }
                    if (parseInt(a.academicSession) > parseInt(b.academicSession)) { return 1; }
                    return 0;
                  });

                  // parseFloat(creditEarned).toString() is used because we sometimes have credits come in as strings
                  // in scientific notation (ex: 1E+1). parseFloat() converts it to a number, and then we convert it
                  // back to a string - as we want to show "0" instead of "N/A". parseFloat() works for normal integers
                  // ex: parseFloat('1') -> 1, parseFloat('1E+1') -> 10, parseFloat('5') -> 5, parseFloat('10') -> 10
                  return (
                    <Fragment key={`student-grad-plan-status-subject-${i}-course-group-${ii}`}>
                      <MesaUI.CollapsibleListCardItem>
                        <MesaUI.FlexRow style={{ width: "100%" }}>
                          <MesaUI.FlexCol col={4}>
                            <MesaUI.Container paddingType={{ left: "xl" }} style={{ fontWeight: "bold" }} >{courseName}</MesaUI.Container>
                          </MesaUI.FlexCol>
                          <MesaUI.FlexCol style={{ whiteSpace: "nowrap" }}>
                            <MesaUI.Container fontColor={courseGroupStatus.color}>{courseGroupStatus.displayName}</MesaUI.Container>
                          </MesaUI.FlexCol>
                        </MesaUI.FlexRow>
                      </MesaUI.CollapsibleListCardItem>
                      {Array.isArray(sortedDetails) && sortedDetails.length ? (
                        sortedDetails.map(({ academicSession, courseGrade, courseName, creditEarned, letterGrade }, j) => (
                          <MesaUI.CollapsibleListCardHiddenItem 
                            key={`student-grad-plan-status-subject-${i}-course-group-${ii}-course-${j}`}
                          >
                            <MesaUI.FlexRow style={{ width: "100%" }}>
                              <MesaUI.FlexCol col={4}>
                                <MesaUI.Container paddingType={{ left: "4em" }}>
                                  {courseName || "N/A"}
                                </MesaUI.Container>
                              </MesaUI.FlexCol>
                              <MesaUI.FlexCol>
                                {academicSession || "N/A"}
                              </MesaUI.FlexCol>
                              {shouldDisplayLetterGrades && (
                                <MesaUI.FlexCol className="course-group-detail-letter-grade">
                                  {letterGrade|| "N/A"}
                                </MesaUI.FlexCol>
                              )}
                              <MesaUI.FlexCol>
                                {courseGrade || "N/A"}
                              </MesaUI.FlexCol>
                              <MesaUI.FlexCol>
                                {parseFloat(creditEarned).toString() || "N/A"}
                              </MesaUI.FlexCol>
                            </MesaUI.FlexRow>
                          </MesaUI.CollapsibleListCardHiddenItem>
                        ))
                      ) : (
                        <MesaUI.CollapsibleListCardHiddenItem/>
                      )}
                    </Fragment>
                  )
                })}
              </MesaUI.CollapsibleListCard>
            </MesaUI.Container>
          )
        })
      }
      {assessmentItems.map(({
          htmlLinkId,
          subjectName,
          iconClassName,
          assessmentItems
        }, i) => {

          const selected = selectedSubject === subjectName

          return (
            <MesaUI.Container key={`student-gp-subject-item-${i}`} paddingType="md">
              <MesaUI.CollapsibleListCard
                id={htmlLinkId}
                className="student__main__subject-card"
                header={(
                  <>
                    <MesaUI.CardHeaderLeft className="student__main__collapsible-card__title"> 
                      <MesaUI.Container style={{ fontSize: "1.05em" }} fontColor={selected ? MesaColorEnum.SEAFOAM : undefined}>
                        <h3>{subjectName}</h3>
                      </MesaUI.Container>
                    </MesaUI.CardHeaderLeft>
                    <MesaUI.CardHeaderRight>
                      <i className={iconClassName} style={{ fontSize: "1.15em", margin: "5px" }}/>
                    </MesaUI.CardHeaderRight>
                  </>
                )}
              >
                {Array.isArray(assessmentItems) && assessmentItems.map(({ 
                  name,
                  lineItemId,
                  criteriaRequirementsFulfilled,
                  acknowledgementAssessmentDetailBeans
                }, ii) => {

                  const status = criteriaRequirementsFulfilled ? CourseGroupStatusEnum.COMPLETED : CourseGroupStatusEnum.NOT_COMPLETED

                  return (
                    <Fragment key={`student-grad-plan-status-assessment-${lineItemId}-${ii}`}>
                      <MesaUI.CollapsibleListCardItem>
                        <MesaUI.FlexRow style={{ width: "100%" }}>
                          <MesaUI.FlexCol col={4}>
                            <MesaUI.Container paddingType={{ left: "xl" }} style={{ fontWeight: "bold" }} >{name}</MesaUI.Container>
                          </MesaUI.FlexCol>
                          <MesaUI.FlexCol style={{ whiteSpace: "nowrap" }}>
                            <MesaUI.Container fontColor={status.color}>{status.displayName}</MesaUI.Container>
                          </MesaUI.FlexCol>
                        </MesaUI.FlexRow>
                      </MesaUI.CollapsibleListCardItem>
                      {(Array.isArray(acknowledgementAssessmentDetailBeans) && !!acknowledgementAssessmentDetailBeans.length) ? (
                        acknowledgementAssessmentDetailBeans.map(({
                          name,
                          requiredScore,
                          score,
                          assessmentEvalType,
                          passed
                        }, j) => (
                            <MesaUI.CollapsibleListCardHiddenItem
                              key={`student-grad-plan-status-assessment-${lineItemId}-${ii}-score-${j}-${name}`}
                            >
                              <MesaUI.FlexRow style={{ width: "100%" }}>
                                <MesaUI.FlexCol col={4} style={{ display: "flex", alignItems: "center" }}>
                                  <MesaUI.Container paddingType={{ left: "4em" }} >
                                    {name}
                                  </MesaUI.Container>
                                </MesaUI.FlexCol>
                                <MesaUI.FlexCol>
                                  {AssessmentEvalTypeEnum.getByKeyname(assessmentEvalType) === AssessmentEvalTypeEnum.WITH_SCORE ? (
                                    <ScoreTotal
                                      amount={score}
                                      total={requiredScore}
                                      iconFaClass={passed ? "fas fa-check-circle" : "fal fa-times-circle"}
                                      displayIcon
                                    />
                                  ) : (
                                    <i className={passed ? "fas fa-check-circle" : "fas fa-minus-circle"} />
                                  )}
                                </MesaUI.FlexCol>
                              </MesaUI.FlexRow>
                            </MesaUI.CollapsibleListCardHiddenItem>
                          )
                        )
                      ) : (
                        <MesaUI.CollapsibleListCardHiddenItem/>
                      )}
                    </Fragment>
                  )
                })}
              </MesaUI.CollapsibleListCard>
            </MesaUI.Container>
          )
        })
      }
    </>
  )
}

export default StudentMainGPSubjects
