import { PayloadAction } from '@reduxjs/toolkit'
import { takeEvery, takeLatest, takeLeading, call, all, put } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import { 
  FilterFields,
  UserFilter,
  mockFilterFields,
  mockUserFilters,
  CreateFilterPayload,
  FilterIDPayload,
  mockFilterDetails,
  FilterDetails,
  ActiveFilterStatus
} from './filterDomain'
import * as filterSagaActions from './filterSagaActions'
import { errorSlice } from '../error/errorSlice'
import { filterSlice } from './filterSlice'

import { loadTriggersSagaActions } from '../loadTriggers'

let nActiveFilterRequests = 0

function* fetchFilterFieldsSaga(){
  try {
    yield put(filterSlice.actions.filterFieldsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<FilterFields>("/api/filter/fields", {}, mockFilterFields)
    })
    const fields: FilterFields = result?.data || { campuses: [], cohorts: [], demographics: [], filters: [], gender: [] }
    if(Array.isArray(fields.cohorts)){
      fields.cohorts.sort((a, b) => {
        if(a === "NA") return -1
        return a < b ? 1 : -1
      })
    }
    yield put(filterSlice.actions.getFilterFields({ fields }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error getting filter fields: ${e}`}))
    yield put(filterSlice.actions.getFilterFields({ fields: { campuses: [], cohorts: [], demographics: [], filters: [], gender: [] } }))
  }
}

function* fetchUserFilterSaga(){
  try {
    yield put(filterSlice.actions.userFiltersRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ filters: Array<UserFilter>}>("/api/filter/all", {}, { filters: mockUserFilters })
    })
    const filters: Array<UserFilter> = result?.data?.filters
    if(Array.isArray(filters)){
      yield put(filterSlice.actions.getUserFilters({ filters: filters.map((filter => {
        if(typeof filter?.filterParams === "string"){
          try {
            const filterParams = JSON.parse(filter.filterParams)
            return { ...filter, filterParams }
          } catch(e) { console.warn(`WARNING: Unable to parse filter params ${e}`) }
        }
        console.warn(`WARNING: Unexpected non-string filter params ${JSON.stringify(filter.filterParams)}`)
        return filter
      }))}))
    } else {
      yield put(filterSlice.actions.getUserFilters({ filters: [] }))
    }
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error getting user filters: ${e}`}))
    yield put(filterSlice.actions.getUserFilters({ filters: [] }))
  }
}

function* saveFilterSaga({ payload }: PayloadAction<CreateFilterPayload>){
  try {
    yield put(filterSlice.actions.saveFilterRequested())
    yield call(() => {
      return OnTimeAPI.post("/api/filter/save", payload, {}, {})
    })
    yield put(filterSagaActions.fetchActiveFilterStatus())
    yield put(filterSagaActions.fetchUserFilters())
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error saving filter: ${e}`}))
  } finally {
    yield put(filterSlice.actions.saveFilterComplete())
  }
}

function* deactivateFilterSaga(){
  try {
    yield put(filterSlice.actions.deactivateFilterRequested())
    yield call(() => {
      return OnTimeAPI.post("/api/filter/turnoff", {}, {}, {})
    })
    yield put(filterSagaActions.fetchActiveFilterStatus())
    yield put(filterSagaActions.fetchUserFilters())
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error saving filter: ${e}`}))
  } finally {
    yield put(filterSlice.actions.deactivateFilterComplete())
  }
}

function* activateFilterSaga({ payload: { filterId } }: PayloadAction<FilterIDPayload>){
  try {
    yield put(filterSlice.actions.activateFilterRequested())
    yield call(() => {
      return OnTimeAPI.post("/api/filter/set", {}, { params: { filterId } }, {})
    })
    yield put(filterSagaActions.fetchActiveFilterStatus())
    yield put(filterSagaActions.fetchUserFilters())
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error activating filter: ${e}`}))
  } finally {
    yield put(filterSlice.actions.activateFilterComplete())
  }
}

function* deleteFilterSaga({ payload: { filterId } }: PayloadAction<FilterIDPayload>){
  try {
    yield put(filterSlice.actions.deleteFilterRequested())
    yield call(() => {
      return OnTimeAPI.put("/api/filter/delete", {}, { params: { filterId } }, {})
    })
    yield put(filterSagaActions.fetchActiveFilterStatus())
    yield put(filterSagaActions.fetchUserFilters())
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error deleting filter: ${e}`}))
  } finally {
    yield put(filterSlice.actions.deleteFilterComplete())
  }
}

function* fetchFilterDetailsSaga({ payload: { filterId } }: PayloadAction<FilterIDPayload>){
  const emptyFilterDetails = {
    campusNames: [],
    cohortNames: [],
    genderName: "",
    groupNames: [],
    raceNames: []
  }
  try {
    yield put(filterSlice.actions.filterDetailsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<FilterDetails>('/api/filter/details', { params: { filterId }}, mockFilterDetails)
    })
    const details: FilterDetails = result?.data || emptyFilterDetails
    yield put(filterSlice.actions.getFilterDetails({ details }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching filter details: ${e}`}))
    yield put(filterSlice.actions.getFilterDetails({ details: emptyFilterDetails }))
  }
}

function* fetchActiveFilterStatusSaga(){
  nActiveFilterRequests++
  const emptyStatus = { active: false, filterName: "" }
  try {
    yield put(filterSlice.actions.activeFilterStatusRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<ActiveFilterStatus>(
        '/api/filter/isactive', 
        {}, 
        { active: true, filterName: "Mock Filter" } // emptyStatus 
      )
    })
    const activeFilterStatus = result?.data || emptyStatus
    yield put(filterSlice.actions.getActiveFilterStatus({ activeFilterStatus }))
    if(nActiveFilterRequests > 1){
      yield put(loadTriggersSagaActions.triggerImmediateFlagsLoad())
      yield put(loadTriggersSagaActions.triggerImmediateIndicatorsLoad())
    }
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching active filter status: ${e}`}))
    yield put(filterSlice.actions.getActiveFilterStatus({ activeFilterStatus: emptyStatus }))
  }
}

export function* rootFilterSaga(){
  yield all([
    takeLeading(filterSagaActions.fetchFilterFields.type, fetchFilterFieldsSaga),
    takeLatest(filterSagaActions.fetchUserFilters.type, fetchUserFilterSaga),
    takeEvery(filterSagaActions.saveFilter.type, saveFilterSaga),
    takeLeading(filterSagaActions.deactivateFilter.type, deactivateFilterSaga),
    takeLatest(filterSagaActions.activateFilter.type, activateFilterSaga),
    takeEvery(filterSagaActions.deleteFilter.type, deleteFilterSaga),
    takeLatest(filterSagaActions.fetchFilterDetails.type, fetchFilterDetailsSaga),
    takeLatest(filterSagaActions.fetchActiveFilterStatus.type, fetchActiveFilterStatusSaga)
  ])
}
