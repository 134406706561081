

import { Enum } from '@mesacloud/corejs'

export interface StudentCriteriaAggregateType {
  keyname: string;
  displayName: string;
  valid: boolean;
}

const normalizeKeyname = (s: string) => (s + "").trim().toLowerCase().replace(/s$/gi, '')

export class StudentCriteriaAggregateTypeEnum extends Enum implements StudentCriteriaAggregateType {

  public static ASSESSMENTS = new StudentCriteriaAggregateTypeEnum("Assessments", "Assessments", true)
  public static ACKNOWLEDGEMENTS = new StudentCriteriaAggregateTypeEnum("Acknowledgements", "Acknowledgements", true)
  public static UNKNOWN = new StudentCriteriaAggregateTypeEnum("unknown", "N/A", false)

  protected constructor(public keyname: string, public displayName: string, public valid: boolean){ super() }

  public static getByKeyname(keyname: string): StudentCriteriaAggregateTypeEnum {
    return this.asArray().find(aet => normalizeKeyname(aet.keyname) === normalizeKeyname(keyname)) || this.UNKNOWN
  }

  public static asArray(): Array<StudentCriteriaAggregateType> { return super.asArray() as Array<StudentCriteriaAggregateType> }
  public static asObject(): { [key: string]: StudentCriteriaAggregateType } { return super.asObject() as { [key: string]: StudentCriteriaAggregateType } }
}