
import { all, put, call, takeLatest } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import { ApiHealth, API_STATUS_OK } from './apiHealthDomain'

import * as apiHealthSagaActions from './apiHealthSagaActions'
import { apiHealthSlice } from './apiHealthSlice'

const mockApiStatus = {
  status: API_STATUS_OK
}

function* fetchApiHealthSaga(){
  try {
    yield put(apiHealthSlice.actions.getApiHealthRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<ApiHealth>("/actuator/health", {}, mockApiStatus)
    })
    yield put(apiHealthSlice.actions.getApiHealth({ statusText: result?.data?.status }))
  } catch(e) {
    yield put(apiHealthSlice.actions.getApiHealthFailure({ errorMessage: e }))
  }
}

export function* rootApiHealthSaga(){
  yield all([
    takeLatest(apiHealthSagaActions.fetchApiHealth.type, fetchApiHealthSaga)
  ])
}