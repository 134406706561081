
import React, { useState, useEffect, useRef } from 'react'
import MesaUI, { useUuid, useClickOutside } from '@mesacloud/react-framework'

import { CreateGroupSpecialSelector } from '../../../store/groups/groupsDomain';
import { useSelector } from '../../../store'

import RoleFilter from '../../../components/RoleFilter'

import { validateLastNameFilter } from './createGroupUtils'
import { UserRoleEnum } from '../../../lib/enums';
import isEqual from 'lodash.isequal';

const currentYear = new Date().getFullYear();
const ye9s = Array.from(new Array(10)).map((item, i) => currentYear - i);

interface CreateGroupSelectorPropTypes {
  selector: CreateGroupSpecialSelector;
  remove: () => void;
  updateSelector: (update: Partial<CreateGroupSpecialSelector>) => void;
}

const CreateGroupSelector = ({
  selector,
  remove,
  updateSelector,
}: CreateGroupSelectorPropTypes) => {

  const [lastNameValid, setLastNameValid] = useState(validateLastNameFilter(selector?.lastNameFilter))
  const [ye9Selected, setYe9Selected] = useState(selector?.ye9Filters?.length > 0)
  const [campusSelected, setCampusSelected] = useState(selector?.campusIds?.length > 0)

  const uuid = useUuid()

  useEffect(() => {
    setLastNameValid(validateLastNameFilter(selector?.lastNameFilter))
    setCampusSelected(selector?.campusIds?.length > 0)
    setYe9Selected(selector?.ye9Filters?.length > 0)
    // eslint-disable-next-line
  }, [selector])

  const lastNameRef = useRef(null)

  useClickOutside(lastNameRef, () => {
    const fixed = selector?.lastNameFilter?.map((val, i) => {
      if(i === 0 && !val){
        return 'A'
      } else if(i === 1 && !val){
        return 'Z'
      }
      return val
    }) as [string, string]
    
    if(!isEqual(fixed, selector?.lastNameFilter)){
      updateSelector({ lastNameFilter: fixed })
    }
  })

  const { campusOptions } = useSelector(({ groups }) => groups)

  return (
    <MesaUI.Container className="create-group__selector">
      <MesaUI.Container className="create-group__selector__selection">
        <span>Add students from this YE9: </span>
        <select 
          value={ye9Selected ? selector?.ye9Filters[0] || 'All' : 'All'} 
          onChange={({ target: { value }}) => {
            if(value === "All"){
              updateSelector({ ye9Filters: [] })
            } else {
              updateSelector({ ye9Filters: [value] })
            }
          }}
        >
          <option value="All">
            All
          </option>
          {ye9s.map((ye9,i) => (
            <option key={`create-group__selector-${uuid}-ye9-option-${ye9}-${i}`} value={ye9 + ""}>
              {ye9} - {ye9 + 1}
            </option>
          ))}
        </select>
      </MesaUI.Container>
      <RoleFilter roles={[UserRoleEnum.DISTRICT]}>
        <MesaUI.Container className="create-group__selector__selection">
          <span>Use students from campus: </span>
          <select 
            value={campusSelected ? selector?.campusIds[0] || 'All' : 'All'} 
            onChange={({ target: { value }}) => {
              if(value === "All"){
                updateSelector({ campusIds: [] })
              } else {
                updateSelector({ campusIds: [value] })
              }
            }}
          >
            <option value="All">
              All
            </option>
            {campusOptions.map(({ externalId, name },i) => (
              <option key={`create-group__selector-${uuid}-campus-option-${externalId}-${i}`} value={externalId + ""}>
                {name}
              </option>
            ))}
          </select>
        </MesaUI.Container>
      </RoleFilter>
      <MesaUI.Container className="create-group__selector__last-name">
        <div className="create-group__selector__last-name__wrap">
          <span>With last names from: </span>
          <span ref={lastNameRef}>
            <input 
              className="create-group__selector__last-name__one" 
              value={selector?.lastNameFilter[0]} 
              onChange={({ target: { value }}) => {
                const newVal = value.slice(0,3).toLocaleUpperCase().replace(/[^A-Z]/gi, '')
                updateSelector({ lastNameFilter: [ newVal, selector?.lastNameFilter[1] ]})
              }}
            /> 
            <span>to</span>
            <input 
              className="create-group__selector__last-name__two" 
              value={selector?.lastNameFilter[1]} 
              onChange={({ target: { value }}) => {
                const newVal = value.slice(0,3).toLocaleUpperCase().replace(/[^A-Z]/gi, '')
                updateSelector({ lastNameFilter: [ selector?.lastNameFilter[0], newVal ]})
              }}
            />
          </span>
          <MesaUI.Container className="create-group__selector__last-name__icon">
            {lastNameValid ? (
              <i className="fas fa-check-circle"/>
            ) : (
              <i className="fas fa-times-circle"/>
            )}
          </MesaUI.Container>
        </div>
        <MesaUI.Container className="create-group__selector__message">
          Last names up to three letters each.  Examples: A - C, CH - JA, TRE to Z, B to B (all B&apos;s)
        </MesaUI.Container>
        <MesaUI.Container className="create-group__selector__remove">
          <MesaUI.Button buttonType="primary" onClick={remove}>Remove</MesaUI.Button>
        </MesaUI.Container>
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default CreateGroupSelector
