
import React, { useState, useEffect } from 'react'
import joinUrls from 'url-join'
import { useDispatch } from 'react-redux'
import { parseBool } from '@mesacloud/corejs'
import MesaUI from '@mesacloud/react-framework'

import { useSelector, groupsSagaActions, searchSagaActions } from '../../../store'
import { GroupStudent } from '../../../store/search/searchDomain'
import { NavLink } from 'react-router-dom'

interface PanelGroupListSelectedStudentsPropTypes {
  viewSingleAddOnly: boolean | null | undefined;
  toggleViewSingleAddOnly: (value?: boolean) => void;
  groupPage: number;
  setGroupPage: (p: number) => void;
}

const PanelGroupListSelectedStudents = ({
  viewSingleAddOnly,
  toggleViewSingleAddOnly,
  groupPage,
  setGroupPage
}: PanelGroupListSelectedStudentsPropTypes) => {

  const [searchText, setSearchText] = useState("")
  const [listView, setListView] = useState<"group" | "search">("group") 

  const dispatch = useDispatch()

  const {
    isAddingStudentToGroup,
    isRemovingStudentFromGroup,
    selectedGroupList,
    selectedGroupName,
    selectedGroupId
  } = useSelector(({ groups }) => groups)

  const {
    isLoadingGroupStudentSearch,
    groupStudentSearchResults
  } = useSelector(({ search }) => search)

  const fetchGroup = (override: Partial<{ groupId: string; groupName: string; page: number; isSingleAddOnly: boolean }> = {}) => dispatch(groupsSagaActions.fetchGroupList({ 
    groupId: selectedGroupId + "", 
    page: groupPage,
    groupName: selectedGroupName,
    isSingleAddOnly: !!viewSingleAddOnly ,
    ...override
  }))

  useEffect(() => {
    dispatch(searchSagaActions.fetchGroupStudentSearch({ 
      groupId: selectedGroupId, 
      searchCriteria: searchText, 
      isSingleAddToggle: !!viewSingleAddOnly
    }))
    // eslint-disable-next-line
  }, [searchText])

  useEffect(() => {
    if(!!groupStudentSearchResults?.length){
      setListView("search")
    } else {
      setListView("group")
    }
  }, [groupStudentSearchResults, setListView])

  useEffect(() => {
    return () => { isAddingStudentToGroup && fetchGroup() }
    // eslint-disable-next-line
  }, [isAddingStudentToGroup])

  useEffect(() => {
    return () => { isRemovingStudentFromGroup && fetchGroup() }
    // eslint-disable-next-line
  }, [isRemovingStudentFromGroup])

  const handlePageChange = ({ selected }: { selected: number }) => {
    setGroupPage(selected)
    fetchGroup({ page: selected })
  }

  const viewingList: Array<GroupStudent> = (() => {
    switch(listView){
      case "group":
        return selectedGroupList?.students || []
      case "search":
        return groupStudentSearchResults || []
      default:
        return []
    }
  })()

  const pages = parseInt(selectedGroupList?.numberOfPages + "", 10) || 0

  return (
    <MesaUI.Container className="panel-groups__selected__student-view">
      <MesaUI.Container className="panel-groups__selected__student-view__search">
        <MesaUI.Search
          showResults={false}
          searchText={searchText}
          onTextChange={(text: string) => setSearchText(text)}
          isLoading={isLoadingGroupStudentSearch}
          placeholder="Search for students in this group"
        />
      </MesaUI.Container>
      <MesaUI.Container className={{ "panel-groups__selected__student-view__single-add-toggle": true, active: viewSingleAddOnly }}>
        <MesaUI.Container 
          className={{ "panel-groups__selected__student-view__single-add-toggle__icon": true, active: !!viewSingleAddOnly }}
          onClick={() => {
            toggleViewSingleAddOnly()
            fetchGroup({ isSingleAddOnly: !viewSingleAddOnly })
          }}
        >
          <i className="fas fa-file-plus"/> 
        </MesaUI.Container>
      </MesaUI.Container>
      <MesaUI.Container className="panel-groups__selected__student-view__group-list">
      <ul>
        {Array.isArray(viewingList) && viewingList.map(({ singularAdd, studentName, systemUserId }, i) => (
          <li key={`panel-groups__selected__student-view__group-list-student-${i}-${systemUserId}`}>
            <span>
              <NavLink to={joinUrls('/student', systemUserId)}>
                {studentName}
              </NavLink>
              {parseBool(singularAdd) && <i className="fa fa-file-plus"/>}
            </span>
            <span>
              <i className="fa fa-trash" onClick={() => dispatch(groupsSagaActions.removeStudentFromGroup({ groupId: selectedGroupId + "", studentId: systemUserId }))}/>
            </span>
          </li>
        ))}
      </ul>
      </MesaUI.Container>
      {listView === "group" && (
        <MesaUI.Container className="panel-groups__selected__student-view__pagination">
          <MesaUI.Pagination page={groupPage} handlePageChange={handlePageChange} withPageJump={pages > 1} totalPages={pages}/>
        </MesaUI.Container>
      )}
    </MesaUI.Container>
  )
}

export default PanelGroupListSelectedStudents