
import React from 'react'
import MesaUI from '@mesacloud/react-framework'
import { MesaColorEnum } from '@mesacloud/corejs'

import { FilterDetails } from '../../../store/filter/filterDomain'

interface PanelFilterDetailsModalPropTypes {
  filterName: string;
  closeDetails: (...args: any) => void;
  isLoading?: boolean | null;
  details: FilterDetails;
}

const PanelFilterDetailsModal = ({
  filterName,
  closeDetails,
  isLoading,
  details
}: PanelFilterDetailsModalPropTypes) => {

  return (
    <MesaUI.Modal closeFunc={closeDetails}>  
      <MesaUI.Card className="filter-details-card">
        <MesaUI.CardHeader backgroundColor={MesaColorEnum.NAVY} fontColor={MesaColorEnum.WHITE}>
          <MesaUI.CardHeaderLeft>
            <h2>Filter Details: {filterName}</h2>
          </MesaUI.CardHeaderLeft>
          <MesaUI.CardHeaderRight>
            <MesaUI.Button buttonType="close" onClick={closeDetails}/>
          </MesaUI.CardHeaderRight>
        </MesaUI.CardHeader>
        <MesaUI.CardContent className={{ 'loading-whirl': isLoading }}>
          {!!(details?.campusNames?.length) && (
            <>
              <MesaUI.Container className="filter-details-card__title" paddingType="md" style={{ textAlign: "center" }}>
                <h4>Campuses</h4>
              </MesaUI.Container>
              <ul>
                {details.campusNames.map((campusName, i) => <li key={`filter-details-campus-name-${i}`}>{campusName}</li>)}
              </ul>
            </>
          )}
          {!!(details?.raceNames.length) && (
            <>
              <MesaUI.Container className="filter-details-card__title" paddingType="md" style={{ textAlign: "center" }}>
                <h4>Races</h4>
              </MesaUI.Container>
              <ul>
                {details.raceNames.map((raceName, i) => <li key={`filter-details-race-name-${i}`}>{raceName}</li>)}
              </ul>
            </>
          )}
          {!!(details?.genderName) && (
            <>
              <MesaUI.Container className="filter-details-card__title" paddingType="md" style={{ textAlign: "center" }}>
                <h4>Gender</h4>
              </MesaUI.Container>
              <ul>
                <li>{details.genderName}</li>
              </ul>
            </>
          )}
          {!!(details?.cohortNames.length) && (
            <>
              <MesaUI.Container className="filter-details-card__title" paddingType="md" style={{ textAlign: "center" }}>
                <h4>YE9s</h4>
              </MesaUI.Container>
              <ul>
                {details.cohortNames.map((ye9, i) => <li key={`filter-details-race-name-${i}`}>{ye9}</li>)}
              </ul>
            </>
          )}
        </MesaUI.CardContent>
      </MesaUI.Card>
    </MesaUI.Modal>
  )
}

export default PanelFilterDetailsModal