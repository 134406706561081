import { v4 } from 'uuid'

import { capitalize } from "@mesacloud/corejs"

import { StudentProgressSnapshotItem, CourseGroupDetails, StudentCriteriaAgg, StudentCriteriaAggItem } from '../../../../store/student/studentDomain'

import { CourseGroupStatusEnum } from '../../../../lib/enums'
import { StudentCriteriaAggregateTypeEnum } from "../../../../lib/enums/StudentCriteriaAggregateTypeEnum"

// This was created in order to facilitate linking up legacy response data for new student page features
export interface StudentGradPlanItem {
  htmlLinkId: string;
  subjectName: string;
  entityType: "Course Group" | "Assessment" | "Acknowledgement" | "Acheivement";
  hasProgress: boolean;
  iconClassName: string;
  completed: boolean;
  progress: {
    earned: string;
    total: string;
  };
  hasCourseGroupDetails: boolean;
  courseGroupDetails: Array<CourseGroupDetails>;
  hasAssessmentItems: boolean;
  assessmentItems: Array<StudentCriteriaAggItem>;
}

const normalizeSubjectName = (s: string) => (s + "").trim().toLowerCase()

const formatSubjectNameForHTMLId = (s: string) => s?.replace(/\s+/g, '-').replace(/[^A-Z0-9-]/gi, '').toLowerCase() || v4()

const zipProgressSnapshotAndCourseGroupDetails = (
  progressSnapshot: Array<StudentProgressSnapshotItem>,
  courseGroupDetails: Array<CourseGroupDetails>
): Array<{ psItem: StudentProgressSnapshotItem | null; cgItems: Array<CourseGroupDetails> }> => {

  // const leftoverCourseGroups: Array<CourseGroupDetails> = [...courseGroupDetails]

  const indexesToRemove: Array<number> = []
  const zippedItems: Array<{ psItem: StudentProgressSnapshotItem | null; cgItems: Array<CourseGroupDetails> }> = progressSnapshot.map(psItem => ({
    psItem,
    cgItems: courseGroupDetails.slice().filter((cg, i) => {
      const keep = normalizeSubjectName(cg.subject) === normalizeSubjectName(psItem.subjectName)
      if(keep){
        indexesToRemove.push(i)
      }
      return keep
    })
  }))

  const leftoverCourseGroups = courseGroupDetails.filter((cg, i) => !indexesToRemove.includes(i))

  if(leftoverCourseGroups.length > 0){
    zippedItems.push({ psItem: null, cgItems: leftoverCourseGroups })
  }

  return zippedItems
}

const getIconClass = (type: "positive" | "warning" | "neutral"): string => {
  switch(type){
    case "positive":
      return "fas fa-check-circle"
    case "warning":
      return "fas fa-exclamation-circle"
    case "neutral":
      return "fal fa-circle"
    default:
      return "fal fa-circle"
  }
}

const warningStatuses = [
  CourseGroupStatusEnum.REQUIRED,
  CourseGroupStatusEnum.PARTIAL,
  CourseGroupStatusEnum.FAILED,
  CourseGroupStatusEnum.SCHEDULED_AT_RISK
]

// transform two arrays of data into a new interface to be consumed by the Progress Snapshot and main details cards
const adaptStudentGradPlanItemsFromProgressAndGradPlan = (
  progressSnapshot: Array<StudentProgressSnapshotItem>, 
  courseGroupDetails: Array<CourseGroupDetails>
): Array<StudentGradPlanItem> => (
  zipProgressSnapshotAndCourseGroupDetails(progressSnapshot, courseGroupDetails).map(({ psItem, cgItems }) => {
    const subjectName = capitalize(psItem?.subjectName || cgItems.find(({ subject }) => !!(subject) && typeof subject === "string")?.subject || "Other (Subject N/A)")
    const htmlLinkId = `gp-subject-${formatSubjectNameForHTMLId(subjectName)}`
    const entityType = "Course Group"
    const hasProgress = !!psItem
    let completed = false
    const progress = {
      earned: (psItem?.studentEarnedCredits || "0") + "",
      total: (psItem?.requiredSectionCredits || "0") + "",
    }
    if(hasProgress){
      const earnedNum = parseFloat(progress.earned)
      const totalNum = parseFloat(progress.total)
      completed = (!isNaN(earnedNum) && !isNaN(totalNum)) && (earnedNum >= totalNum)
    }
    const hasCourseGroupDetails = !!cgItems.length
    const courseGroupDetails = cgItems
    const iconType = completed ? (
      'positive'
    ) : (
      hasCourseGroupDetails ? (
        // I'm unsure of how exactly this works, since I don't know where the hasWarning
        // comes from, but, the code checks to see if the status of the cgItems has a
        // warning, and if so, it checks to see if it's in the list of warningStatuses.
        // If it is, it assigns a warning status of "warning".
        cgItems.reduce((hasWarning, cgItem) => {
          return ( // has a warning-level status
            hasWarning || warningStatuses.includes(CourseGroupStatusEnum.getByKeyname(cgItem?.status || ""))
          )
        }, false) ? (
          'warning'
        ) : (
          'neutral'
        )
      ) : (
        'neutral'
      )
    )

    const iconClassName = getIconClass(iconType)

    const hasAssessmentItems = false
    const assessmentItems: Array<StudentCriteriaAggItem> = []
    
    return { 
      htmlLinkId, subjectName, entityType, iconClassName, completed,
      hasProgress, progress, hasCourseGroupDetails, courseGroupDetails,
      hasAssessmentItems, assessmentItems
    }
  })
)

const adaptStudentGradPlanItemsFromCriteriaAgg = (studentCriteriaAggs: Array<StudentCriteriaAgg>): Array<StudentGradPlanItem> => (
  // Assessment items
  studentCriteriaAggs.filter(sa => StudentCriteriaAggregateTypeEnum.getByKeyname(sa?.type || "") === StudentCriteriaAggregateTypeEnum.ASSESSMENTS).map(({
    name,
    criteriaRequirementsFulfilled,
    lineItemBeanList
  }) => {
    const subjectName = name
    const htmlLinkId = `gp-assessment-${formatSubjectNameForHTMLId(name)}`
    const entityType = "Assessment"
    const completed = criteriaRequirementsFulfilled
    const iconClassName = getIconClass(completed ? 'positive' : 'neutral')
    const hasProgress = false
    const progress = { earned: "0", total: "0" }
    const hasCourseGroupDetails = false
    const courseGroupDetails: Array<CourseGroupDetails> = []
    const hasAssessmentItems = true
    const assessmentItems = lineItemBeanList

    return { 
      htmlLinkId, subjectName, entityType, iconClassName, completed,
      hasProgress, progress, hasCourseGroupDetails, courseGroupDetails,
      hasAssessmentItems, assessmentItems
    }
  })
)

export const adaptStudentGradPlanItems = (
  progressSnapshot: Array<StudentProgressSnapshotItem>, 
  courseGroupDetails: Array<CourseGroupDetails>,
  studentCriteriaAggs: Array<StudentCriteriaAgg>,
): Array<StudentGradPlanItem> => {
  return adaptStudentGradPlanItemsFromProgressAndGradPlan(progressSnapshot, courseGroupDetails)
    .concat(adaptStudentGradPlanItemsFromCriteriaAgg(studentCriteriaAggs))
}