import { createAction } from '@reduxjs/toolkit'

import {
  GeneralStudentSearchPayload,
  GroupStudentSearchPayload,
  AddGroupStudentSearchPayload,
} from './searchDomain'

export const fetchGeneralStudentSearch = createAction<GeneralStudentSearchPayload>("FETCH_GENERAL_STUDENT_SEARCH_SAGA")
export const fetchGroupStudentSearch = createAction<GroupStudentSearchPayload>("FETCH_GROUP_STUDENT_SEARCH_SAGA")
export const fetchAddGroupStudentSearch = createAction<AddGroupStudentSearchPayload>("FETCH_ADD_GROUP_STUDENT_SEARCH_SAGA")
export const fetchCreateGroupStudentSearch = createAction<GeneralStudentSearchPayload>("FETCH_CREATE_GROUP_STUDENT_SEARCH_SAGA")
