
import React, { useState, useEffect } from 'react'
import MesaUI from '@mesacloud/react-framework'

import { NOTE_INPUT_CHAR_MAX } from '../../../store/notes/notesDomain'
import { useSelector } from '../../../store'

interface PanelNotesInputPropTypes {
  submitNote: (text: string) => void;
}

const PanelNotesInput = ({
  submitNote
}: PanelNotesInputPropTypes) => {

  const [noteText, setNoteText] = useState('')
  const [noteTextValid, setNoteTextValid] = useState(false)

  const { noteSaveSucceeded } = useSelector(({ notes }) => notes)

  useEffect(() => {
    setNoteTextValid(noteText?.length > 1)
    if(noteText?.length > NOTE_INPUT_CHAR_MAX){
      setNoteText(noteText.slice(0, NOTE_INPUT_CHAR_MAX))
    }
  }, [noteText, setNoteText, setNoteTextValid])

  useEffect(() => {
    if(noteSaveSucceeded){
      setNoteText("")
    }
  }, [noteSaveSucceeded, setNoteText])

  return (
    <MesaUI.Container className="panel-notes__input">
      <textarea 
        maxLength={NOTE_INPUT_CHAR_MAX}
        value={noteText}
        onChange={({ target: { value }}) => setNoteText(value)}
      />
      <MesaUI.Button 
        buttonType="primary" 
        disabled={!noteTextValid} 
        onClick={() => submitNote(noteText)}
      >
        Save
      </MesaUI.Button>
    </MesaUI.Container>
  )
}

export default PanelNotesInput
