
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { 
  NoteDTO, 
  RequestFlagNotesPayload, 
  RequestUserNotesPayload
} from './notesDomain'

export interface NotesState {
  isLoadingUserNotes: boolean;
  isLoadingFlagNotes: boolean;
  isSavingUserNote: boolean;
  isSavingFlagNote: boolean;
  noteSaveSucceeded: boolean;
  currentNotesView: "student" | "flag";
  currentNotesStudentName: string;
  currentNotesStudentUUID: string;
  currentNotesFlagName: string;
  currentNotesFlagUUID: string;
  notes: Array<NoteDTO>;
}

const initialState: NotesState = {
  isLoadingUserNotes: false,
  isLoadingFlagNotes: false,
  isSavingFlagNote: false,
  isSavingUserNote: false,
  noteSaveSucceeded: false,
  currentNotesView: "student",
  currentNotesStudentUUID: "",
  currentNotesStudentName: "",
  currentNotesFlagName: "",
  currentNotesFlagUUID: "",
  notes: []
}

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    getUserNotesRequested: (state, { payload: { studentName, userUuid }}: PayloadAction<RequestUserNotesPayload>) => {
      state.isLoadingUserNotes = true
      state.currentNotesView = "student"
      state.currentNotesStudentName = studentName
      state.currentNotesStudentUUID = userUuid
    },
    getFlagNotesRequested: (state, { payload: { studentName, flagName, flagUuid, userUuid }}: PayloadAction<RequestFlagNotesPayload>) => {
      state.isLoadingFlagNotes = true
      state.currentNotesView = "flag"
      state.currentNotesStudentName = studentName
      state.currentNotesFlagName = flagName
      state.currentNotesFlagUUID = flagUuid
      state.currentNotesStudentUUID = userUuid
    },
    saveUserNoteRequested: (state) => {
      state.isSavingUserNote = true
      state.noteSaveSucceeded = false
    },
    saveUserNoteComplete: (state) => {
      state.isSavingUserNote = false
    },
    saveFlagNoteComplete: (state) => {
      state.isSavingFlagNote = false
    },
    saveNoteSuccess: (state) => {
      state.noteSaveSucceeded = true
    },
    saveFlagNoteRequested: (state) => {
      state.isSavingFlagNote = true
      state.noteSaveSucceeded = false
    },
    getUserNotes: (state, { payload: { notes } }: PayloadAction<{ notes: Array<NoteDTO> }>) => {
      state.isLoadingUserNotes = false
      state.notes = notes
    },
    getFlagNotes: (state, { payload: { notes } }: PayloadAction<{ notes: Array<NoteDTO> }>) => {
      state.isLoadingFlagNotes = false
      state.notes = notes
    },
    appendUserNotes: (state, { payload: { notes }}: PayloadAction<{ notes: Array<NoteDTO> }>) => {
      state.isSavingUserNote = false
      state.notes = [ ...state.notes, ...notes ]
    },
    appendFlagNotes: (state, { payload: { notes }}: PayloadAction<{ notes: Array<NoteDTO> }>) => {
      state.isSavingFlagNote = false
      state.notes = [ ...state.notes, ...notes ]
    },
  }
})