
import { Enum, MesaColor, MesaColorEnum } from '@mesacloud/corejs'

interface StudentGradPlanStatus {
  displayName: string;
  keyname: string;
  color: MesaColor;
}

const fmtKeyForCompare = (s: string) => (s + "").trim().toLowerCase().replace(/[^a-z0-9]/g, '')

export class StudentGradPlanStatusEnum extends Enum implements StudentGradPlanStatus {

  public static ON_TRACK = new StudentGradPlanStatusEnum("On Track", "On Track", MesaColorEnum.SEAFOAM)
  public static OFF_TRACK = new StudentGradPlanStatusEnum("Off Track", "Off Track", MesaColorEnum.RED)
  public static ALERT = new StudentGradPlanStatusEnum("Alert", "Alert", MesaColorEnum.GOLD)
  public static NO_YE9_TRANSCRIPT = new StudentGradPlanStatusEnum("Missing Transcript or Missing YE9", "No YE9 Transcript", MesaColorEnum.GRAY)
  public static UNDETERMINED = new StudentGradPlanStatusEnum("Undetermined", "Undetermined", MesaColorEnum.GRAY)

  protected constructor(public displayName: string, public keyname: string, public color: MesaColor) { super() }

  public static getByKeyname(keyname: string): StudentGradPlanStatusEnum {
    return this.asArray().find(({ keyname: _keyname }) => fmtKeyForCompare(_keyname) === fmtKeyForCompare(keyname)) || this.UNDETERMINED
  }

  public static asArray(){
    return super.asArray() as Array<StudentGradPlanStatus>
  }

  public static asObject(){
    return super.asObject() as { [key: string]: StudentGradPlanStatus | undefined }
  }
}