
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '@mesacloud/react-framework'

import { useSelector, searchSagaActions, groupsSagaActions } from '../../../store'

const PanelGroupListSelectedAdd = () => {

  const [searchText, setSearchText] = useState("")
  const [addedStudentIndexs, setAddedStudentIndexes] = useState<Array<number>>([])

  const dispatch = useDispatch()

  const {
    isLoadingAddGroupStudentSearch,
    addGroupStudentSearchResults
  } = useSelector(({ search }) => search)

  const {
    selectedGroupId
  } = useSelector(({ groups }) => groups)

  useEffect(() => {
    dispatch(searchSagaActions.fetchAddGroupStudentSearch({ groupId: selectedGroupId, searchCriteria: searchText }))
    // eslint-disable-next-line
  }, [searchText])

  useEffect(() => {
    setAddedStudentIndexes([])
    // eslint-disable-next-line
  }, [addGroupStudentSearchResults])

  return (
    <MesaUI.Container className="panel-groups__selected__search-view">
      <MesaUI.Container className="panel-groups__selected__search-view__search">
        <MesaUI.Search
          placeholder="Search for students to add to this group"
          searchText={searchText}
          onTextChange={(text: string) => setSearchText(text.slice(0,30))}
          showResults={false}
          isLoading={isLoadingAddGroupStudentSearch}
        />
      </MesaUI.Container>
      <MesaUI.Container className="panel-groups__selected__search-view__results">
        <ul>
          {Array.isArray(addGroupStudentSearchResults) && addGroupStudentSearchResults.map(({ studentName, systemUserId }, i) => (
            <li key={`panel-groups__selected__search-view__results-student-${i}-${systemUserId}`}>
              <span>{studentName}</span>
              <span 
                onClick={() => {
                  setAddedStudentIndexes([...addedStudentIndexs, i])
                  dispatch(groupsSagaActions.addStudentToGroup({ groupId: selectedGroupId + "", studentId: systemUserId }))
                }}
              >
                {!addedStudentIndexs.includes(i) && <i className="fas fa-plus"/>}
              </span>
            </li>
          ))}
        </ul>
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default PanelGroupListSelectedAdd