import { createAction } from '@reduxjs/toolkit'

import { StudentAndGradPlanPayload, StudentIDPayload } from './studentDomain'

export const fetchAllGradPlans = createAction("FETCH_ALL_GRAD_PLANS_SAGA")
export const fetchStudentInfo = createAction<StudentIDPayload>("FETCH_STUDENT_INFO_SAGA")
export const fetchStudentAssessmentsStatuses = createAction<StudentIDPayload>("FETCH_STUDENT_ASSESSMENTS_STATUSES_SAGA")
export const fetchStudentEnrollment = createAction<StudentIDPayload>("FETCH_STUDENT_ENROLLMENT_SAGA")
export const fetchStudentProgressSnapshot = createAction<StudentAndGradPlanPayload>("FETCH_STUDENT_PROGRESS_SNAPSHOT_SAGA")
export const fetchDefaultStudentProgressSnapshot = createAction<StudentIDPayload>("FETCH_DEFAULT_STUDENT_PROGRESS_SNAPSHOT_SAGA")
export const fetchStudentGradPlanStatus = createAction<StudentAndGradPlanPayload>("FETCH_STUDENT_GRAD_PLAN_STATUS_SAGA")
export const fetchDefaultStudentGradPlanStatus = createAction<StudentIDPayload>("FETCH_DEFAULT_STUDENT_GRAD_PLAN_STATUS_SAGA")
export const fetchStudentCTEs = createAction<StudentIDPayload>("FETCH_STUDENT_CTES_SAGA")
export const fetchStudentEndorsements = createAction<StudentIDPayload>("FETCH_STUDENT_ENDORSEMENTS_SAGA")
export const fetchStudentCriteriaAggregates = createAction<StudentAndGradPlanPayload>("FETCH_STUDENT_CRITERIA_AGGREGATES_SAGA")
export const fetchDefaultStudentCriteriaAggregates = createAction<StudentIDPayload>("FETCH_DEFAULT_STUDENT_CRITERIA_AGGREGATES_SAGA")