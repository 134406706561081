import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, takeLeading, call, all, put } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import { 
  GradPlanDetails,
  mockGradPlans,
  StudentInfo,
  emptyStudentInfo,
  CourseGroupDetails,
  StudentAndGradPlanPayload,
  StudentIDPayload,
  StudentCTE,
  StudentAssessmentStatus,
  StudentProgressSnapshotItem,
  mockStudentInfo,
  mockStudentAssessmentStatuses,
  mockStudentCtes,
  mockStudentProgressSnapshotItems,
  mockCourseGroups,
  StudentCriteriaAgg,
  mockStudentCriteriaAggWithAssessments
} from './studentDomain'
import * as studentSagaActions from './studentSagaActions'
import { errorSlice } from '../error'
import { studentSlice } from './studentSlice'
import { ENV } from "../../lib/env";

function* fetchAllGradPlansSaga(){
  try {
    yield put(studentSlice.actions.allGradPlansRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ gradPlanDetails: Array<GradPlanDetails>}>('/api/gradplan/details', {}, { gradPlanDetails: mockGradPlans })
    })
    const gradPlans = result?.data?.gradPlanDetails || []
    yield put(studentSlice.actions.getAllGradPlans({ gradPlans }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching district grad plans: ${e}` }))
    yield put(studentSlice.actions.getAllGradPlans({ gradPlans: [] }))
  }
}

function* fetchStudentInfoSaga({ payload }: PayloadAction<StudentIDPayload>){
  try {
    yield put(studentSlice.actions.studentInfoRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<StudentInfo>('/api/profile', { params: payload }, mockStudentInfo)
    })
    const studentInfo = result?.data || emptyStudentInfo

    // The Demo environment doesn't fetch the transcriptCreditSum due to
    // cohorts being old. Fetch the total sum from the progress snapshot and
    // use the total value.
    if (ENV.mesaDemo && result?.data) {
      const snapResult = yield call(() => {
        return OnTimeAPI.get<{ snapShotList: Array<StudentProgressSnapshotItem> }>('/api/gradplan/snapshot', { params: payload }, { snapShotList: mockStudentProgressSnapshotItems })
      })
      const studentProgressSnapshot = snapResult?.data?.snapShotList || []
      if (studentProgressSnapshot.length > 0) {
        // The "Total" snapshot is always the last SnapshotItem and contains the data we need for the sum
        studentInfo.transcriptCreditSum = parseFloat(studentProgressSnapshot[studentProgressSnapshot.length - 1]['studentEarnedCredits']);
      }
    }

    yield put(studentSlice.actions.getStudentInfo({ studentInfo }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student info: ${e}` }))
    yield put(studentSlice.actions.getStudentInfo({ studentInfo: emptyStudentInfo }))
  }
}

function* fetchStudentAssessmentStatusesSaga({ payload }: PayloadAction<StudentIDPayload>){
  try {
    yield put(studentSlice.actions.studentAssessmentStatusesRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ studentEocBean: Array<StudentAssessmentStatus> }>('/api/studenteoc', { params: payload }, { studentEocBean: mockStudentAssessmentStatuses })
    })
    const assessmentStatuses = result?.data?.studentEocBean || []
    yield put(studentSlice.actions.getStudentAssessmentStatuses({ assessmentStatuses }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student assessment statuses: ${e}` }))
    yield put(studentSlice.actions.getStudentAssessmentStatuses({ assessmentStatuses: [] }))
  }
}

function* fetchStudentEnrollmentSaga({ payload }: PayloadAction<StudentIDPayload>){
  try {
    yield put(studentSlice.actions.studentEnrollmentRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ genericCourses: Array<CourseGroupDetails> }>('/api/gradplan/enrollment', { params: payload }, { genericCourses: mockCourseGroups })
    })
    const studentEnrollment = result?.data?.genericCourses || []
    yield put(studentSlice.actions.getStudentEnrollment({ studentEnrollment }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student enrollment: ${e}` }))
    yield put(studentSlice.actions.getStudentEnrollment({ studentEnrollment: [] }))
  }
}

function* fetchStudentProgressSnapshotSaga({ payload }: PayloadAction<StudentAndGradPlanPayload>){
  try {
    yield put(studentSlice.actions.studentProgressSnapshotRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ snapShotList: Array<StudentProgressSnapshotItem> }>('/api/gradplan/snapshot', { params: payload }, { snapShotList: mockStudentProgressSnapshotItems })
    })
    const studentProgressSnapshot = result?.data?.snapShotList || []
    yield put(studentSlice.actions.getStudentProgressSnapshot({ studentProgressSnapshot }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student progress snapshot: ${e}` }))
    yield put(studentSlice.actions.getStudentProgressSnapshot({ studentProgressSnapshot: [] }))
  }
}

function* fetchDefaultStudentProgressSnapshotSaga({ payload }: PayloadAction<StudentIDPayload>){
  try {
    yield put(studentSlice.actions.studentProgressSnapshotRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ snapShotList: Array<StudentProgressSnapshotItem> }>('/api/gradplan/snapshot', { params: payload }, { snapShotList: mockStudentProgressSnapshotItems })
    })
    const studentProgressSnapshot = result?.data?.snapShotList || []
    yield put(studentSlice.actions.getStudentProgressSnapshot({ studentProgressSnapshot }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching default student progress snapshot: ${e}` }))
    yield put(studentSlice.actions.getStudentProgressSnapshot({ studentProgressSnapshot: [] }))
  }
}

function* fetchStudentGradPlanStatusSaga({ payload }: PayloadAction<StudentAndGradPlanPayload>){
  try {
    yield put(studentSlice.actions.studentGradPlanStatusRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ genericCourses: Array<CourseGroupDetails>; hasLetterGrades?: boolean }>('/api/gradplan/plan', { params: payload }, { genericCourses: mockCourseGroups })
    })
    const studentGradPlanStatus = result?.data?.genericCourses || []
    const shouldDisplayLetterGrades = !!result?.data?.hasLetterGrades
    yield put(studentSlice.actions.getStudentGradPlanStatus({ studentGradPlanStatus, shouldDisplayLetterGrades }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student grad plan: ${e}` }))
    yield put(studentSlice.actions.getStudentGradPlanStatus({ studentGradPlanStatus: [] }))
  }
}

function* fetchDefaultStudentGradPlanStatusSaga({ payload }: PayloadAction<StudentIDPayload>){
  try {
    yield put(studentSlice.actions.studentGradPlanStatusRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ genericCourses: Array<CourseGroupDetails>; gradPlanName: string; hasLetterGrades?: boolean }>('/api/gradplan/plan', { params: payload }, { genericCourses: mockCourseGroups, gradPlanName: "Mock Grad Plan 1" })
    })
    const studentGradPlanStatus = result?.data?.genericCourses || []
    const shouldDisplayLetterGrades = !!result?.data?.hasLetterGrades
    yield put(studentSlice.actions.getStudentGradPlanStatus({ studentGradPlanStatus, defaultGradPlanName: result?.data?.gradPlanName || "", shouldDisplayLetterGrades }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching default student grad plan: ${e}` }))
    yield put(studentSlice.actions.getStudentGradPlanStatus({ studentGradPlanStatus: [] }))
  }
}

function* fetchStudentCTEsSaga({ payload }: PayloadAction<StudentAndGradPlanPayload>){
  if (ENV.mesaDemo) {
    yield put(studentSlice.actions.getStudentCTEs({ studentCTEs: mockStudentCtes }));
    return;
  }

  try {
    const { studentId } = payload
    yield put(studentSlice.actions.studentCTEsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ ctes: Array<StudentCTE> }>('/api/student/cte/{{studentId}}', { pathParams: { studentId } }, { ctes: mockStudentCtes })
    })
    const studentCTEs = result?.data?.ctes || []
    yield put(studentSlice.actions.getStudentCTEs({ studentCTEs }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student CTE statuses: ${e}` }))
    yield put(studentSlice.actions.getStudentCTEs({ studentCTEs: [] }))
  }
}

function* fetchStudentEndorsementsSaga({ payload }: PayloadAction<StudentIDPayload>){
  try {
    yield put(studentSlice.actions.studentEndorsementsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ endorsementName: Array<string> }>('/api/student/cte/{{studentId}}', { params: payload }, { endorsementName: [""] })
    })
    const endorsements = result?.data?.endorsementName || []
    yield put(studentSlice.actions.getStudentEndorsements({ endorsements }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student endorsements: ${e}` }))
    yield put(studentSlice.actions.getStudentEndorsements({ endorsements: [] }))
  }
}

function* fetchStudentCriteriaAggregatesSaga({ payload: { studentId, gradPlanId } }: PayloadAction<StudentAndGradPlanPayload>) {
  if (ENV.mesaDemo) {
    studentSlice.actions.getStudentCriteriaAggregates({ studentCriteriaAggregates: mockStudentCriteriaAggWithAssessments });
    return;
  }

  try {
    yield put(studentSlice.actions.studentCriteriaAggregatesRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ acknowledgementLineItemBeans: Array<StudentCriteriaAgg> }>(
        '/api/student/additional/gradplan/req/{{studentId}}/{{gradPlanId}}', 
        { pathParams: { studentId, gradPlanId } }, 
        { acknowledgementLineItemBeans: [] }
      )
    })
    const studentCriteriaAggregates = result?.data?.acknowledgementLineItemBeans || []
    yield put(studentSlice.actions.getStudentCriteriaAggregates({ studentCriteriaAggregates }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student CriteriaAggregates: ${e}` }))
    yield put(studentSlice.actions.getStudentCriteriaAggregates({ studentCriteriaAggregates: [] }))
  }
}

function* fetchDefaultStudentCriteriaAggregatesSaga({ payload: { studentId } }: PayloadAction<StudentIDPayload>) {
  if (ENV.mesaDemo) {
    studentSlice.actions.getStudentCriteriaAggregates({ studentCriteriaAggregates: mockStudentCriteriaAggWithAssessments });
    return;
  }

  try {
    yield put(studentSlice.actions.studentCriteriaAggregatesRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ acknowledgementLineItemBeans: Array<StudentCriteriaAgg> }>(
        '/api/student/additional/gradplan/req/{{studentId}}/{{gradPlanId}}', 
        { pathParams: { studentId, gradPlanId: "-1" } }, 
        { acknowledgementLineItemBeans: [] }
      )
    })
    const studentCriteriaAggregates = result?.data?.acknowledgementLineItemBeans || []
    yield put(studentSlice.actions.getStudentCriteriaAggregates({ studentCriteriaAggregates }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching student CriteriaAggregates: ${e}` }))
    yield put(studentSlice.actions.getStudentCriteriaAggregates({ studentCriteriaAggregates: [] }))
  }
}

export function* rootStudentSaga(){
  yield all([
    takeLeading(studentSagaActions.fetchAllGradPlans.type, fetchAllGradPlansSaga),
    takeLatest(studentSagaActions.fetchStudentInfo.type, fetchStudentInfoSaga),
    takeLatest(studentSagaActions.fetchStudentAssessmentsStatuses.type, fetchStudentAssessmentStatusesSaga),
    takeLatest(studentSagaActions.fetchStudentEnrollment.type, fetchStudentEnrollmentSaga),
    takeLatest(studentSagaActions.fetchStudentProgressSnapshot.type, fetchStudentProgressSnapshotSaga),
    takeLatest(studentSagaActions.fetchDefaultStudentProgressSnapshot.type, fetchDefaultStudentProgressSnapshotSaga),
    takeLatest(studentSagaActions.fetchStudentGradPlanStatus.type, fetchStudentGradPlanStatusSaga),
    takeLatest(studentSagaActions.fetchDefaultStudentGradPlanStatus.type, fetchDefaultStudentGradPlanStatusSaga),
    takeLatest(studentSagaActions.fetchStudentCTEs.type, fetchStudentCTEsSaga),
    takeLatest(studentSagaActions.fetchStudentEndorsements.type, fetchStudentEndorsementsSaga),
    takeLatest(studentSagaActions.fetchStudentCriteriaAggregates.type, fetchStudentCriteriaAggregatesSaga),
    takeLatest(studentSagaActions.fetchDefaultStudentCriteriaAggregates.type, fetchDefaultStudentCriteriaAggregatesSaga)
  ])
}
