import { configureStore, Middleware } from "@reduxjs/toolkit"
import { createLogger } from 'redux-logger'
import createSagaMiddleware from "redux-saga"
import { MesaColorEnum } from '@mesacloud/corejs'

import { rootReducer } from './rootReducer'
import { rootSaga } from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

const middleware: Array<Middleware> = [sagaMiddleware]

let devTools = false
if(process.env.NODE_ENV !== "production"){
  devTools = true
  const logger = createLogger({
    collapsed: true,
    level: "info",
    colors: {
      title: () => MesaColorEnum.SEAFOAM.hex,
      prevState: () => MesaColorEnum.GOLD.hex,
      action: () => MesaColorEnum.BRICK.hex,
      nextState: () => MesaColorEnum.ORANGE.hex,
      error: () => MesaColorEnum.RED.hex,
    },
  }) as Middleware
  middleware.push(logger)
}

export const store = configureStore({
  reducer: rootReducer,
  devTools,
  middleware
})

sagaMiddleware.run(rootSaga)