import { PayloadAction } from '@reduxjs/toolkit'
import { takeEvery, put, call, all } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import { errorSlice } from '../error/errorSlice'
import { supportSlice } from './supportSlice'
import { SupportMessagePayload } from './supportDomain'
import * as supportSagaActions from './supportSagaActions'

function* sendSupportMessageSaga({ payload }: PayloadAction<SupportMessagePayload>){
  try { 
    yield put(supportSlice.actions.supportMessageRequested())
    yield call(() => {
      return OnTimeAPI.post('/api/mail/send', payload, {}, {})
    })
    yield put(supportSlice.actions.supportMessageSuccess())
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error sending support request: ${e}`}))
    yield put(supportSlice.actions.supportMessageFailure({ failureReason: e + "" }))
  }
}

export function* rootSupportSaga(){
  yield all([
    takeEvery(supportSagaActions.sendSupportMessage.type, sendSupportMessageSaga)
  ])
}
