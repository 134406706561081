
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

import { NoteDTO } from '../../../store/notes/notesDomain'

// utilities that can be moved to a better place later
const WEEKDAY_ABBVS = Object.freeze(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"])

const isDateValid = (date: Date) => !isNaN(date.getTime())

export const formatDateTimeString = (dateString: string): string => {
    const date = new Date(dateString)

    if(isDateValid(date)){
        let localString = date.toLocaleString()
        if(localString.match(/(\d|\d{2}):\d{2}:\d{2}/g,)){ // remove ms
          localString = localString.replace(/:\d{2} /g,' ')
        }
        return WEEKDAY_ABBVS[date.getDay()] + " " + localString
    }

    console.warn(`Invalid date string received: ${dateString}`)

    return dateString
}

const tzlessRegex = /Z$|(-|\+)\d\d:\d\d$/g
const fixNoteTimestamp = (ts: string): string => {
  if(typeof ts !== "string") return ts

  return ts.match(tzlessRegex) ? ts : ts + "Z"
}

const PanelNotesNote = ({ note }: { note: NoteDTO }) => (
  <MesaUI.Container className="panel-notes__note">
    <div className="panel-notes__note__date">
      {formatDateTimeString(fixNoteTimestamp(note?.created_date))}
    </div>
    <div className="panel-notes__note__author">
      By: {note?.author_name || "N/A"}
    </div>
    <div className="panel-notes__note__content">
      {note?.note_content || "N/A"}
    </div>
  </MesaUI.Container>
)

export default PanelNotesNote