
import { Enum } from '@mesacloud/corejs'

export interface AssessmentEvalType {
  keyname: string;
  displayName: string;
  valid: boolean;
}

export class AssessmentEvalTypeEnum extends Enum implements AssessmentEvalType {

  public static WITH_SCORE = new AssessmentEvalTypeEnum("score", "Score", true)
  public static WITHOUT_SCORE = new AssessmentEvalTypeEnum("noscore", "No Score", true)
  public static UNKNOWN = new AssessmentEvalTypeEnum("unknown", "N/A", false)

  protected constructor(public keyname: string, public displayName: string, public valid: boolean){ super() }

  public static getByKeyname(keyname: string): AssessmentEvalTypeEnum {
    return this.asArray().find(aet => aet.keyname.trim().toLowerCase() === (keyname + "").trim().toLowerCase()) || this.UNKNOWN
  }

  public static asArray(): Array<AssessmentEvalType> { return super.asArray() as Array<AssessmentEvalType> }
  public static asObject(): { [key: string]: AssessmentEvalType } { return super.asObject() as { [key: string]: AssessmentEvalType } }
}