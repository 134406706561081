
export interface SearchStudent {
  assignedExternalId: string;
  studentName: string;
  systemUserId: string;
  studentId: string;
}

export interface GroupStudent {
  assignedExternalId: string;
  studentName: string;
  systemUserId: string;
  singularAdd: boolean;
}

export interface GeneralStudentSearchPayload {
  searchCriteria: string;
}

export interface GroupStudentSearchPayload {
  searchCriteria: string;
  groupId: string | number;
  isSingleAddToggle: boolean;
}

export interface AddGroupStudentSearchPayload {
  searchCriteria: string;
  groupId: string | number;
}

export const mockSearchStudents: Array<SearchStudent> = [
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
  { assignedExternalId: "12345", studentName: "Mock Student Name", systemUserId: "-1", studentId: "-1" },
]
