import { PayloadAction } from '@reduxjs/toolkit'
import { call, all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import { errorSlice } from '../error/errorSlice'

import { 
  NoteDTO, 
  SaveFlagNotePostData, 
  SaveUserNotePostData,
  RequestUserNotesPayload,
  RequestFlagNotesPayload,
  createMockNotes
} from './notesDomain'
import { notesSlice } from './notesSlice'
import * as notesSagaActions from './notesSagaActions'
import { loadTriggersSagaActions } from '../loadTriggers'
import { ENV } from "../../lib/env";

interface NoteResponse {
  note: Array<NoteDTO>;
}

function* fetchUserNotesSaga({ payload: { userUuid, studentName } }: PayloadAction<RequestUserNotesPayload>){
  if (ENV.mesaDemo) {
    const notes = createMockNotes()
    yield put(notesSlice.actions.getUserNotes({ notes }))
    return
  }

  try {
    yield put(notesSlice.actions.getUserNotesRequested({ studentName, userUuid }))
    const result = yield call(() => {
      return OnTimeAPI.get<NoteResponse>(
        "/api/note/recipientuuid/{{userUuid}}", 
        { pathParams: { userUuid }},
        { note: createMockNotes() }
      )
    })
    const notes = result?.data?.note || []
    yield put(notesSlice.actions.getUserNotes({ notes }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error getting notes for user ${userUuid}: ${e}` }))
    yield put(notesSlice.actions.getUserNotes({ notes: [] }))
  }
}

function* fetchFlagNotesSaga({ payload: { userUuid, flagUuid, studentName, flagName } }: PayloadAction<RequestFlagNotesPayload>){
  if (ENV.mesaDemo) {
    const notes = createMockNotes()
    yield put(notesSlice.actions.getFlagNotes({ notes }))
    return
  }

  try {
    yield put(notesSlice.actions.getFlagNotesRequested({ studentName, flagName, userUuid, flagUuid }))
    const result = yield call(() => {
      return OnTimeAPI.get<NoteResponse>(
        "/api/note/recipientuuid/{{userUuid}}/flaguuid/{{flagUuid}}", 
        { pathParams: { userUuid, flagUuid }},
        { note: createMockNotes() }
      )
    })
    const notes = result?.data?.note || []
    yield put(notesSlice.actions.getFlagNotes({ notes }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error getting notes for user ${userUuid} flag ${flagUuid}: ${e}` }))
    yield put(notesSlice.actions.getFlagNotes({ notes: [] }))
  }
}

function* saveUserNoteSaga({ payload }: PayloadAction<SaveUserNotePostData>){
  try {
    yield put(notesSlice.actions.saveUserNoteRequested())
    const result = yield call(() => {
      return OnTimeAPI.post<NoteResponse>("/api/note/savesimplenote", payload, {}, { note: createMockNotes() })
    })
    const notes = result?.data?.note || []
    yield put(notesSlice.actions.appendUserNotes({ notes }))
    yield put(notesSlice.actions.saveNoteSuccess())
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error saving note for user ${payload.recipientUuid}: ${e}`}))
    yield put(notesSlice.actions.appendUserNotes({ notes: [] }))
  } finally {
    yield put(notesSlice.actions.saveUserNoteComplete())
  }
}

function* saveFlagNoteSaga({ payload }: PayloadAction<SaveFlagNotePostData>){
  try {
    yield put(notesSlice.actions.saveFlagNoteRequested())
    const result = yield call(() => {
      return OnTimeAPI.post<NoteResponse>("/api/note/saveflagnote", payload, {}, { note: createMockNotes() })
    })
    const notes = result?.data?.note || []
    yield put(notesSlice.actions.appendFlagNotes({ notes }))
    yield put(loadTriggersSagaActions.triggerImmediateFlagsLoad())
    yield put(notesSlice.actions.saveNoteSuccess())
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error saving flag for user ${payload.recipientUuid} flag ${payload.flagUuid}: ${e}`}))
    yield put(notesSlice.actions.appendFlagNotes({ notes: [] }))
  } finally {
    yield put(notesSlice.actions.saveFlagNoteComplete())
  }
}

export function* rootNotesSaga(){
  yield all([
    takeLatest(notesSagaActions.fetchUserNotes.type, fetchUserNotesSaga),
    takeLatest(notesSagaActions.fetchFlagNotes.type, fetchFlagNotesSaga),
    takeEvery(notesSagaActions.saveUserNote.type, saveUserNoteSaga),
    takeEvery(notesSagaActions.saveFlagNote.type, saveFlagNoteSaga)
  ])
}
