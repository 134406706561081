import joinUrls from 'url-join'
import { MesaAPI } from '@mesacloud/corejs'

import { ENV } from './env'

export const IS_REDIRECTING_TO_LOGIN_PLACEHOLDER = "IS_REDIRECTING_TO_LOGIN_PLACEHOLDER"

const shouldUseJWTState = {
  shouldSetJWT: true
}

export const setShouldUseJWTState = (val: boolean) => {
  shouldUseJWTState.shouldSetJWT = val
}

export const OnTimeAPI = new MesaAPI(
  { 
    baseURL: joinUrls(ENV.apiUrl, '/ontime'),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    }
  }, 
  {
    requestMiddleware: [
      config => {
        const jwt = localStorage.getItem("x-mesaiam-jwt")
        if(jwt && shouldUseJWTState.shouldSetJWT){
          config.headers['Authorization'] = `Bearer ${jwt}`
        }
        return config
      },
      error => error
    ],
    responseMiddleware: [
      response => {
        if(shouldUseJWTState.shouldSetJWT){
          const jwt = response.headers["x-mesaiam-jwt"]
          if(jwt){
            localStorage.setItem("x-mesaiam-jwt", jwt)
          }
        }
        return response
      },
      error => {
        const loginUrl = error?.response?.headers["x-mesaiam-sso-login-url"]
        if(loginUrl){
          setShouldUseJWTState(false)
          localStorage.clear()
          window.location.href = loginUrl
          return Promise.reject(IS_REDIRECTING_TO_LOGIN_PLACEHOLDER)
        }

        // A malformed JWT can result in an unauthorized response where the server won't redirect us back to loging.
        // This clears it out and reloads the page so that we get redirected appropriately.
        if (error?.response?.status === 401) {
            setShouldUseJWTState(false)
            localStorage.clear()
            window.location.reload()
        }
        return Promise.reject(error)
      }
    ]
  }
)

if(ENV.useMockApi){
  OnTimeAPI.setAsMock()
}
