import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useSelector, viewActions } from '../store'
import { PanelViewEnum } from '../lib/enums'

export const useCloseNotesOnFirstLoad = () => {

  const dispatch = useDispatch()

  const { panelIsOpen, panelView } = useSelector(({ view }) => view)

  useEffect(() => {
    if(panelIsOpen && panelView === PanelViewEnum.NOTES){
      dispatch(viewActions.closePanel())
    }
  // eslint-disable-next-line
  }, [])
}