// Shared logic to only be used within the demo environment

export function demoCohort(cohort: string): string {

  // Cohorts occasionally come in as "NA"
  const oldCohort = parseInt(cohort);
  if (isNaN(oldCohort)) {
    return cohort;
  }

  // Most demo student cohorts are off by a 3 year time span. If bumping
  // the year by 3 goes over the current year, we leave it untouched.
  const currentYear = new Date().getFullYear();
  if (oldCohort < currentYear && (oldCohort + 3) < currentYear) {
    return (oldCohort + 3).toString();
  }

  return cohort;
}
