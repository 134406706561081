
export interface UserFilter {
  activeStatus: boolean;
  filterId: string;
  filterParams: {
    name: string;
    campusIds: Array<string>;
    raceIds: Array<string>;
    groupIds: Array<string>;
    cohorts: Array<string>;
    gender: string;
    systemUserFilterId: string;
  };
  name: string;
}

export const mockUserFilters: Array<UserFilter> = [
  { 
    activeStatus: true, 
    filterId: "-1", 
    filterParams: {
      name: "Mock Filter 1",
      campusIds: ["-1", "-2"],
      raceIds: ["-1", "-2"],
      groupIds: ["-1", "-2"],
      cohorts: ["2019", "2020"],
      gender: "",
      systemUserFilterId: "1",
    }, 
    name: "Mock Filter 1" 
  },
  { 
    activeStatus: false, 
    filterId: "-2", 
    filterParams: {
      name: "Mock Filter 2",
      campusIds: ["-1", "-2"],
      raceIds: ["-2"],
      groupIds: ["-1", "-2"],
      cohorts: [],
      gender: "",
      systemUserFilterId: "-2",
    }, 
    name: "Mock Filter 2" 
  },
]

export interface ActiveFilterStatus {
  active: string | boolean;
  filterName: string;
}

export interface FilterDetails {
  campusNames: Array<string>;
  cohortNames: Array<string>;
  genderName: string;
  groupNames: Array<string>;
  raceNames: Array<string>;
}

export const mockFilterDetails: FilterDetails = {
  campusNames: ["Mock Campus 1", "Mock Campus 2", "Mock Campus 3"],
  cohortNames: ["2016", "2017", "2018", "2019"],
  genderName: "Male",
  groupNames: ["Mock Group 1", "Mock Group 2"],
  raceNames: ["Mock Race"]
}

export interface FilterFields {
  campuses: Array<{
    campusId: string;
    campusName: string;
    externalCampusId: string;
  }>;
  cohorts: Array<string>;
  demographics: Array<{ id: string; name: string }>;
  filters: Array<{ groupId: string; name: string }>;
  gender: Array<{ name: string; id: string }>;
}

export const mockFilterFields: FilterFields = {
  campuses: [{ campusId: "-1", campusName: "Mock Campus 1", externalCampusId: "123456" }],
  cohorts: ["2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021"],
  demographics: [{ id: "-1", name: "Mock Race" }],
  filters: [{ groupId: "-1", name: "Mock Group 1" }, { groupId: "-2", name: "Mock Group 2"}],
  gender: [{ id: "-1", name: "Male" }, { id: "-2", name: "Female" }]
}

export interface CreateFilterPayload {
  name: string;
  groupIds: Array<number | string>;
  raceIds: Array<number | string>;
  campusIds: Array<number | string>;
  cohorts: Array<number | string>;
  gender: string | number;
  systemUserFilterId: string | number;
}

export interface FilterIDPayload {
  filterId: number | string;
}