
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '@mesacloud/react-framework'

import { useSelector, filterSagaActions, viewActions } from '../../../store'

import { UserFilter } from '../../../store/filter/filterDomain'

import PanelFilterInput from './PanelFilterInput'
import PanelFilterSaved from './PanelFilterSaved'
import PanelFilterDetailsModal from './PanelFilterDetailsModal'

const PanelFilter = () => {

  const [filterForEdit, setFilterForEdit] = useState<null | UserFilter>(null)
  const [showDetails, setShowDetails] = useState(false)
  const [filterDetailsName, setFilterDetailsName] = useState("")

  const { 
    isActivatingFilter,
    isDeactivatingFilter,
    isDeletingFilter,
    isLoadingFilterFields,
    isLoadingUserFilters,
    isSavingFilter,
    isLoadingActiveFilterStatus,
    isLoadingFilterDetails,
    filterFields,
    filterFieldsLoaded,
    filterDetails,
    userFilters
  } = useSelector(({ filter }) => filter)

  const isLoading = isActivatingFilter || isDeactivatingFilter || 
  isDeletingFilter || isLoadingFilterFields || isLoadingUserFilters || 
  isSavingFilter || isLoadingActiveFilterStatus

  const dispatch = useDispatch()

  useEffect(() => {
    if(!filterFieldsLoaded && !isLoadingFilterFields) dispatch(filterSagaActions.fetchFilterFields())
  }, [filterFieldsLoaded, isLoadingFilterFields, dispatch])

  useEffect(() => {
    if(!userFilters?.length) dispatch(filterSagaActions.fetchUserFilters())
  // eslint-disable-next-line
  }, [])

  const selectFilterForEdit = (filter: UserFilter) => filter && setFilterForEdit(filter)
  const selectFilterForDetails = (filter: UserFilter) => {
    if(filter){
      setFilterDetailsName(filter?.name || "N/A")
      setShowDetails(true)
      dispatch(filterSagaActions.fetchFilterDetails({ filterId: filter.filterId }))
    }
  }

  const activateFilter = (filter: UserFilter) => filter?.filterId && dispatch(filterSagaActions.activateFilter({ filterId: filter.filterId }))
  const deactivateFilter = () => dispatch(filterSagaActions.deactivateFilter())
  const deleteFilter = (filter: UserFilter) => filter?.filterId && dispatch(filterSagaActions.deleteFilter({ filterId: filter.filterId }))

  return (
    <>
      <MesaUI.PanelHeader>
        <h2>Manage Filters</h2>
        <MesaUI.Button buttonType="close" onClick={() => dispatch(viewActions.closePanel())}/>
      </MesaUI.PanelHeader>
      <MesaUI.PanelContent className={{ "loading-whirl": isLoading, "panel__filter": true }} style={{ height: "100%" }}>
        <MesaUI.FlexRow style={{ height: "100%" }}>
          <MesaUI.FlexCol style={{ height: "100%" }} className="panel__filter__saved-view">
            <PanelFilterSaved 
              filters={userFilters} 
              selectFilterForEdit={selectFilterForEdit}
              activateFilter={activateFilter}
              deactivateFilter={deactivateFilter}
              deleteFilter={deleteFilter}
              selectFilterForDetails={selectFilterForDetails}
            />
          </MesaUI.FlexCol>
          <MesaUI.FlexCol style={{ height: "100%" }} className="panel__filter__input-view">
            <PanelFilterInput 
              fields={filterFields} 
              filterForEdit={filterForEdit}
              cancelEdit={() => setFilterForEdit(null)}
            />
          </MesaUI.FlexCol>
        </MesaUI.FlexRow>
      </MesaUI.PanelContent>
      {showDetails && (
        <PanelFilterDetailsModal
          details={filterDetails}
          isLoading={isLoadingFilterDetails}
          filterName={filterDetailsName}
          closeDetails={() => setShowDetails(false)}
        />
      )}
    </>
  )
}

export default PanelFilter