
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import MesaUI from '@mesacloud/react-framework'

import { useSelector, groupsSagaActions, viewActions } from '../../../store'
import { AppPathEnum } from '../../../lib/enums'

const PanelGroupListNoSelect = () => {

  const dispatch = useDispatch()

  const {
    isLoadingStudentGroups,
    studentGroups
  } = useSelector(({ groups }) => groups)

  useEffect(() => {
    if(!studentGroups?.length && !isLoadingStudentGroups){
      dispatch(groupsSagaActions.fetchStudentGroups())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <MesaUI.Container className="panel-groups__no-select">
      <MesaUI.Container className="panel-groups__no-select__message" paddingType="md">
        You haven&apos;t selected a group yet!
      </MesaUI.Container>
      <MesaUI.Container className="panel-groups__no-select__link">
        <NavLink to={AppPathEnum.GROUPS.path} onClick={() => dispatch(viewActions.closePanel())}>
          <MesaUI.Button buttonType="primary">
            Manage Groups
          </MesaUI.Button>
        </NavLink>
      </MesaUI.Container>
      <MesaUI.Container className="panel-groups__no-select__or">
        or
      </MesaUI.Container>
      <MesaUI.Container className="panel-groups__no-select__quick-select">
        <h4>Quick Select (Your Groups):</h4>
        <MesaUI.Container>
          {(Array.isArray(studentGroups) && !!studentGroups?.length) ? (
            <ul>
              {studentGroups.map(({ groupName, systemUserGroupId }, i) => (
                <li key={`panel-group-list-quick-select-group-${i}-${systemUserGroupId}`} onClick={() => dispatch(groupsSagaActions.fetchGroupList({ groupId: systemUserGroupId, groupName, page: 0, isSingleAddOnly: false }))}>
                  {groupName}
                </li>
              ))}
            </ul>
          ) : isLoadingStudentGroups ? (
            <MesaUI.LoadingRing seafoam/>
          ) : (
            <MesaUI.Container className="panel-groups__no-select__quick-select__none">
              You haven&apos;t created any groups yet.
            </MesaUI.Container>
          )}
        </MesaUI.Container>
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default PanelGroupListNoSelect