// @flow

import PendoService from './pendo';

let pendoInstance: PendoService;

export default (() => {
  // eslint-disable-next-line
  // @ts-ignore
  if (!pendoInstance) {
    pendoInstance = new PendoService();
  }

  return pendoInstance;
})();
