
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SupportState {
  isSendingSupportMessage: boolean;
  supportMessageFailed: boolean;
  supportMessageFailureReason: string;
  supportMessageComplete: boolean;
}

const initialState: SupportState = {
  isSendingSupportMessage: false,
  supportMessageFailed: false,
  supportMessageFailureReason: "",
  supportMessageComplete: false
}

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    supportMessageRequested: (state) => {
      state.isSendingSupportMessage = true
    },
    supportMessageSuccess: (state) => {
      state.isSendingSupportMessage = false
      state.supportMessageComplete = true
    },
    supportMessageFailure: (state, { payload: { failureReason }}: PayloadAction<{ failureReason: string }>) => {
      state.isSendingSupportMessage = false
      state.supportMessageFailed = true
      state.supportMessageFailureReason = failureReason
      state.supportMessageComplete = true
    },
    resetSupportMessageState: (state) => {
      state.isSendingSupportMessage = false
      state.supportMessageFailed = false
      state.supportMessageFailureReason = ""
      state.supportMessageComplete = false
    }
  }
})
