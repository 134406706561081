
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

import { useSelector } from '../../../store'

const StudentLeftPanelSchedule = () => {

  const {
    studentEnrollment
  } = useSelector(({ student }) => student)

  return (
    <MesaUI.Container className="student__left-panel__schedule no-mobile">
      <MesaUI.Container className="student__left-panel__schedule__header">
        <h3>Scheduled Courses</h3>
      </MesaUI.Container>
      <ul className="student__left-panel__schedule__courses">
        {Array.isArray(studentEnrollment) && studentEnrollment.map(({ courseName, academicSession }, i) => (
          <li key={`student-page-left-student-schedule-course-${i}`} className="student__left-panel__schedule__courses__item">
            <MesaUI.FlexRow>
              <MesaUI.FlexCol col={6}>
                {courseName}
              </MesaUI.FlexCol>
              <MesaUI.FlexCol col={1}>
                {academicSession}
              </MesaUI.FlexCol>
            </MesaUI.FlexRow>
          </li>
        ))}
      </ul>
    </MesaUI.Container>
  )
}

export default StudentLeftPanelSchedule
