
import React from 'react'
import MesaUI from "@mesacloud/react-framework"
import { useSelector } from '../../../../store'

const StudentMainSchedule = () => {

  const {
    studentEnrollment,
    isLoadingStudentEnrollment
  } = useSelector(({ student }) => student)

  return (
    <MesaUI.Container className="student__main__generic-list">
      <MesaUI.Container className="student__main__generic-list__header">
        <MesaUI.FlexRow>
          <MesaUI.FlexCol col={1}>
            Subject
          </MesaUI.FlexCol>
          <MesaUI.FlexCol col={3}>
            Course
          </MesaUI.FlexCol>
          <MesaUI.FlexCol col={1}>
            Session
          </MesaUI.FlexCol>
        </MesaUI.FlexRow>
      </MesaUI.Container>
      <MesaUI.Container className="student__main__generic-list__list">
        {isLoadingStudentEnrollment ? (
          <MesaUI.Container paddingType="xl"><MesaUI.LoadingRing seafoam/></MesaUI.Container>
        ) : (
          Array.isArray(studentEnrollment) && studentEnrollment.map(({ academicSession, courseName, subject }, i) => (
            <MesaUI.FlexRow 
              key={`student__main__generic-list__courses__item-${i}`}
              className="student__main__generic-list__list__item"
            >
              <MesaUI.FlexCol col={1}>
                {subject}
              </MesaUI.FlexCol>
              <MesaUI.FlexCol col={3}>
                {courseName}
              </MesaUI.FlexCol>
              <MesaUI.FlexCol col={1}>
                {academicSession}
              </MesaUI.FlexCol>
            </MesaUI.FlexRow>
          ))
        )}
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default StudentMainSchedule
