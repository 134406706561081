import { PayloadAction } from '@reduxjs/toolkit'
import { takeEvery, takeLatest, takeLeading, call, all, put } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import { 
  GetFlagsPayload, 
  UpdateFlagsStatusPayload,
  FlagDTO,
  FlagType,
  mockFlagTypes,
  mockFlags 
} from './flagsDomain'
import * as flagsSagaActions from './flagsSagaActions'
import { errorSlice } from '../error/errorSlice'
import { flagsSlice } from './flagsSlice'
import { loadTriggersSagaActions } from '../loadTriggers'

function* fetchFlagTypesSaga(){
  try {
    yield put(flagsSlice.actions.flagTypesRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ flagTypes: Array<FlagType> }>('api/flags/flagtypes', {}, { flagTypes: mockFlagTypes })
    })
    const flagTypes = result?.data?.flagTypes || []
    yield put(flagsSlice.actions.getFlagTypes({ flagTypes }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error fetching flag types: ${e}`}))
    yield put(flagsSlice.actions.getFlagTypes({ flagTypes: [] }))
  }
}

function* fetchFlagsSaga({ payload }: PayloadAction<GetFlagsPayload>){
  try {
    yield put(flagsSlice.actions.flagsRequested())
    const result = yield call(() => {
      return OnTimeAPI.get<{ studentFlagBeans: Array<FlagDTO>; numberOfPages: string | number }>('api/flags/flagfilter', { params: payload }, { studentFlagBeans: mockFlags, numberOfPages: 1 })
    })
    const flags = result?.data?.studentFlagBeans || []
    const totalPages = parseInt(result?.data?.numberOfPages) || 1
    yield put(flagsSlice.actions.getFlags({ flags, totalPages }))
  } catch(e){
    yield put(errorSlice.actions.displayError({ errorMessage: `Error flags: ${e}`}))
    yield put(flagsSlice.actions.getFlags({ flags: [], totalPages: 1 }))
  }
}

function* updateFlagsStatusSaga({ payload }: PayloadAction<UpdateFlagsStatusPayload>){
  try {
    yield put(flagsSlice.actions.updateFlagStatusRequested())
    yield call(() => {
      return OnTimeAPI.put('/api/flags/updatestatus', payload, {}, {})
    })
    yield put(loadTriggersSagaActions.triggerImmediateFlagsLoad())
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error updating flag status: ${e}`}))
  } finally {
    yield put(flagsSlice.actions.updateFlagStatusComplete())
  }
}

export function* rootFlagsSaga(){
  yield all([
    takeLeading(flagsSagaActions.fetchFlagTypes.type, fetchFlagTypesSaga),
    takeLatest(flagsSagaActions.fetchFlags.type, fetchFlagsSaga),
    takeEvery(flagsSagaActions.updateFlagsStatus.type, updateFlagsStatusSaga)
  ])
}
