
import React, { useState, useEffect, useRef } from 'react'
import { useHistory }  from 'react-router-dom'
import { useDispatch } from 'react-redux'
import MesaUI, { useClickOutside } from '@mesacloud/react-framework'

import { useSelector, searchSagaActions } from '../../../store'

const HeaderSearch = () => {

  const [searchText, setSearchText] = useState("")
  const [showResults, setShowResults] = useState(false)

  const history = useHistory()
  
  const { 
    generalStudentSearchResults,
    isLoadingGeneralStudentSearch
  } = useSelector(({ search }) => search)

  const clickRef = useRef(null)
  
  const dispatch = useDispatch()

  useClickOutside(clickRef, () => {
    setShowResults(false)
  })

  useEffect(() => {
    if(generalStudentSearchResults?.length > 0){
      setShowResults(true)
    }
  }, [generalStudentSearchResults, setShowResults])

  const handleSearchTextChange = (text: string) => {
    setSearchText(text)
    dispatch(searchSagaActions.fetchGeneralStudentSearch({ searchCriteria: text }))
  }

  const renderResults = () => (Array.isArray(generalStudentSearchResults) && !!generalStudentSearchResults.length) ? (
    generalStudentSearchResults.map((student, i) => {
      if(student){
        const { assignedExternalId, studentName, systemUserId } = student
        const ResultItem = () => (
          <MesaUI.SearchResultItem
            className="header__search__result"
            onClick={() => {
              setShowResults(false)
              handleSearchTextChange("")
              history.push(`/student/${systemUserId}`)
            }}
          >
            {`${assignedExternalId} - ${studentName}`}
          </MesaUI.SearchResultItem>
        )
        return <ResultItem key={`header-student-search-result-${i}-${assignedExternalId}-${studentName}`}/>
      }
      return null
    })
  ) : null

  return (
    <div ref={clickRef}>
      <MesaUI.Container marginType={{ left: "md", bottom: "-0.1em" }} onClick={() => setShowResults(true)}>
        <MesaUI.Search 
          placeholder="Search for students"
          className="header__search"
          searchText={searchText} 
          onTextChange={handleSearchTextChange}
          isLoading={isLoadingGeneralStudentSearch}
          showResults={showResults}
        >
          {renderResults()} 
        </MesaUI.Search>
      </MesaUI.Container>
    </div>
  )
}

export default HeaderSearch