
import { Enum, MesaColor, MesaColorEnum } from '@mesacloud/corejs'

interface CourseGroupStatus {
  keyname: string;
  color: MesaColor;
  displayName: string;
}

export class CourseGroupStatusEnum extends Enum implements CourseGroupStatus {

  public static PARTIAL = new CourseGroupStatusEnum("Partial", MesaColorEnum.ORANGE, "Partial")
  // Passed required course but don't have credit
  public static REQUIRED = new CourseGroupStatusEnum("Required", MesaColorEnum.RED, "No Credit")
  public static COMPLETED = new CourseGroupStatusEnum("Completed", MesaColorEnum.GREEN, "Completed")
  public static SCHEDULED = new CourseGroupStatusEnum("Scheduled", MesaColorEnum.DARK_BLUE, "Scheduled")
  public static ENROLLED = new CourseGroupStatusEnum("Enrolled", MesaColorEnum.DARK_BLUE, "Enrolled")
  public static SCHEDULED_AT_RISK = new CourseGroupStatusEnum("Scheduled At Risk", MesaColorEnum.ORANGE, "Scheduled At Risk")
  // Failed, no credit
  public static FAILED = new CourseGroupStatusEnum("Failed", MesaColorEnum.RED, "No Credit")
  public static FUTURE_REQUIREMENT = new CourseGroupStatusEnum("Future Requirement", MesaColorEnum.MIDNIGHT, "Future Requirement")
  public static NOT_COMPLETED = new CourseGroupStatusEnum("Not Completed", MesaColorEnum.GRAY, "Not Completed")
  public static NA = new CourseGroupStatusEnum("NA", MesaColorEnum.GRAY, "N/A")

  protected constructor(public keyname: string, public color: MesaColor, public displayName: string){ super() }

  public static asArray(){
    return super.asArray() as Array<CourseGroupStatus>
  }

  public static asObject(){
    return super.asObject() as { [keyname: string]: CourseGroupStatus }
  }

  public static getByKeyname(keyname: string): CourseGroupStatus {
    return this.asArray().find(({ keyname: existingKeyname }) => (
      existingKeyname.toLowerCase().trim().match(keyname.toLowerCase().trim())
    )) || this.NA
  }
}
