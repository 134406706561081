
import React from 'react'
import { NavLink } from 'react-router-dom'
import MesaUI, { useDocumentTitle } from '@mesacloud/react-framework'

const FourOFour = () => {

  useDocumentTitle("Mesa OnTime: 404 Not Found")

  return (
    <div className="four-o-four">
      <MesaUI.TopographyBanner torn className="four-o-four__background"/>
        <MesaUI.Card>
          <MesaUI.CardContent>
            <MesaUI.Container className="blocker-page__content__card-content" paddingType="lg">
              <MesaUI.Logo className="blocker-page__content__logo" logoType="mesa"/>
              <MesaUI.Container className="blocker-page__content__message-container" marginType={{ top: "lg" }}>
                <div className="blocker-page__content__message" style={{ textAlign: "center" }}>
                  Oops! 404 - We couldn&apos;t find what you were looking for.  
                  <NavLink to="/">
                    <MesaUI.Button buttonType="primary" marginType={{ top: "1em" }}>
                      Back to Mesa
                    </MesaUI.Button>
                  </NavLink>
                </div>
              </MesaUI.Container>
            </MesaUI.Container>
          </MesaUI.CardContent>
        </MesaUI.Card>
    </div>
  )
}

export default FourOFour