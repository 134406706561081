
import React from 'react'
import MesaUI, { useUuid } from '@mesacloud/react-framework'
import { MesaColorEnum, createDatedFilename } from '@mesacloud/corejs'

interface IndicatorsFlagTablePropTypes {
  isLoading: boolean | null | undefined;
  title: string;
  csvFilename: string;
  headers: Array<{ name: string; color: MesaColorEnum }>;
  rows: Array<Array<string | number>>;
}

const handleDownloadCSV = (fileName: string, headers: Array<string>, rows: Array<Array<string | number>>) => {
  if(rows?.length > 0) {
    const csvContent = "data:text/csv;harset=utf-8," + headers.join(",") + "\n" + rows.map(vals => vals.join(",")).join("\n");
    const csvURI = encodeURI(csvContent);
    const hiddenLink = document.createElement("a");
    hiddenLink.target = "_blank";
    hiddenLink.download = createDatedFilename(fileName, "csv")
    hiddenLink.href = csvURI;
    document && document.body && document.body.appendChild(hiddenLink);
    hiddenLink.click();
    document && document.body && document.body.removeChild(hiddenLink);
  }
}

const IndicatorsFlagTable = ({
  isLoading,
  title,
  csvFilename,
  headers,
  rows
}: IndicatorsFlagTablePropTypes) => {

  const uuid = useUuid()

  return (
    <MesaUI.Card className={{ "indicators__card--flags-table": true, "loading-whirl": isLoading }}>
      <MesaUI.CardHeader>
        <MesaUI.CardHeaderLeft>
          <h3>{title}</h3>
        </MesaUI.CardHeaderLeft>
        <MesaUI.CardHeaderRight>
          <MesaUI.Button buttonType="primary" onClick={() => handleDownloadCSV(csvFilename, headers?.map(({ name }) => name) || [], rows || [])}>
            Export
          </MesaUI.Button>
        </MesaUI.CardHeaderRight>
      </MesaUI.CardHeader>
      <MesaUI.CardContent>
        <MesaUI.Table>
          <MesaUI.TableRow header>
            {headers?.map(({ name, color }, i) => (
              <MesaUI.TableCell key={`indicators-flags-table-${uuid}-header-${i}`} backgroundColor={color} className="indicators__flags-table__header">
                {name}
              </MesaUI.TableCell>
            ))}
          </MesaUI.TableRow>
          {rows?.sort().map((row, i) => (
            <MesaUI.TableRow key={`indicators-flags-table-${uuid}-row-${i}`}>
              {row.map((value, ii) => (
                <MesaUI.TableCell key={`indicators-flags-table-${uuid}-row-${i}-item-${ii}-${value}`} backgroundColor={i % 2 === 0 ? MesaColorEnum.WHITE : MesaColorEnum.SNOW}>
                  {value}
                </MesaUI.TableCell>
              ))}
            </MesaUI.TableRow>
          ))}
        </MesaUI.Table>
      </MesaUI.CardContent>
    </MesaUI.Card>
  )
}

export default IndicatorsFlagTable
