
import React from 'react'
import MesaUI from '@mesacloud/react-framework'
import { MesaColorEnum, parseBool } from '@mesacloud/corejs'

import { UserFilter } from '../../../store/filter/filterDomain'

interface PanelFilterSavedPropTypes {
  filters: Array<UserFilter>;
  selectFilterForEdit: (filter: UserFilter) => void;
  activateFilter: (filter: UserFilter) => void;
  deactivateFilter: () => void;
  deleteFilter: (filter: UserFilter) => void;
  selectFilterForDetails: (filter: UserFilter) => void;
}

const PanelFilterSaved = ({
  filters,
  selectFilterForDetails,
  selectFilterForEdit,
  activateFilter,
  deactivateFilter,
  deleteFilter
}: PanelFilterSavedPropTypes) => {

  return (
    <MesaUI.Container>
      <MesaUI.Container paddingType="md" fontColor={MesaColorEnum.ORANGE}>
        <h3>Your Saved Filters</h3>
      </MesaUI.Container>
      <MesaUI.Container>
        <ul className="panel__filter__saved-list">
          {(Array.isArray(filters) && filters.length) ? filters.map((filter, i) => {
            const active = parseBool(filter?.activeStatus)
            return (
              <li className="panel__filter__saved-list__item" key={`panel__filter__saved-list__item-${i}-${filter.filterId}`}>
                <MesaUI.Container backgroundColor={i % 2 === 0 ? MesaColorEnum.SNOW : MesaColorEnum.WHITE}>
                  <MesaUI.FlexRow>
                    <MesaUI.FlexCol col={4}>
                      <MesaUI.Container paddingType="sm">
                        {filter?.name || "N/A"}
                      </MesaUI.Container>
                    </MesaUI.FlexCol>
                    <MesaUI.FlexCol col={1}>
                      <MesaUI.Container paddingType="sm" title="Activate">
                        <input 
                          type="checkbox"
                          className={active ? "panel__filter__checkbox--active" : "panel__filter__checkbox"}
                          checked={active} 
                          onChange={() => {
                            active ? deactivateFilter() : activateFilter(filter)}
                          }
                        />
                      </MesaUI.Container>
                    </MesaUI.FlexCol>
                    <MesaUI.FlexCol col={1}>
                      <MesaUI.Container paddingType="sm">
                        <i className="fa fa-info-circle panel__filter__info-button" onClick={() => selectFilterForDetails(filter)}/>
                      </MesaUI.Container>
                    </MesaUI.FlexCol>
                    <MesaUI.FlexCol col={1}>
                      <MesaUI.Container paddingType="sm">
                        <i className="fa fa-edit panel__filter__edit-button" onClick={() => selectFilterForEdit(filter)}/>
                      </MesaUI.Container>
                    </MesaUI.FlexCol>
                    <MesaUI.FlexCol col={1}>
                      <MesaUI.Container paddingType="sm">
                        <i className="fa fa-trash panel__filter__delete-button" onClick={() => deleteFilter(filter)}/>
                      </MesaUI.Container>
                    </MesaUI.FlexCol>
                  </MesaUI.FlexRow>
                </MesaUI.Container>
              </li>
            )
          }) : (
            <MesaUI.Container paddingType="md">
              <h4>You haven&apos;t created any filters yet</h4>
            </MesaUI.Container>
          )}
        </ul>
      </MesaUI.Container>
    </MesaUI.Container>
  )
}

export default PanelFilterSaved