
import React from 'react'
import MesaUI from '@mesacloud/react-framework'

import { RiskAssessment } from '../../store/indicators/indicatorsDomain'
import { MesaColorEnum } from '@mesacloud/corejs'

import { StudentGradPlanStatusEnum } from '../../lib/enums'

interface IndicatorsCardFooterStudentCountPropTypes {
  riskAssessment: RiskAssessment;
}

const IndicatorsCardFooterStudentCount = ({
  riskAssessment
}: IndicatorsCardFooterStudentCountPropTypes) => {
  const safeToAccessRiskAssessment = riskAssessment !== null && typeof riskAssessment === "object"
  return (
    <MesaUI.Container className="indicators__card-footer-student-count" fontColor={MesaColorEnum.WHITE}>
      {/* Opportunity for component-izing these */}
      <MesaUI.Container backgroundColor={MesaColorEnum.SEAFOAM}>
        <div>{(safeToAccessRiskAssessment && riskAssessment["On Track"]) || 0}</div>
        <div>{StudentGradPlanStatusEnum.ON_TRACK.displayName}</div>
      </MesaUI.Container>
      <MesaUI.Container backgroundColor={MesaColorEnum.GOLD}>
        <div>{(safeToAccessRiskAssessment && riskAssessment["Alert"]) || 0}</div>
        <div>{StudentGradPlanStatusEnum.ALERT.displayName}</div>
      </MesaUI.Container>
      <MesaUI.Container backgroundColor={MesaColorEnum.RED}>
        <div>{(safeToAccessRiskAssessment && riskAssessment["Off Track"]) || 0}</div>
        <div>{StudentGradPlanStatusEnum.OFF_TRACK.displayName}</div>
      </MesaUI.Container>
      <MesaUI.Container backgroundColor={MesaColorEnum.GRAY}>
        <div>{(safeToAccessRiskAssessment && riskAssessment["No YE9 Transcript"]) || 0}</div>
        <div>{StudentGradPlanStatusEnum.NO_YE9_TRANSCRIPT.displayName}</div>
      </MesaUI.Container>
    </MesaUI.Container>
  )
}
export default IndicatorsCardFooterStudentCount
