import { parse } from 'csv-string'
import { MesaColor, MesaColorEnum } from '@mesacloud/corejs'

interface GpaRange {
  color: MesaColor;
  iconClass: string;
  min: number;
  max: number;
  valid: boolean;
}

const parseGpaRange = (val: string): GpaRange => {
  if(!val.match(/\d+(\.\d+)?-\d+(\.\d+)?:[A-Za-z]+$/)){
    console.warn(`Invlaid GpaRange value: ${val}`)
    return { color: MesaColorEnum.GRAY, iconClass: "fal fa-circle", min: -1, max: -1, valid: false }
  }
  const [minMax, colorName] = val.split(':')
  const [min, max] = minMax.split('-')
  const [color, iconClass] = (() => {
    switch(colorName.toUpperCase()){
      case 'RED':
        return [MesaColorEnum.RED, "fas fa-times-circle"] as [MesaColor, string]
      case 'YELLOW':
        return [MesaColorEnum.GOLD, "fas fa-exclamation-circle"] as [MesaColor, string]
      case 'GREEN':
        return [MesaColorEnum.SEAFOAM, "fas fa-check-circle"] as [MesaColor, string]
      default:
        return [MesaColorEnum.GRAY, "fal fa-circle"] as [MesaColor, string]
    }
  })()
  return { color, min: parseFloat(min), max: parseFloat(max), iconClass, valid: true }
}

// range set syntax: 0.0-2.0:RED","2.0-2.25:YELLOW","2.25-10.0:GREEN
export const parseGpaRangeSet = (rangeSet: string): Array<GpaRange> => {
  const parsedCsv = parse(rangeSet || "") as any as Array<Array<string>> // bad type definition from lib...
  if(parsedCsv.length !== 1){
    console.warn(`Did not receive expected single-row GPA range set: ${rangeSet}`)
  }
  return (parsedCsv[0]).map(parseGpaRange)
}

// truncate decimal rather than round
export const normalizeGpaValue = (val: string | number, decimalPlaces: number): string => {
  const numVal = typeof val === "number" ? val : parseFloat(val)
  if(isNaN(numVal) || numVal < 0){
    console.warn(`Invalid GPA num received: ${val}`)
    return "N/A"
  } else {
    const match = (numVal + "").match(/(\d+)(\.\d+)?/)
    if(!match) return (numVal + "")
    if(decimalPlaces <= 0){
      return match[1]
    }
    if(match[2] !== undefined){
      return match[1] + match[2].slice(0, decimalPlaces + 1)
    } else {
      return numVal + ""
    }
  }
}

export const getGpaRangeForGpaVal = (gpa: string | number, gpaRangeSet: string) => {
  const rangeSet = parseGpaRangeSet(gpaRangeSet).sort((a, b) => a.min > b.min ? 1 : -1)
  const gpaNum = typeof gpa === "number" ? gpa : parseFloat(gpa)
  return rangeSet.reduce((prev, range) => {
    if((gpaNum >= range.min) && (gpaNum <= range.max)){ // inclusive both ends to allow highest range applicable
      return range
    }
    return prev
  }, { color: MesaColorEnum.GRAY, iconClass: "fal fa-circle", min: -1, max: -1, valid: false })
}