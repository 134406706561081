
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import MesaUI, { useClickOutside } from '@mesacloud/react-framework'

import { useSelector, searchSagaActions } from '../../../store'

interface CreateGroupSearchPropTypes {
  addStudent: (studentId: string) => void;
  removeStudent: (studentId: string) => void;
  studentIds: Array<string>;
}

const CreateGroupSearch = ({
  addStudent,
  removeStudent,
  studentIds,
}: CreateGroupSearchPropTypes) => {

  const [addedStudents, setAddedStudents] = useState<Array<{ name: string; id: string }>>([])
  const [searchText, setSearchText] = useState("")
  const [showResults, setShowResults] = useState(false)
  const clickRef = useRef(null)

  const dispatch = useDispatch()

  const { isLoadingCreateGroupStudentSearch, createGroupStudentSearchResults } = useSelector(({ search }) => search)

  useEffect(() => {
    dispatch(searchSagaActions.fetchCreateGroupStudentSearch({ searchCriteria: searchText }))
  }, [searchText, dispatch])

  useEffect(() => {
    if(!!createGroupStudentSearchResults?.length){
      setShowResults(true)
    }
   // eslint-disable-next-line
  }, [createGroupStudentSearchResults])

  useEffect(() => {
    setAddedStudents(studentIds?.map(studentId => {
      const existing = addedStudents.find(({ id }) => id === studentId)
      if(existing){
        return existing
      } else {
        const result = createGroupStudentSearchResults.find(({ assignedExternalId }) => assignedExternalId === studentId)
        if(result){
          return { name: `${result.assignedExternalId} - ${result.studentName}`, id: result.studentId }
        } 
      }
      return { name: studentId + "", id: studentId }
    }) || [])
    // eslint-disable-next-line
  }, [studentIds])

  useClickOutside(clickRef, () => {
    setShowResults(false)
  })

  return (
    <MesaUI.Container className="create-group__search">
      <MesaUI.Container className="create-group__search__input">
        <span ref={clickRef}>
          <MesaUI.Search
            placeholder="Search for students"
            searchText={searchText}
            onTextChange={(text: string) => setSearchText(text.slice(0,30))}
            isLoading={isLoadingCreateGroupStudentSearch}
            showResults={showResults}
          >
            {Array.isArray(createGroupStudentSearchResults) && createGroupStudentSearchResults.map(({ assignedExternalId, studentName }, i) => (
              <MesaUI.SearchResultItem className="create-group__search__result" key={`create-group-search-result-item-${i}`} onClick={() => {
                addStudent(assignedExternalId)
                setShowResults(false)
                setSearchText("")
              }}>
                {assignedExternalId} - {studentName}
              </MesaUI.SearchResultItem>
            ))}
          </MesaUI.Search>
        </span>
      </MesaUI.Container>
      {!!addedStudents.length && (
        <MesaUI.Container className="create-group__search__adds">
          <MesaUI.Container>Manually Added Students:</MesaUI.Container>
          <ul>
            {addedStudents.map(({ name, id }, i) => (
              <li key={`create-group__search__adds__result-${i}-${id}`} onClick={() => removeStudent(id)}>
                {name}
              </li>
            ))}
          </ul>
        </MesaUI.Container>
      )}
    </MesaUI.Container>
  )

}

export default CreateGroupSearch