import React from 'react'
import { MesaColorEnum } from '@mesacloud/corejs'
import MesaUI from '@mesacloud/react-framework'

const LoadingPage = ({
  errorMessage,
  errorInfo,
}: { errorMessage: string; errorInfo: string }) => {
  return (
    <div className="blocker-page">
      <MesaUI.TopographyBanner className="blocker-page__background"/>
      <div className="blocker-page__content">
        <MesaUI.Card className="blocker-page__content__card">
          <MesaUI.CardContent>
            <MesaUI.Container className="blocker-page__content__card-content" paddingType="lg">
              <MesaUI.Logo className="blocker-page__content__logo" logoType="mesa"/>
              <MesaUI.Container className="blocker-page__content__message-container" marginType={{ top: "lg" }}>
                <div className="blocker-page__content__message" style={{ textAlign: "center" }}>
                  <MesaUI.Container paddingType="xs">
                    Oops! Something went wrong.
                  </MesaUI.Container>
                  <MesaUI.Container fontColor={MesaColorEnum.RED}>
                    {errorMessage}
                  </MesaUI.Container>
                  <MesaUI.Container>
                    Info: {errorInfo}
                  </MesaUI.Container>
                </div>
              </MesaUI.Container>
            </MesaUI.Container>
          </MesaUI.CardContent>
        </MesaUI.Card>
      </div>
    </div>
  )
}

export default LoadingPage