import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, call, all, put } from 'redux-saga/effects'

import { OnTimeAPI } from '../../lib/api'

import {
  AddGroupStudentSearchPayload,
  GeneralStudentSearchPayload,
  GroupStudentSearchPayload,
  mockSearchStudents
} from './searchDomain'

import {
  mockGroupList
} from '../groups/groupsDomain'

import * as searchSagaActions from './searchSagaActions'
import { errorSlice } from '../error/errorSlice'
import { searchSlice } from './searchSlice'

const validateSearchCriteria = (searchCriteria: any): boolean => {
  return (typeof searchCriteria === "string" && searchCriteria.length > 1)
}

function* generalStudentSearchSaga({ payload }: PayloadAction<GeneralStudentSearchPayload>){
  if(!validateSearchCriteria(payload?.searchCriteria)){
    yield put(searchSlice.actions.getGeneralStudentSearch({ students: [] }))
    return
  }
  try {
    yield put(searchSlice.actions.generalStudentSearchRequested())
    const result = yield call(() => {
      return OnTimeAPI.get("/api/gradplan/search", { params: payload }, { studentBeanList: mockSearchStudents })
    })
    const students = result?.data?.studentBeanList || []
    yield put(searchSlice.actions.getGeneralStudentSearch({ students }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error searching for students: ${e}` }))
    yield put(searchSlice.actions.getGeneralStudentSearch({ students: [] }))
  }
}

function* createGroupStudentSearchSaga({ payload }: PayloadAction<GeneralStudentSearchPayload>){
  if(!validateSearchCriteria(payload?.searchCriteria)){
    yield put(searchSlice.actions.getCreateGroupStudentSearch({ students: [] }))
    return
  }
  try {
    yield put(searchSlice.actions.createGroupStudentSearchRequested())
    const result = yield call(() => {
      return OnTimeAPI.get("/api/gradplan/search", { params: payload }, { studentBeanList: mockSearchStudents })
    })
    const students = result?.data?.studentBeanList || []
    yield put(searchSlice.actions.getCreateGroupStudentSearch({ students }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error searching for students: ${e}` }))
    yield put(searchSlice.actions.getCreateGroupStudentSearch({ students: [] }))
  }
}

function* groupStudentSearchSaga({ payload }: PayloadAction<GroupStudentSearchPayload>){
  if(!validateSearchCriteria(payload?.searchCriteria)){
    yield put(searchSlice.actions.getGroupStudentSearch({ students: [] }))
    return
  }
  try {
    yield put(searchSlice.actions.groupStudentSearchRequested())
    const result = yield call(() => {
      return OnTimeAPI.get("/api/group/studentsearchingroup", { params: payload }, { studentBeanList: mockGroupList.students })
    })
    const students = result?.data?.studentBeanList || []
    yield put(searchSlice.actions.getGroupStudentSearch({ students }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error searching for students: ${e}` }))
    yield put(searchSlice.actions.getGroupStudentSearch({ students: [] }))
  }
}

function* addGroupStudentSearchSaga({ payload }: PayloadAction<AddGroupStudentSearchPayload>){
  if(!validateSearchCriteria(payload?.searchCriteria)){
    yield put(searchSlice.actions.getAddGroupStudentSearch({ students: [] }))
    return
  }
  try {
    yield put(searchSlice.actions.addGroupStudentSearchRequested())
    const result = yield call(() => {
      return OnTimeAPI.get("/api/group/singleaddsearch", { params: payload }, { students: mockGroupList.students })
    })
    const students = result?.data?.students || []
    yield put(searchSlice.actions.getAddGroupStudentSearch({ students }))
  } catch(e) {
    yield put(errorSlice.actions.displayError({ errorMessage: `Error searching for students: ${e}` }))
    yield put(searchSlice.actions.getAddGroupStudentSearch({ students: [] }))
  }
}

export function* rootSearchSaga(){
  yield all([
    takeLatest(searchSagaActions.fetchGeneralStudentSearch.type, generalStudentSearchSaga),
    takeLatest(searchSagaActions.fetchGroupStudentSearch.type, groupStudentSearchSaga),
    takeLatest(searchSagaActions.fetchCreateGroupStudentSearch.type, createGroupStudentSearchSaga),
    takeLatest(searchSagaActions.fetchAddGroupStudentSearch.type, addGroupStudentSearchSaga)
  ])
}
