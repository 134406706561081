
import { all, put, takeLatest, delay } from 'redux-saga/effects'

import { loadTriggersSlice } from './loadTriggersSlice'
import * as loadTriggersSagaActions from './loadTriggersSagaActions'

const LOAD_DELAY = 1000 * 60 // after a minute, page should be refreshed for Indicators or Flags when navigating back eventually

function* indicatorsImmediateLoadSaga(){
  yield put(loadTriggersSlice.actions.triggerImmediateIndicatorsPageLoad())
}

function* indicatorsEventualLoadDelaySaga(){
  yield delay(LOAD_DELAY)
  yield put(loadTriggersSlice.actions.triggerEventualIndicatorsPageLoad())
}

function* flagsEventualLoadDelaySaga(){
  yield delay(LOAD_DELAY)
  yield put(loadTriggersSlice.actions.triggerEventualFlagPageLoad())
}

function* flagsImmediateLoadSaga(){
  yield put(loadTriggersSlice.actions.triggerImmediateFlagPageLoad())
}

export function* rootLoadTriggersSaga(){
  yield all([
    takeLatest(loadTriggersSlice.actions.indicatorsPageLoaded.type, indicatorsEventualLoadDelaySaga),
    takeLatest(loadTriggersSlice.actions.flagsPageLoaded.type, flagsEventualLoadDelaySaga),
    takeLatest(loadTriggersSagaActions.triggerImmediateIndicatorsLoad.type, indicatorsImmediateLoadSaga),
    takeLatest(loadTriggersSagaActions.triggerEventualIndicatorsLoad.type, indicatorsEventualLoadDelaySaga),
    takeLatest(loadTriggersSagaActions.triggerImmediateFlagsLoad.type, flagsImmediateLoadSaga),
    takeLatest(loadTriggersSagaActions.triggerEventualFlagsLoad.type, flagsEventualLoadDelaySaga)
  ])
}