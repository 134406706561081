
import { useHistory } from 'react-router-dom'

import { useSelector } from '../store'
import { UserRoleEnum } from '../lib/enums'

export const useRedirectRoles = (roles: Array<UserRoleEnum>, to = "/") => {

  const userRole = useSelector(({ user: { userRole } }) => userRole)
  const history = useHistory()

  if(roles.includes(userRole)){
    history.push(to)
  }
}