import { createAction } from "@reduxjs/toolkit"

import {
  AddOrRemoveGroupStudentPayload,
  GetStudentsByGroupIdPayload,
  GroupIDPayload,
  CreateGroupPayload
} from './groupsDomain'

export const fetchStudentGroups = createAction("FETCH_STUDENT_GROUPS_SAGA")
export const fetchGroupDetails = createAction<GroupIDPayload>("FETCH_GROUP_DETAILS_SAGA")
export const fetchCampusOptions = createAction("FETCH_CAMPUS_OPTIONS_SAGA")
export const fetchGroupList = createAction<GetStudentsByGroupIdPayload>("FETCH_GROUP_LIST_SAGA")
export const addStudentToGroup = createAction<AddOrRemoveGroupStudentPayload>("ADD_STUDENT_TO_GROUP_SAGA")
export const removeStudentFromGroup = createAction<AddOrRemoveGroupStudentPayload>("REMOVE_STUDENT_FROM_GROUP_SAGA")
export const deleteGroup = createAction<GroupIDPayload>("DELETE_GROUP_SAGA")
export const createGroup = createAction<CreateGroupPayload>("CREATE_GROUP_SAGA")
