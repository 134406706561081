
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  FilterFields,
  UserFilter,
  FilterDetails,
  ActiveFilterStatus
} from './filterDomain'

export interface FilterState {
  isLoadingUserFilters: boolean;
  isLoadingFilterFields: boolean;
  isSavingFilter: boolean;
  isDeactivatingFilter: boolean;
  isActivatingFilter: boolean;
  isDeletingFilter: boolean;
  isLoadingFilterDetails: boolean;
  isLoadingActiveFilterStatus: boolean;

  filterFields: FilterFields;
  filterFieldsLoaded: boolean;
  userFilters: Array<UserFilter>;
  filterIsActive: boolean;
  activeFilterName: string;
  filterDetails: FilterDetails;
}

const initialState: FilterState = {
  isLoadingUserFilters: false,
  isLoadingFilterFields: false,
  isSavingFilter: false,
  isDeactivatingFilter: false,
  isActivatingFilter: false,
  isDeletingFilter: false,
  isLoadingFilterDetails: false,
  isLoadingActiveFilterStatus: false,
  filterFields: {
    campuses: [],
    cohorts: [],
    demographics: [],
    filters: [],
    gender: []
  },
  filterFieldsLoaded: false,
  userFilters: [],
  filterIsActive: false,
  activeFilterName: "",
  filterDetails: {
    campusNames: [],
    cohortNames: [],
    raceNames: [],
    genderName: "",
    groupNames: []
  },
}

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    filterFieldsRequested: (state) => {
      state.isLoadingFilterFields = true
    },
    getFilterFields: (state, { payload: { fields }}: PayloadAction<{ fields: FilterFields }>) => {
      state.isLoadingFilterFields = false
      state.filterFieldsLoaded = true
      state.filterFields = fields
    },
    userFiltersRequested: (state) => {
      state.isLoadingUserFilters = true
    },
    getUserFilters: (state, { payload: { filters } }: PayloadAction<{ filters: Array<UserFilter> }>) => {
      state.isLoadingUserFilters = false
      state.userFilters = filters
    },
    saveFilterRequested: (state) => {
      state.isSavingFilter = true
    },
    saveFilterComplete: (state) => {
      state.isSavingFilter = false
    },
    deactivateFilterRequested: (state) => {
      state.isDeactivatingFilter = true
    },
    deactivateFilterComplete: (state) => {
      state.isDeactivatingFilter = false
    },
    activateFilterRequested: (state) => {
      state.isActivatingFilter = true
    },
    activateFilterComplete: (state) => {
      state.isActivatingFilter = false
    },
    deleteFilterRequested: (state) => {
      state.isDeletingFilter = true
    },
    deleteFilterComplete: (state) => {
      state.isDeletingFilter = false
    },
    filterDetailsRequested: (state) => {
      state.isLoadingFilterDetails = true
    },
    getFilterDetails: (state, { payload: { details } }: PayloadAction<{ details: FilterDetails }>) => {
      state.isLoadingFilterDetails = false
      state.filterDetails = details
    },
    activeFilterStatusRequested: (state) => {
      state.isLoadingActiveFilterStatus = true
    },
    getActiveFilterStatus: (state, { payload: { activeFilterStatus }}: PayloadAction<{ activeFilterStatus: ActiveFilterStatus }>) => {
      state.isLoadingActiveFilterStatus = false

      const { filterName, active } = activeFilterStatus

      state.activeFilterName = filterName || ""
      state.filterIsActive = typeof active === "string" ? (
        !!active.trim().match(/true/i)
      ) : typeof active === "boolean" ? (
        active
      ) : (
        !!active
      )
    }
  }
})
