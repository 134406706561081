
import React from 'react'
import { useDispatch } from 'react-redux'
import MesaUI from '@mesacloud/react-framework'

import { UserRoleEnum } from '../../../lib/enums'
import { PanelViewEnum } from '../../../lib/enums'
import { useSelector, viewActions } from '../../../store'

import RoleFilter from '../../RoleFilter'

const Rightbar = () => {

  const { panelView, panelIsOpen } = useSelector(({ view }) => view)
  const { filterIsActive, activeFilterName } = useSelector(({ filter }) => filter)
  const { selectedGroupList } = useSelector(({ groups }) => groups)
  const {
    authRoleEnum
  } = useSelector(({user}) => user.whoami)
  const isNotStudent = authRoleEnum !== 'STUDENT'

  const dispatch = useDispatch()

  const createViewSelector = (view: PanelViewEnum) => () => {
    dispatch(viewActions.setPanelView({ view }))
    panelView === view ? dispatch(viewActions.toggleOpenPanel()) : dispatch(viewActions.openPanel())
  }

  return (
    <MesaUI.Rightbar>
      <RoleFilter roles={[UserRoleEnum.STUDENT]} exclude>
        <MesaUI.RightbarItem 
          active={filterIsActive}
          selected={panelIsOpen && (panelView === PanelViewEnum.FILTER)}
          onClick={createViewSelector(PanelViewEnum.FILTER)}
          iconFaClass="fas fa-filter"
          tooltipText={filterIsActive ? `Active Filter: ${activeFilterName}` : "No filter active"}
        />
        <MesaUI.RightbarItem 
          onClick={createViewSelector(PanelViewEnum.GROUP_LIST)}
          selected={panelIsOpen && (panelView === PanelViewEnum.GROUP_LIST)}
          iconFaClass="fas fa-users"
          tooltipText={selectedGroupList?.groupName ? `Group: ${selectedGroupList?.groupName}` : 'Groups'}
        />
      </RoleFilter>
      
      { isNotStudent &&
        <MesaUI.RightbarItem 
          onClick={createViewSelector(PanelViewEnum.SUPPORT_REQUEST)}
          selected={panelIsOpen && (panelView === PanelViewEnum.SUPPORT_REQUEST)}
          iconFaClass="fa fa-user-headset"
          tooltipText="Support Request"
        />
      }
    </MesaUI.Rightbar>
)
}

export default Rightbar