
export interface RiskAssessment {
  "Alert": string;
  "No YE9": string;
  "No Transcripts": string;
  "No YE9 Transcript": string;
  "Off Track": string;
  "On Track": string;
  "Undetermined"?: string;
}

export const emptyRiskAssessment: RiskAssessment = {
  "Alert": "0",
  "No YE9": "0" ,
  "No Transcripts": "0",
  "No YE9 Transcript": "0",
  "Off Track": "0",
  "On Track": "0",
}

export const mockRiskAssessment: RiskAssessment = {
  "Alert": "100",
  "No YE9": "100",
  "No Transcripts": "100",
  "No YE9 Transcript": "200",
  "Off Track": "10",
  "On Track": "200"
}

export interface RiskAssessmentByYE9 {
  [ye9: string]: RiskAssessment;
}

export const mockRiskAssessmentByYE9: RiskAssessmentByYE9 = {
  "2012-2013":  mockRiskAssessment,
  "2013-2014": mockRiskAssessment,
  "2014-2015": mockRiskAssessment,
  "2015-2016": mockRiskAssessment
}

export interface OverallFlagCounts {
  [flagName: string]: number;
}

export const mockOverallFlagCounts = {
  "Mock Flag 1": 100,
  "Mock Flag 2": 2000,
  "Mock Flag 3": 21,
}

export interface StudentsWithFlagsCounts {
  studentsCount: number;
  studentsCountWithMultipleFlags: number;
}

export const emptyStudentsWithFlagsCount = {
  studentsCount: 0,
  studentsCountWithMultipleFlags: 0
}

export interface FlagCountsByCampus {
  campusName: string;
  [flagName: string]: string;
}

export const mockFlagCountsByCampus: Array<FlagCountsByCampus> = [
  { campusName: "Mock Campus", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { campusName: "Mock Campus", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { campusName: "Mock Campus", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { campusName: "Mock Campus", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
]

export interface FlagCountsByYE9 {
  ye9: string;
  [flagName: string]: string;
}

export const mockFlagCountsByYE9: Array<FlagCountsByYE9> = [
  { ye9: "Mock YE9", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { ye9: "Mock YE9", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { ye9: "Mock YE9", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { ye9: "Mock YE9", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
]

export interface FlagCountsByDem {
  demName: string;
  [flagName: string]: string;
}

export const mockFlagCountsByDem: Array<FlagCountsByDem> = [
  { demName: "Mock Demogrpahic", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { demName: "Mock Demogrpahic", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { demName: "Mock Demogrpahic", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
  { demName: "Mock Demogrpahic", "Mock Flag 1": "10", "Mock Flag 2": "200", "Mock Flag 3": "3000"},
]